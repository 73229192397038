import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function PlanningAccuracy({ data }) {
  useEffect(() => {
    // Create chart instance
    var chart = am4core.create("PlanningAccuracy", am4charts.XYChart);
    // chart.scrollbarX = new am4core.Scrollbar();

    // Add data
    chart.data = data;

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "title";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "middle";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.tooltip.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100; // Set the maximum value of the y-axis to 100

    // Remove space between y-axis labels and chart
    valueAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      return 0;
    });
    // Format y-axis labels with percentage symbol
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#.#'%'";
    valueAxis.renderer.minWidth = 50;

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "title";
    // series.tooltipText = "[{categoryX}: bold]{valueY}[/]";  // comment out
    series.columns.template.strokeWidth = 0;
    series.tooltipText = "{valueY.formatNumber('#.')} %"; // code run

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 20;
    series.columns.template.column.cornerRadiusTopRight = 20;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    var hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.fillOpacity = 1;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }, [data]);

  return <div style={{ height: "250px" }} id="PlanningAccuracy"></div>;
}

export default PlanningAccuracy;

// function PlanningAccuracy() {
//   useEffect(() => {
//     var chart = am4core.create("planningAccuracy", am4charts.XYChart);

//     // Add data
//     chart.data = [
//       {
//         year: "2016",
//         Planned: 60,
//         Hold: 40,
//       },
//       {
//         year: "2017",
//         Planned: 30,
//         Hold: 50,
//       },
//       {
//         year: "2018",
//         Planned: 30,
//         Hold: 30,
//       },
//     ];

// // Create axes
// var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
// categoryAxis.dataFields.category = "year";
// categoryAxis.renderer.grid.template.location = 0;

// var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
// valueAxis.renderer.inside = true;
// valueAxis.renderer.labels.template.disabled = false; // Show the labels
// valueAxis.min = 0;
// valueAxis.max = 100;
// valueAxis.strictMinMax = true;

// // Use a labelFunction to add "%" symbol to the axis labels
// valueAxis.renderer.labels.template.adapter.add("text", (text) => {
//   return text + "%";
// });

// // Set the step size for the y-axis labels
// valueAxis.renderer.baseGrid.disabled = true;
// valueAxis.renderer.minGridDistance = 10;
// valueAxis.renderer.grid.template.disabled = true;
// valueAxis.renderer.ticks.template.disabled = false;
// valueAxis.renderer.ticks.template.length = 8;
// valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
// // valueAxis.renderer.ticks.template.adapter.add("locationY", (location, target) => {
// //   return ((target.dataItem.value / maxValue) * 100).toString() + "%";
// // });

//     // Create series
//     function createSeries(field, name) {
//       // Set up series
//       var series = chart.series.push(new am4charts.ColumnSeries());
//       series.name = name;
//       series.dataFields.valueY = field;
//       series.dataFields.categoryX = "year";
//       series.sequencedInterpolation = true;

//       // Make it stacked
//       series.stacked = true;

//       // Configure columns
//       series.columns.template.width = am4core.percent(60);
//       series.columns.template.tooltipText =
//         "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

//       // Add label
//       var labelBullet = series.bullets.push(new am4charts.LabelBullet());
//       labelBullet.label.text = "{valueY}";
//       labelBullet.locationY = 0.5;
//       labelBullet.label.hideOversized = true;

//       return series;
//     }

//     createSeries("Planned", "Planned");
//     createSeries("Hold", "Hold");

//     // Legend
//     chart.legend = new am4charts.Legend();
//     chart.legend.position = "right";
//   }, []);

//   return <div style={{height:'250px'}} id="planningAccuracy"></div>;
// }

// export default PlanningAccuracy;
