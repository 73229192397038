import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  filterHeader: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  tp: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "4px !important",
    },
  },
}));
