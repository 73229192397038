import { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Popup from "./Popup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Graph from "./graph";
import PaperHeader from "../paperHeader/paperHeader";
import SelectField from "../../components/select/selectField";
import { GlobalData } from "../../context/globalData";
import { textValue } from "../../utils/textMap";
import { levelMap } from "../../utils/levelMap";
// import { cos } from "@amcharts/amcharts4/.internal/core/utils/Math";
import { useExcelDownloadHook } from "../../utils/downloadExcelHook";
import { exportComponentAsPNG } from "react-component-export-image";
// import BarChart from "./graph/BarChart";
import StackChart from "./graph/StackChart";
const useStyles = makeStyles((theme) => ({
  paper: {
    // width: "490px",
    // flex: "33%",
    borderRadius: "20px",
    borderWidth: "3px",
    borderColor: "#D6E4EC",
    marginBottom: "25px",
    padding: "14px 6px",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      // marginTop: "15px",
      flex: "50%",
    },
  },
  wrapper: {
    display: "flex",
    marginTop: "8px",
    // alignItems: "center",
    justifyContent: "center",
  },
}));

const defaultVal = 0;

export default function MetricGraph(props) {
  const classes = useStyles();
  const {
    allMetricData,
    setOpenPop,
    setTrendData,
    setSelectedMetricTrend,
    setPopupPrecision,
    setMetricDataName,
    projectNameData,
    // localChart,
    // setLocalChart,
    graphName,
    iteration,
    moduleKpiGraphFlag,
  } = useContext(GlobalData);

  const [selectedValue, setSelectedValue] = useState("");
  // const [localChart, setLocalChart] = useState(1);
  const [metricData, setMetricData] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [precision, setPrecision] = useState(null);
  const [matrixId, setMatrixId] = useState();
  let keyList = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  function handleClick() {
    setShowPopup(true);
  }

  function handleClose() {
    setShowPopup(false);
  }
  const {
    body,
    isSprint,
    isStories,
    isTask,
    graphData,
    setGraphTrendApi,
    level,
    dropdownVal,
    valueOfIteration,
    groupId,
    graphTrendApi2,
    handleChartType,
    localChart,
    setLocalChart,
    selectedmoduleKpiNameValue,
    isKpiGraph,
  } = props;
  const componentRef = useRef(localChart);
  useEffect(() => {
    const metric = [];
    allMetricData.forEach((val) => {
      if (val[levelMap[level.slice(0, -1).toUpperCase()]]) {
        const obj = {
          [val.DisplayName]: val.Id,
          Precision: val.Precision,
        };
        metric.push(obj);
      }
    });

    setMetricData(metric);

    keyList.current = metric.map(
      (val) => textValue[Object.keys(val)[0]] || Object.keys(val)[0]
    );
    setDropdown(keyList.current);
  }, [allMetricData]);

  useEffect(() => {
    if (dropdown.length === 0 && metricData.length === 0) return;
    if (!metricData[dropdownVal || defaultVal]) return;
    !selectedValue
      ? setSelectedValue(keyList.current[dropdownVal || defaultVal])
      : setSelectedValue(selectedValue);
    !matrixId
      ? setMatrixId(Object.values(metricData[dropdownVal || defaultVal])[0])
      : setMatrixId(matrixId);
    !graphTrendApi2
      ? setGraphTrendApi(
          Object.values(metricData[dropdownVal || defaultVal])[0]
        )
      : setGraphTrendApi(graphTrendApi2);
    setPrecision(metricData[dropdownVal || defaultVal].Precision);
  }, [dropdown, setGraphTrendApi, metricData, dropdownVal]);

  const handlerSelect = (val) => {
    setMatrixId(Object.values(metricData[keyList.current.indexOf(val)])[0]);
    setGraphTrendApi(
      Object.values(metricData[keyList.current.indexOf(val)])[0]
    );
    setPrecision(metricData[keyList.current.indexOf(val)].Precision);
    setSelectedValue(val);
  };

  useEffect(() => {
    
    if (selectedmoduleKpiNameValue && metricData?.length > 0) {
      handlerSelect(selectedmoduleKpiNameValue);
    }
    
  }, [selectedmoduleKpiNameValue, metricData]);

 

  const openPopup = () => {
    setOpenPop(true);
    setTrendData(graphData);
    setMetricDataName(graphName);
    setPopupPrecision(precision);
    setSelectedMetricTrend(selectedValue);
    setLocalChart(localChart);
  };
  const { downloadCsv } = useExcelDownloadHook(
    graphName,
    projectNameData,

    selectedValue,
    precision
  );

  const downloadGraph = (filename) => {
    exportComponentAsPNG(componentRef, { fileName: filename });
  };
  const data = [
    {
      DisplayName: "Resolved Defects Lead Time",
      Desc: "The time it takes for the team to find the bugs in the software and to verify and validate the fix.  In short, from tracking the bugs and making sure the bugs are fixed the way they were supposed to, to closing out the issue in a reasonable time, this KPI ensures it all.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Resolved Defects",
      Desc: "A bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to you to a reasonably tolerable amount.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Defects In Progress",
      Desc: "Number of bugs which is yet to get fixed.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Defects In Backlog",
      Desc: "The set of all known and unresolved defects in the project; defect backlog in stored in the defect database.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Commits",
      Desc: "An operation which sends the latest changes of the source code to the repository, making these changes part of the head revision of the repository.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Pull Requests",
      Desc: "Let you tell others about changes you've pushed to a branch in a repository on GitHub. Once a pull request is opened, you can discuss and review the potential changes with collaborators and add follow-up commits before your changes are merged into the base branch.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "% Commits without Pull Requests",
      Desc: "This metric will highlight the percentage of code commits made to your default branch that do not have a related pull request.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Pull Request Resolution Time",
      Desc: "How long it takes to resolve pull requests.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Resolved Vulnerabilities Per Team Member",
      Desc: "",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Resolved Vulnerabilities Lead Time",
      Desc: "The time it takes to find the point of code that is open to attack until the code is  is attackfree.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Resolved Vulnerabilities Cycle Time",
      Desc: "The time it takes to start fixing the point of code that open to attack  until the code is attackfree.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Resolved Vulnerabilities",
      Desc: "Fixing  A point in your code that's open to attack.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Story Point Cycle Time",
      Desc: "The time it takes from when you start work on a user story that's ready for implementation, to when it's ready for delivery (release).",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Defects In Backlog Per Team Member",
      Desc: "Number of bugs  that are there in the backlog.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Schedule Varient",
      Desc: "This is the difference between the percentage of work completed versus the percentage expected to be completed by a particular date.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Commits without Pull Requests",
      Desc: "Commits Without a Pull Request (CWPR) is an important engineering metric for all organisations and particularly those focused on software engineering quality and DevSecOps.",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Stories Completed",
      Desc: "",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Story Points Completed Per Team Member",
      Desc: "",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Story Points Completed",
      Desc: "",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
    {
      DisplayName: "Resolved Defects Per Team Member",
      Desc: "",
      Why_it_Matters: "",
      How_to_use_it: "",
    },
  ];

  return (
    <Paper variant="outlined" square className={classes.paper}>
      <PaperHeader
        name={selectedValue}
        popup={openPopup}
        download={downloadGraph}
        selectedValue={selectedValue}
        localChart={localChart}
      />

      {showPopup ? (
        <Popup
          closePopup={handleClose}
          selectedValue={selectedValue}
          data={data}
        />
      ) : null}

      <div className="row">
        <div className="col-3 d-flex justify-content-center align-items-center">
          <FormControl
            variant="outlined"
            // className={classes.formControl}
            size="small"
          >
            <Select
              value={localChart}
              onChange={handleChartType}
              style={{
                borderRadius: "25px",
                width: "150px",
                margin: "22px 10px",
                padding: "2px 2px",
              }}
            >
              <MenuItem value={1}>Line Chart</MenuItem>

              <MenuItem value={2}>Bar Chart</MenuItem>
            </Select>
          </FormControl>
        </div>
        {!moduleKpiGraphFlag && (
          <div className="col-7 d-flex  align-items-center marginLeft:'10px'">
            <SelectField
              render={dropdown}
              value={selectedValue}
              setValue={handlerSelect}
            />
          </div>
        )}

        <div className="col-2 d-flex  align-items-center">
          {data
            ?.filter((e) => e.DisplayName === selectedValue)
            ?.map((a) => a.Desc)[0] !== "" && (
            <i
              className="fa fa-info-circle"
              style={{ fontSize: "14px", cursor: "pointer" }}
              aria-hidden="true"
              onClick={handleClick}
            >
              <span
                style={{
                  // padding: "5px",
                  fontSize: "14px",
                  cursor: "pointer",
                  fontFamily: "Lexend Deca",
                }}
              >
                Definition
              </span>
            </i>
          )}
        </div>
      </div>

      {localChart == 1 && (
        <div ref={componentRef}>
          <Graph
            body={body}
            isSprint={isSprint}
            isStories={isStories}
            isTask={isTask}
            graphData={graphData}
            level={level}
            precision={precision}
            groupType={matrixId}
            valueOfIteration={valueOfIteration}
            groupId={groupId}
            matrixName={selectedValue}
          />
        </div>
      )}

      {/* <BarChart/> */}
      {localChart == 2 && (
        <div ref={componentRef}>
          <StackChart
            graphData={graphData}
            body={body}
            isSprint={isSprint}
            isStories={isStories}
            isTask={isTask}
            level={level}
            precision={precision}
            groupType={matrixId}
            valueOfIteration={valueOfIteration}
            groupId={groupId}
            matrixName={selectedValue}
          />
        </div>
      )}
    </Paper>
  );
}
