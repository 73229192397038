import { Box, Button, Dialog, DialogTitle } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { fix } from "../../../../utils/changeDecimal";
import { Alert } from "@mui/material";
import MStepper from "../../../../components/mobileSteper";
import TrendWithCategoryAxis from "../../../../components/trendGraph/trendWithCategory";
import CommonPopup from "../../../../components/commonPopup";
import { config } from "../../../../Config/permission";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../../../utils/moduleTabApi";
import instance from "../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { GlobalData } from "../../../../context/globalData";
import EpicDropDown from "../../../../components/epicDropDown";
import RangeDatePicker from "../../../../components/RangeDatePicker";

function HealthModal({
  classes,
  resourceHealth,
  handleClose,
  getProductivityHealthStatus,
  type,
}) {
  const { iterationValueGlobal, projectRangePickerValue: rangePickerValue } =
    useContext(GlobalData);
  const [value, setValue] = useState(0);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [date, setDate] = useState(rangePickerValue);
  const [showAlert, setShowAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showTrend, setShowTrend] = useState(false);
  const { projectId } = useParams();
  const [resourceProductivity, setResourceProductivity] = useState();
  const [showInsights, setShowInsights] = useState(false);
  const { state } = useLocation();
  console.log(type, "type");
  // const alertsData = [
  //   {
  //     title: "Action: For Unusually high code churn",
  //     actions: [
  //       "Ask for a pre-commit code review and design documentation",
  //       "Ask to split the work or assign a mentor",
  //       "Ask a more senior engineer to assess what “good enough” is in the context of the project.",
  //       "If the problem is difficult, or if the domain is unfamiliar, bring in another engineer to pair program.",
  //     ],
  //   },
  //   {
  //     title: "Action: For Low Sprint Completion rate",
  //     actions: [
  //       "Use cross training and ensure knowledge transfer is consistent",
  //       "Avoid context switching and use a rolling average of the last 3-4 sprints to plan the next sprint. Remove any anomalous outliers to obtain a better average",
  //       "Use consistent definition of Done across your sprint",
  //     ],
  //   },
  //   {
  //     title: "Action: For Low utilization",
  //     actions: [
  //       "You can assign more stories or tasks to this engineer to improve utilization and efficiency",
  //     ],
  //   },
  //   {
  //     title: "Action: For Low performance",
  //     actions: [
  //       "Talk to the engineer to figure out the reason for consistent low performance",
  //       "Assign a mentor to help them understand the feature or domain.",
  //       "Work with the engineer to set up a performance improvement plan.",
  //     ],
  //   },
  //   {
  //     title: "Action: For High utilization",
  //     actions: [
  //       "Reduce the tasks assigned to this engineer as consistent high utilization can lead to burnout and attrition.",
  //     ],
  //   },
  //   {
  //     title: "Action: For High work in progress",
  //     actions: [
  //       "Insist on doing one thing at a time. If blocked, change the status appropriately.",
  //     ],
  //   },
  // ];
  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    const newDateUrl = addLeadingZeroToDateRange(dateUrl);
    let url;
    if (type?.id) {
      url = `/api/v2/teams/teamProductivitytrend?projectId=${projectId}&${newDateUrl}&teamId=${type?.id}`;
    } else {
      url = `/api/v2/resource/productivitytrend?projectId=${projectId}&resourceName=${state?.resourceName}&${newDateUrl}`;
    }
    instance
      .get(url)
      .then((res) => {
        const data = res?.data.map((e) => {
          const originalDate = parseISO(e.month);
          return {
            Status: e?.healthStatus,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setResourceProductivity(data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [date]);

  return (
    <Box
      // maxHeight={500}
      overflow={"auto"}
    >
      <div
        className={classes.closeIconSingleRes}
        onClick={() => handleClose(null)}
      >
        <div>
          <HighlightOffIcon />
        </div>
      </div>
      <Box
        padding={3}
        display={"grid"}
        gridGap={4}
        gridTemplateColumns="1fr 1fr"
        sx={{ maxHeight: 540, overflow: "auto" }}
      >
        <div>
          <label className={classes.resourceHealthLabel}>Completion (%)</label>
          <p>{resourceHealth?.completionPercentage?.toFixed()} %</p>
        </div>{" "}
        <div>
          <label className={classes.resourceHealthLabel}>Utilization (%)</label>
          <p>{resourceHealth?.utilizationPercentage?.toFixed()} %</p>
        </div>
        <div style={{ marginRight: "20px" }}>
          <label className={classes.resourceHealthLabel}>
            Performance (%){" "}
          </label>
          <p>{resourceHealth?.performancePercentage?.toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Code Churn</label>
          <p>{resourceHealth?.codeChurn?.toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>In Progress</label>
          <p>{resourceHealth?.count?.toFixed()} </p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Health Score</label>
          <p>{resourceHealth?.healthScore?.toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Health Status</label>
          <p>{getProductivityHealthStatus} </p>
        </div>
        {/* <div>
          <label className={classes.resourceHealthLabel}>
            Assigned {config.type}
          </label>
          <p>{resourceHealth?.assignedStoryPoints?.toFixed()}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Avg Assigned {config.type}
          </label>
          <p>{resourceHealth?.avgAssignStoryPoint?.toFixed()}</p>
        </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>
            Adjusted Assign <br /> {config.type}
          </label>
          <p>{fix(resourceHealth?.adjustedAssignStoryPoints)}</p>
        </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>Inprogress</label>
          <p>{resourceHealth?.count}</p>
        </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>
            Adjusted Completion <br />
            Percentage
          </label>
          <p>{fix(resourceHealth?.adjustedCompletionPercentage)}</p>
        </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>
            Completed {config.type}
          </label>
          <p>{resourceHealth?.completedStoryPoints?.toFixed()}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Display Name</label>
          <p>{resourceHealth?._id}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Performance</label>
          <p>{resourceHealth?.performance}</p>
        </div> */}
        {/* <div>
                    <label className={classes.resourceHealthLabel}>
                        Resource Health Score
                    </label>
                    <p>{fix(resourceHealth?.resourceHealthScore)}</p>
                </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>Total Sprint</label>
          <p>{resourceHealth?.totalSprint?.toFixed()}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Utilization</label>
          <p>{resourceHealth?.utilization}</p>
        </div> */}
      </Box>
      <Box paddingX={3} paddingBottom={2} display={"flex"} gridGap={10}>
        {getProductivityHealthStatus !== "Good Health" && (
          <>
            <Button
              variant="outlined"
              style={{ textTransform: "capitalize" }}
              color="primary"
              onClick={() => setShowInsights(!showInsights)}
            >
              {showInsights ? "Hide" : "Show"} Insights
            </Button>
            <Button
              variant="outlined"
              style={{ textTransform: "capitalize" }}
              color="primary"
              onClick={() => setShowAlert(!showAlert)}
            >
              {showAlert ? "Hide" : "Show"} Recommendation
            </Button>
          </>
        )}
        <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => setShowTrend(!showTrend)}
        >
          {showTrend ? "Hide" : "Show"} Trend
        </Button>
      </Box>
      <CommonPopup open={showAlert} setOpen={setShowAlert}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>{resourceHealth?.alertsData[activeStep].title}</h6>
            <ul style={{ paddingLeft: 20 }}>
              {resourceHealth?.alertsData[activeStep].actions.map(
                (action, i) => (
                  <li key={i}>{action}</li>
                )
              )}
            </ul>
          </Alert>
          <MStepper
            steps={resourceHealth?.alertsData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>
      </CommonPopup>
      <CommonPopup open={showInsights} setOpen={setShowInsights} width={600}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>Insights:</h6>
            <ul style={{ paddingLeft: 20 }}>
              Based on our analysis, you need to improve on
              <b> {resourceHealth?.additionalText} </b>so that you can make
              healthy. View Our Show Recommendation Tab to Know More...
            </ul>
          </Alert>
        </Box>
      </CommonPopup>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box display={"flex"} gap={2}>
          {/* {(value === 0 || value === 1 || value === 3) && (
            <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
          )} */}
          {(value === 0 || value === 3) && (
            <>
              <Box marginLeft={2}>
                <RangeDatePicker
                  setDate={setDate}
                  date={date}
                  isNextDate={false}
                />
              </Box>
            </>
          )}
        </Box>
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis
            body={"helthTrend"}
            data={resourceProductivity}
          />
        </Box>
      </CommonPopup>
    </Box>
  );
}

export default HealthModal;
