import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { color } from '../../Config/statusData';

const TrendWithCategoryAxis = ({ data }) => {
    const [hiddenLines, setHiddenLines] = useState([])
    const [lines, setLines] = useState([])
    const toggleLine = (category) => {
        if (hiddenLines.includes(category)) {
            setHiddenLines(hiddenLines.filter(line => line !== category));
        } else {
            setHiddenLines([...hiddenLines, category]);
        }
    };
    useEffect(() => {
        if (data.length > 0) {
            const key = []
            Object.keys(data[0]).forEach((e) => {
                if (e !== "Date") {
                    key.push(e);
                }
            })
            setLines(key)
        }
    }, [data])

    return (
        <LineChart width={800} height={400} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Date" />
            <YAxis type="category" domain={['BAD','AVERAGE', 'GOOD']} tick={{ fontSize: 12 }} />
            <Tooltip formatter={(value, name) => `${value}`} />
            <Legend onClick={(e) => toggleLine(e.dataKey)} cursor={"pointer"} />
            {
                lines.map((e, i) => (
                    <Line
                        type="monotone"
                        dataKey={e}
                        stroke={color[i]}
                        activeDot={{ r: 8 }}
                        hide={hiddenLines.includes(e)}
                    />
                ))
            }
        </LineChart>
    );
};

export default TrendWithCategoryAxis;
