import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const WIPGraph = ({ data }) => {
  const chartRef = useRef(null);
  console.log(data, "data1");

  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;

    chart.colors.step = 4;
    chart.colors.list = [am4core.color("#c98cff"), am4core.color("#f580a1")];

    // Sample data
    chart.data = data;
    // Create category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.tooltip.disabled = true;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    // Create series for value1
    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "Work In Progress";
    series1.dataFields.categoryX = "category";
    series1.name = "Work In Progress";
    series1.tooltipText = "{name}: [bold]{valueY}[/]";
    series1.stacked = false;
    // series1.columns.template.fill = am4core.color("red");

    // Create series for value2
    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "Avg Age";
    series2.dataFields.categoryX = "category";
    series2.name = "Avg Age";
    series2.tooltipText = "{name}: [bold]{valueY} Day's[/]";
    series2.stacked = false;
    // series1.columns.template.fill = am4core.color("#c767dc");

    series1.columns.template.fill = chart.colors.getIndex(0);
    series1.columns.template.stroke = chart.colors.getIndex(0);

    series2.columns.template.fill = chart.colors.getIndex(1);
    series2.columns.template.stroke = chart.colors.getIndex(1);

    // Adjust column series clustering
    series1.clustered = true;
    series2.clustered = true;

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.minHeight = 20; // Set the height of the scrollbar
    chart.scrollbarX = scrollbarX;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom"; // Position of the legend (top, bottom, left, right)

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
};

export default WIPGraph;
