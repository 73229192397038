import * as React from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { style } from "./style";
import instance from "../../utils/axiosHelper";
import { useState } from "react";
import { useEffect } from "react";
import NormalSelect from "../select/normalSelect";
import MixedBardChart from "../performanceChart/mixedBardChart";
import ScatterChart from "../performanceChart/ScatterChart";
import { Alert } from "@mui/material";
import { Box, Button } from "@material-ui/core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import RangeDatePicker from "../RangeDatePicker";
import { GlobalData } from "../../context/globalData";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../utils/moduleTabApi";
import { useMemo } from "react";
import MultiLineChart from "./TrendChart";
import { getLegend } from "./utlis/getLegend";
import { config } from "../../Config/permission";
import CircularProgress from "@mui/material/CircularProgress";

function UtilizationPerformance(props) {
  const classes = style();
  const { teamId, open, handleClose } = props;
  const [selectedType, setSelectedType] = useState("Mixed");
  const [graph, setGraph] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [alertState, setAlertState] = useState(false);
  const [showTrend, setShowTrend] = useState(false);
  const [lineChartData, setLineChartData] = useState([]);
  const [mixLoader, setMixLoader] = useState(true);
  const { projectId } = useParams();
  const { projectRangePickerValue } = React.useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [suggestionStatus, setSuggestionStatus] = useState({});

  const [performanceMedian, setPerformanceMedian] = useState();
  const [ScatterChartData, setScatterChartData] = useState([]);
  const getResourceUtilization = () => {
    const newDateUrl = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(newDateUrl);
    let url;
    const getLineChart = () => {
      setMixLoader(true);
      if (teamId) {
        url = `/api/v2/resource/perfutil?projectId=${projectId}&${dateUrl}&teamId=${teamId}`;
      } else {
        url = `/api/v2/teams/perfutil?projectId=${projectId}&${dateUrl}`;
      }
      instance
        .get(url)
        .then((res) => {
          setMixLoader(false);
          setGraph(res.data);
          // let data = res?.data?.map((item) => {
          //   return {
          //     label: item?._id,
          //     value: item?._id,
          //   };
          // });
          // setSelectedsinglePerson(data?.[0]?.value);
          // setSinglePersonData(data);
          // staticHandler(data?.[0]?.value);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getTrendChart = () => {
      let trendUrl;
      if (teamId) {
        trendUrl = `/api/v2/resource/perfutil/trends?projectId=${projectId}&${dateUrl}&teamId=${teamId}`;
      } else {
        trendUrl = `/api/v2/teams/perfutil/trends?projectId=${projectId}&${dateUrl}`;
      }
      instance
        .get(trendUrl)
        .then((res) => {
          setTrendData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTrendChart();
    getLineChart();
  };

  function calculateMedian(arr) {
    // First, sort the array in ascending order
    const sortedArr = [...arr].sort((a, b) => a - b);
    // Find the middle index
    const middle = Math.floor(sortedArr.length / 2);
    // If the array length is odd, return the middle item
    if (sortedArr.length % 2 === 1) {
      return sortedArr[middle];
    }
    // If the array length is even, return the average of the two middle items
    return (sortedArr[middle - 1] + sortedArr[middle]) / 2;
  }

  const createBarChartData = () => {
    if (selectedType && graph) {
      const adjustedCompletionPercentageArray = graph.map(
        (e) => +e.adjustedCompletionPercentage
      );
      setPerformanceMedian(calculateMedian(adjustedCompletionPercentageArray));
      if (selectedType === "Utilization") {
        let status = {};
        const result = graph.map((e) => {
          const value = (
            ((e?.lastSprintAssignedStoryPoint || 0) /
              e?.adjustedAssignStoryPoints) *
            100
          ).toFixed();
          if (+value > 100) {
            status = { ...status, OverUtilized: true };
          }
          if (+value < 50) {
            status = { ...status, UnderUtilized: true };
          }
          return {
            name: e._id,
            Utilization: value,
          };
        });
        setSuggestionStatus(status);
        setGraphData(result);
      } else if (selectedType === "Performance") {
        const result = graph.map((e) => {
          if (e.adjustedCompletionPercentage < performanceMedian + 20) {
            setSuggestionStatus({
              ...suggestionStatus,
              UnderPerformance: false,
            });
          } else {
            setSuggestionStatus({
              ...suggestionStatus,
              UnderPerformance: true,
            });
          }
          return {
            name: e._id,
            Performance: e?.adjustedCompletionPercentage,
          };
        });
        setGraphData(result);
      } else {
        const result = graph?.map((e) => {
          return {
            name: e._id,
            radius: 10,
            y: e?.adjustedCompletionPercentage,
            x: (
              (e?.lastSprintAssignedStoryPoint / e?.adjustedAssignStoryPoints) *
              100
            ).toFixed(),
          };
        });
        setScatterChartData(result);
      }
    }
  };

  const createLineBarChartData = () => {
    const chartData = [];
    const groupedData = trendData.reduce((acc, entry) => {
      const value =
        selectedType === "Performance"
          ? entry.adjustedCompletionPercentage
          : (
              ((entry?.lastSprintAssignedStoryPoint || 0) /
                entry?.adjustedAssignStoryPoints) *
              100
            ).toFixed(2);
      if (!acc[entry.displayName]) {
        acc[entry.displayName] = [];
      }
      acc[entry.displayName].push({
        time: entry?.time,
        value: value,
      });
      return acc;
    }, {});

    for (const displayName in groupedData) {
      chartData.push({
        displayName: displayName,
        data: groupedData[displayName],
      });
    }
    setLineChartData(chartData);
  };

  useEffect(() => {
    if (open) {
      getResourceUtilization();
      setAlertState(false);
      setSuggestionStatus({});
    }
  }, [date, open]);

  useEffect(() => {
    createBarChartData();
  }, [graph, selectedType, showTrend]);

  useEffect(() => {
    createLineBarChartData();
  }, [trendData, selectedType, showTrend]);

  const showSuggestionButton = useMemo(() => {
    let isShow = false;
    Object.keys(suggestionStatus).forEach((e) => {
      if (suggestionStatus[e]) {
        isShow = true;
      }
    });
    return isShow;
  }, [suggestionStatus]);
  const trendStatus = useMemo(() => {
    let status;
    if (selectedType === "Utilization") {
      const arr = [
        { value: 50, label: "Under Utilized", color: "#ffb100", arrow: "down" },
        // { value: 50, label: "Normal", color: "green" },
        { value: 100, label: "Over Utilized", color: "#FF5733", arrow: "Up" },
      ];
      status = arr;
    } else if (selectedType === "Performance") {
      const arr = [
        {
          value: Math.ceil(performanceMedian - 19),
          label: "Under Performance",
          color: "#FF5733",
          arrow: "down",
        },
        // { value: Math.ceil(performanceMedian - 19), label: "Normal", color: "#26a6bb" },
        {
          value: Math.ceil(performanceMedian + 20),
          label: "Good Performed",
          color: "green",
          arrow: "Up",
        },
      ];
      status = arr;
    }
    return status;
  }, [showTrend, graph, selectedType]);

  return (
    <Modal
      className="paginationStyling"
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        className={classes.paper}
        style={{ width: "90vw", height: "640px", overflow: "auto" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
            marginTop: "10px",
          }}
        >
          <div style={{ fontSize: "15px", fontWeight: "600" }}>
            Utilization vs Performance
          </div>
          <div>
            <HighlightOffOutlinedIcon
              style={{
                color: "#B1B1B1",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </div>
        </div>
        <Box display={"flex"} gridGap={10}>
          <div style={{ width: "300px" }}>
            <NormalSelect
              option={[
                {
                  label: `Utilization in % (based on ${
                    config.type === "Task" ? "Hours" : config.type
                  })`,
                  value: "Utilization",
                },
                {
                  label: `Performance in % (based on ${
                    config.type === "Task" ? "Completion Rate" : config.type
                  })`,
                  value: "Performance",
                },
                {
                  label: `Utilization/Performance in % ${
                    config.type === "Task" ? " " : `based on ${config.type}`
                  } `,
                  value: "Mixed",
                },
              ]}
              value={selectedType}
              handleChange={(value) => {
                setSelectedType(value);
                setAlertState(false);
                setSuggestionStatus({});
                setShowTrend(false);
              }}
            />
          </div>
          <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
        </Box>
        <div className={classes.graphWrapper}>
          {getLegend(
            selectedType,
            performanceMedian,
            classes,
            alertState,
            suggestionStatus,
            showTrend,
            teamId
          )}
          {selectedType === "Mixed" ? (
            <>
              {mixLoader ? (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"40vh"}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <ScatterChart
                  ChartData={ScatterChartData}
                  performanceMedian={performanceMedian}
                  mixLoader={mixLoader}
                />
              )}
            </>
          ) : (
            <>
              <Button
                onClick={() => setShowTrend(!showTrend)}
                variant="outlined"
                color="primary"
                style={{
                  float: "right",
                  zIndex: 2,
                  textTransform: "capitalize",
                  marginLeft: "10px",
                }}
              >
                {" "}
                {showTrend ? "Line" : "Trend"}Graph
              </Button>
              {showSuggestionButton && (
                <>
                  <Button
                    onClick={() => setAlertState(!alertState)}
                    variant="outlined"
                    color="primary"
                    style={{
                      float: "right",
                      zIndex: 2,
                      textTransform: "capitalize",
                    }}
                  >
                    {alertState ? "Hide" : "Show"} Suggestion
                  </Button>
                </>
              )}
              {showTrend ? (
                <MultiLineChart
                  chartData={lineChartData}
                  status={trendStatus}
                />
              ) : (
                <MixedBardChart
                  graphData={graphData}
                  selectedType={selectedType}
                  performanceMedian={performanceMedian}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default UtilizationPerformance;
