import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import axios from "../../utils/axiosHelper";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { GlobalData } from "../../context/globalData";
import configImg from "../../assets/configImg.png";

const JenKinsModal = ({
  // data,
  // GrantAccess,
  res,
  classes,
  index,
  skipHandler,
  configTools,
  getTools,
}) => {
  // const classes = useStyles();
  const history = useHistory();
  const {
    sonarqubeFlag,
    setSonarqubeFlag,
    setmetricDetails,
    isPlanUpgradeMessage,
    setIsPlanUpgradeMessage,
    setToolsError,
    toolIntegrationUrl
  } = useContext(GlobalData);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  const validationSchema = yup.object({
    // url: yup
    //   .string()
    //   .matches(urlRegex, "URL is not valid")
    //   .required("URL is required"),
    token: yup.string().required("Token is required"),
    username: yup.string().required("user name is required"),
    api: yup.string().required("API is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      token: "",
      api: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sonarqubeVerificationHandler(values);
      formik.resetForm();
    },
  });

  const sonarqubeVerificationHandler = (values) => {
    let payLoad = {
      type: "Jenkins",
      username: values.username,
      token: values.token,
      api: values.api,
    };
    setLoading(true);
    axios
      .post("/api/tool", payLoad)
      .then((result) => {
        if (result.data.success) {
          // skipHandler(index);
          setLoading(false);
          getTools();
          setSonarqubeFlag(!sonarqubeFlag);
          history.push({
            pathname: toolIntegrationUrl,
            // hash: "Tools Integration",
            // state: {
            //   details: "details",
            //   title: "Tools Integration",
            //   id: 6,
            //   isLoad: true,
            // },
          });
        }
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.error);
        setLoading(false);
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 3000);
        setToolsError(err?.response?.data?.error)
        if (err?.response?.data?.error === "LIMIT_REACHED") {
          setSonarqubeFlag(!sonarqubeFlag);
          setIsPlanUpgradeMessage(!isPlanUpgradeMessage);
        }
      });

    axios
      .get(`/api/tool/`)
      .then((result) => {
        result?.data?.tools.length >= 0
          ? setmetricDetails(result?.data?.tools)
          : setmetricDetails([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <Modal
        open={sonarqubeFlag}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      > */}

      <div className={classes.middelContent}>
        <div className={classes.middelContentRight}>
          <div className={`${classes.middleMainTxt} mb-3`}>
            Itechgenic app is requesting access to your Jenkins account .
          </div>

          <div className={`${classes.middleMTxtLMs}`}>
            we would like to show metrics such as deployment Frequency, Lead
            time for changes, Mean time to recovery, Change failure rate.
          </div>
          {}
          {!configTools[res] ? (
            <>
              <form className="mt-3">
                <div>
                  <TextField
                    id="username"
                    name="username"
                    className="border-bottom"
                    size="small"
                    label="User Name"
                    style={{ minWidth: 400 }}
                    InputProps={{ classes: { input: classes.input } }}
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </div>

                <div className="mt-2">
                  <TextField
                    id="token"
                    name="token"
                    className="border-bottom"
                    size="small"
                    label=" Token"
                    InputProps={{ classes: { input: classes.input } }}
                    value={formik.values.token}
                    style={{ minWidth: 400 }}
                    onChange={formik.handleChange}
                    error={formik.touched.token && Boolean(formik.errors.token)}
                    helperText={formik.touched.token && formik.errors.token}
                  />
                </div>

                <div className="mt-2">
                  <TextField
                    id="api"
                    name="api"
                    className="border-bottom"
                    size="small"
                    label="API"
                    InputProps={{ classes: { input: classes.input } }}
                    value={formik.values.api}
                    style={{ minWidth: 400 }}
                    onChange={formik.handleChange}
                    error={formik.touched.api && Boolean(formik.errors.api)}
                    helperText={formik.touched.api && formik.errors.api}
                  />
                </div>
              </form>

              <div className={classes.btns}>
                <button
                  className={classes.grntBtn}
                  onClick={() => formik.handleSubmit()}
                >
                  Grant Access
                </button>
                {/* {value} {mergeResult.length} */}
                <button
                  className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                  onClick={() => {
                    skipHandler(index);
                  }}
                >
                  Skip
                </button>
                {/* <butt className={classes.cnclBtn}>Skip</butt> */}
              </div>
            </>
          ) : (
            <>
              <p className="mt-5">Your Jenkins account has been added Successfully,Please proceed next tool integration</p>
              <button
                className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                onClick={() => {
                  skipHandler(index);
                }}
              >
                Next
              </button>
            </>
          )}
        </div>
        <div className={classes.middelContentLeft}>
          <img src={configImg} className={classes.configImg} alt="" />
        </div>
      </div>

      <div>
        {/* <h2 id="simple-modal-title">Jenkins user</h2>
        <form
          noValidate
          autoComplete="off"
          className={classes.root}
          onSubmit={formik.handleSubmit}
        >
          <div className={classes.inputWraper}>
            <InputLabel htmlFor="url" className={classes.label}>
              username
            </InputLabel>
            <TextField
              id="username"
              name="username"
              className="border-bottom"
              size="small"
              placeholder="https://dev.sonarqube.com/<your-org>"
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </div>

          <div className={classes.inputWraper}>
            <InputLabel htmlFor="token" className={classes.label}>
              Token
            </InputLabel>
            <TextField
              id="token"
              name="token"
              className="border-bottom"
              size="small"
              placeholder=" sdbhbsjjk1234nklllkk455"
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.token}
              onChange={formik.handleChange}
              error={formik.touched.token && Boolean(formik.errors.token)}
              helperText={formik.touched.token && formik.errors.token}
            />
          </div>

          <div className={classes.inputWraper}>
            <InputLabel htmlFor="API" className={classes.label}>
              API
            </InputLabel>
            <TextField
              id="api"
              name="api"
              className="border-bottom"
              size="small"
              placeholder=" sdbhbsjjk1234nklllkk455"
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.api}
              onChange={formik.handleChange}
              error={formik.touched.api && Boolean(formik.errors.api)}
              helperText={formik.touched.api && formik.errors.api}
            />
          </div>

          <div className={classes.btnWraper}>
            <Button
              variant="contained"
              className={classes.btn}
              type="button"
              onClick={() => setSonarqubeFlag(!sonarqubeFlag)}
            >
              Cancel
            </Button>
            <Button variant="contained" className={classes.btn} type="submit">
              Submit
            </Button>
          </div>
        </form> */}

        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}

        {error && (
          <div className={classes.alert}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </div>
        )}
      </div>
      {/* </Modal> */}
    </>
  );
};

export default JenKinsModal;
