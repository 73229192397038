import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function HealthStatus({ status }) {
  const color = {
    GOOD: "green",
    BAD: "red",
    AVERAGE: "orange",
  };
  const healthIcons = (status) => {
    switch (status) {
      case "Good":
        return (
          <CheckCircleOutlineIcon
            fontSize="medium"
            sx={{
              color: "green",
              marginRight: "8px",
              paddingBottom: "5px",
              fontSize: "26px",
            }}
          />
        );
      case "GOOD":
        return (
          <CheckCircleOutlineIcon
            fontSize="medium"
            sx={{
              color: "green",
              marginRight: "8px",
              paddingBottom: "5px",
              fontSize: "26px",
            }}
          />
        );
      case "BAD":
        return (
          <HighlightOffIcon
            sx={{
              color: "red",
              marginRight: "8px",
              paddingBottom: "5px",
              fontSize: "26px",
            }}
            fontSize="medium"
          />
        );
      case "AVERAGE":
        return (
          <ErrorOutlineIcon
            fontSize="medium"
            sx={{
              color: "orange",
              marginRight: "8px",
              paddingBottom: "5px",
              fontSize: "26px",
            }}
          />
        );
      default:
        return (
          <CheckCircleOutlineIcon
            fontSize="medium"
            sx={{ color: "green", marginRight: "8px", paddingBottom: "5px" }}
          />
        );
    }
  };
  if (!status) return "";
  return (
    <>
      {healthIcons(status)}
      {/* <ErrorOutlineIcon
        sx={{ color: color[status?.toUpperCase()], marginRight: "10px" }}
      /> */}
    </>
  );
}

export default HealthStatus;
