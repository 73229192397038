import { Button, DialogActions, TextField } from "@material-ui/core";
import React from "react";
import SelectField from "../../../../components/select/selectField";

function metricGroups({
  classes,
  error,
  fields,
  setFields,
  back,
  onSubmit,
  groupSequance,
  setgroupSequance,
  groupName,
  setGroupName,
  Status,
  statusValue,
  setStatusValue,
}) {
  return (
    <>
      <div className={classes.label + " " + classes.min_width}>
        <span style={{ color: "#ff0000" }}>*</span>
        Indicates Required Field
      </div>
      <form
        // noValidate
        autoComplete="off"
        className={classes.root}
        onSubmit={onSubmit}
      >
        <div className={classes.inputWraper}>
          <div className={classes.label}>
            Display Sequence
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <TextField
            // helperText={error.Sequance}
            // error={!formIsValid}

            id="sequance"
            name="Sequance"
            variant="outlined"
            size="small"
            value={groupSequance}
            onChange={(e) => {
              fields[e.target.name] = e.target.value;
              setFields(fields);
              setgroupSequance(e.target.value);
            }}
          />
        </div>
        <div className={classes.errorMsg}>{error.Sequance}</div>
        <div className={classes.inputWraper}>
          <div
            className={classes.label}
            style={{ marginRight: "43px", marginButtom: 10 }}
          >
            Metric Group
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <TextField
            id="groupName"
            name="groupName"
            variant="outlined"
            size="small"
            value={groupName}
            onChange={(e) => {
              fields[e.target.name] = e.target.value;
              setFields(fields);
              setGroupName(e.target.value);
            }}
          />
        </div>
        <div className={classes.errorMsg}>{error.groupName}</div>
        <div className={classes.inputWraper}>
          <div
            className={classes.label}
            style={{ marginRight: "40px", marginButtom: 10 }}
          >
            Status
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div className={classes.DropdownGrid}>
            <SelectField
              custom={true}
              render={Status}
              value={statusValue}
              setValue={setStatusValue}
            />
          </div>
        </div>
        <DialogActions>
          <Button onClick={() => back()}>
            <span className={classes.btnlabel}>Cancel</span>
          </Button>
          <Button onClick={onSubmit}>
            {" "}
            <span className={classes.btnlabel}>Apply</span>
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

export default metricGroups;
