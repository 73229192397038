import * as React from "react";
import { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import WallpaperSharpIcon from "@mui/icons-material/WallpaperSharp";
import Icon from "@mui/material/Icon";
import imagePic from "../../assets/image.png";
import CameraIcon from "../../assets/camera.png";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    // borderWidth: "1px",
    // borderRadius: "32px",
    background: "transparent",
    border: "none",
    margin: "5% 5%",
    // borderBottom: "solid #eeeeee 0.5px",
  },
  inputWraper: {
    width: "50%",
    display: "flex",
    borderBottom: "solid #eeeeee 0.5px",
    marginTop: "45px",
  },
  input: {
    width: "300px",
    // padding: "10px 15px",
    height: "10px",
    margin: "15px 5px",
    font: "normal normal normal 18px/22px Lexend Deca",
  },
  btn: {
    background: "rgb(94, 189, 204)",
    cursor: "pointer",
    font: "normal normal normal 16px/20px Lexend Deca",
    borderRadius: "22px",
    width: "170px",
    padding: "12px 8px",
    margin: "25px 5px",
  },
  imgIcon: {
    margin: "25px 35px",
    backgroundColor: "#F6F6F6 0% 0% no-repeat padding-box",
  },
}));
export default function AddEditUser() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [value, setValue] = React.useState(null);

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <>
      <div className={classes.imgIcon}>
        <img
          src={imagePic}
          style={{
            width: "110px",
            height: "95px",
            padding: "25px 30px",
            marginTop: "3% 5%",
            borderRadius: "35px",
            textAlign: "center",
            backgroundColor: "#F6F6F6",
          }}
        />
        <div>
          <img src={CameraIcon} 
           style={{
            width: "50px",
            height: "35px",
            padding: "3px 10px",
            backgroundColor:"#FFFFFF",
            marginLeft:"5%",
          }} />
        </div>
        <div className="row">
          <div className="col-3">
            <div className={classes.inputWraper}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                size="small"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={handleChange}
                placeholder="Fullname"
              />
            </div>
          </div>

          <div className="col-4">
            <div className={classes.inputWraper}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                size="small"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={handleChange}
                placeholder="Role"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className={classes.inputWraper}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                size="small"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={handleChange}
                placeholder="Email address"
              />
            </div>
          </div>
          <div className="col-4">
            <div className={classes.inputWraper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Invite On Date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        <div>
          <button className={classes.btn}>Add new user</button>
        </div>
      </div>
    </>
  );
}
