import { Box, Button, Dialog, DialogTitle, Stack } from "@mui/material";
import NormalSelect from "../../../../../components/select/normalSelect";
import { useState } from "react";

const TechRating = ({ open, setShowTechRatingPopup, TechnologyBody }) => {
  const [allTechnology, setAllTechnology] = useState({
    technologyId: null,
    technologySkillId: null,
    frontEndId: null,
    frontEndSkillId: null,
    BackEndId: null,
    DatabaseId: null,
    DatabaseSkillId: null,
    DomainId: null,
    DomainSkillId: null,
  });

  const handleClose = () => {
    setShowTechRatingPopup(false);
    setAllTechnology("");
  };

  const skillLevel = [
    { label: "Beginner", value: "beginner" },
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ];
  const Technology = [
    { label: "Python", value: "PL01" },
    { label: "Java", value: "PL02" },
    { label: "JavaScript", value: "PL03" },
    { label: "C#", value: "PL04" },
    { label: "C++", value: "PL05" },
    { label: "Ruby", value: "PL06" },
    { label: "PHP", value: "PL07" },
    { label: "Swift", value: "PL08" },
    { label: "Kotlin", value: "PL09" },
    { label: "TypeScript", value: "PL10" },
  ];
  const FrontEndTechnology = [
    { label: "React", value: "FF01" },
    { label: "Angular", value: "FF02" },
    { label: "Vue.js", value: "FF03" },
    { label: "Svelte", value: "FF04" },
    { label: "Ember.js", value: "FF05" },
    { label: "Backbone.js", value: "FF06" },
    { label: "JQuery", value: "FF07" },
  ];
  const BackEndTechnology = [
    { label: "Django", value: "'DB01" },
    { label: "Flask", value: "'DB02" },
    { label: "Spring Boot", value: "BF03" },
    { label: "Express.js", value: "BF04" },
    { label: "Ruby on Rails", value: "BF05" },
    { label: "ASP.NET Core", value: "BF06" },
    { label: "Laravel", value: "BF07" },
    { label: "Node.js", value: "BF08" },
    { label: "Koa.js", value: "BF09" },
    { label: "Phoenix", value: "BF10" },
  ];
  const DatabaseTechnology = [
    { label: "MySQL", value: "DB01" },
    { label: "PostgreSQL", value: "DB02" },
    { label: "MongoDB", value: "DB03" },
    { label: "SOLite", value: "DB04" },
    { label: "Redis", value: "DB05" },
    { label: "Oracle DB", value: "DB06" },
    { label: "Microsoft SQL Server", value: "DB07" },
    { label: "Cassandra", value: "DB08" },
    { label: "MariaDB", value: "DB09" },
    { label: "Firebase Realtime Database", value: "DB10" },
    { label: "DynamoDB", value: "DB11" },
  ];
  const DomainExpertise = [
    { label: "Automotive", value: "DE01" },
    { label: "Consumer Goods", value: "DE02" },
    { label: "Financial Services", value: "DE03" },
    { label: "Manufacturing", value: "DE04" },
    { label: "Telecommunications", value: "DE05" },
  ];

  // Selected technology
  const handleTechnology = (value, name) => {
    if (name === "Technology") {
      setAllTechnology({ ...allTechnology, technologyId: value });
    } else if (name === "TechnologySkill") {
      setAllTechnology({ ...allTechnology, technologySkillId: value });
    } else if (name === "FrontEnd") {
      setAllTechnology({ ...allTechnology, frontEndId: value });
    } else if (name === "FrontEndSkill") {
      setAllTechnology({ ...allTechnology, frontEndSkillId: value });
    } else if (name === "BackEnd") {
      setAllTechnology({ ...allTechnology, BackEndId: value });
    } else if (name === "BackEndSkill") {
      setAllTechnology({ ...allTechnology, BackEndSkillId: value });
    } else if (name === "Database") {
      setAllTechnology({ ...allTechnology, DatabaseId: value });
    } else if (name === "DatabaseSkill") {
      setAllTechnology({ ...allTechnology, DatabaseSkillId: value });
    } else if (name === "Domain") {
      setAllTechnology({ ...allTechnology, DomainId: value });
    } else if (name === "DomainSkill") {
      setAllTechnology({ ...allTechnology, DomainSkillId: value });
    }
  };

  // Apply Handler
  const handleApply = () => {
    if (allTechnology) {
      const techBody = [];
      if (allTechnology?.technologyId) {
        let techKey = `${allTechnology?.technologyId}`;
        console.log(techKey, typeof techKey, "techKey");
        techBody.push({
          [techKey]: allTechnology?.technologySkillId,
        });
      }
      if (allTechnology?.frontEndId) {
        techBody.push({
          [allTechnology?.frontEndId]: allTechnology?.frontEndSkillId,
        });
      }
      if (allTechnology?.BackEndId) {
        techBody.push({
          [allTechnology?.BackEndId]: allTechnology?.BackEndSkillId,
        });
      }
      if (allTechnology?.DatabaseId) {
        techBody.push({
          [allTechnology?.DatabaseId]: allTechnology?.DatabaseSkillId,
        });
      }
      if (allTechnology?.DomainId) {
        techBody.push({
          [allTechnology?.DomainId]: allTechnology?.DomainSkillId,
        });
      }
      TechnologyBody(techBody);
      setShowTechRatingPopup(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        sx={{ Padding: "0px 10px" }}
        fullWidth={"md"}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Filter Resource As Pr Tech Stack"}
        </DialogTitle>
        <Box mx={4} mb={4}>
          {/* Technology Section */}
          <Box display={"flex"} gap={4} mb={2} width={"100%"}>
            <div className="w-100">
              <div>Technology</div>
              <NormalSelect
                option={Technology}
                handleChange={(value) => handleTechnology(value, "Technology")}
              />
            </div>
            {allTechnology?.technologyId && (
              <div className="w-100">
                <div>Skill Level</div>
                <NormalSelect
                  option={skillLevel}
                  handleChange={(value) =>
                    handleTechnology(value, "TechnologySkill")
                  }
                />
              </div>
            )}
          </Box>
          <Box display={"flex"} gap={4} mb={2} width={"100%"}>
            <div className="w-100">
              <div>Front-End Technology</div>
              <NormalSelect
                option={FrontEndTechnology}
                handleChange={(value) => handleTechnology(value, "FrontEnd")}
              />
            </div>
            {allTechnology?.frontEndId && (
              <div className="w-100">
                <div>Skill Level</div>
                <NormalSelect
                  option={skillLevel}
                  handleChange={(value) =>
                    handleTechnology(value, "FrontEndSkill")
                  }
                />
              </div>
            )}
          </Box>
          <Box display={"flex"} gap={4} mb={2} width={"100%"}>
            <div className="w-100">
              <div>Backend Technology</div>
              <NormalSelect
                option={BackEndTechnology}
                handleChange={(value) => handleTechnology(value, "BackEnd")}
              />
            </div>
            {allTechnology?.BackEndId && (
              <div className="w-100">
                <div>Skill Level</div>
                <NormalSelect
                  option={skillLevel}
                  handleChange={(value) =>
                    handleTechnology(value, "BackEndSkill")
                  }
                />
              </div>
            )}
          </Box>
          <Box display={"flex"} gap={4} mb={2} width={"100%"}>
            <div className="w-100">
              <div>Database Technology</div>
              <NormalSelect
                option={DatabaseTechnology}
                handleChange={(value) => handleTechnology(value, "Database")}
              />
            </div>
            {allTechnology?.DatabaseId && (
              <div className="w-100">
                <div>Skill Level</div>
                <NormalSelect
                  option={skillLevel}
                  handleChange={(value) =>
                    handleTechnology(value, "DatabaseSkill")
                  }
                />
              </div>
            )}
          </Box>
          <Box display={"flex"} gap={4} mb={2} width={"100%"}>
            <div className="w-100">
              <div>Domain Expertise</div>
              <NormalSelect
                option={DomainExpertise}
                handleChange={(value) => handleTechnology(value, "Domain")}
              />
            </div>
            {allTechnology?.DomainId && (
              <div className="w-100">
                <div>Skill Level</div>
                <NormalSelect
                  option={skillLevel}
                  handleChange={(value) =>
                    handleTechnology(value, "DomainSkill")
                  }
                />
              </div>
            )}
          </Box>
          <Stack spacing={2} direction="row" justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleApply()}>
              Apply
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};
export default TechRating;
