import React, { useContext, useEffect, useState } from "react";
import Teams from "./team";
import InvestmentProfile from "./investmentProfile/investmentProfile";
import EffortAllocation from "./effortAllocation";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import InfoIcon from "@material-ui/icons/Info";
import EpicDropDown from "../../components/epicDropDown";
import NormalSelect from "../../components/select/normalSelect";
import { GlobalData } from "../../context/globalData";
import RangeDatePicker from "../../components/RangeDatePicker";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { Card } from "@material-ui/core";
import axios from "../../utils/axiosHelper";
import CheckSelectId from "../../components/select/CheckSelectId";

function TeamList({ showBreadCrumb = false }) {
  const { projectId } = useParams();
  const { projectRangePickerValue, setIsDefinaionPopup, setDefinationKPI } =
    useContext(GlobalData);
  const history = useHistory();
  const [date, setDate] = useState(projectRangePickerValue);
  const [iteration, setIteration] = useState(30);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const projectName = searchParams.get("projectName");
  const [allTeamList, setAllTeamList] = useState([{}]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamUrl, setTeamUrl] = useState("");
  const location = useLocation();

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    if (selectedTeam?.length > 0) {
      let url = " ";
      selectedTeam.forEach((e, index) => {
        if (index === 0) {
          url += `&teamId=${e}`;
        } else {
          url += `&teamId=${e}`;
        }
      });
      setTeamUrl(url.trim(), selectedTeam);
    } else {
      setTeamUrl("", selectedTeam);
    }
  }, [selectedTeam]);

  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return {
            name: item?.TeamName,
            id: item?.Id,
          };
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {showBreadCrumb && (
        <BreadCrumb
          backHandler={() => {
            history.goBack();
          }}
          list={["Projects", projectName]}
          active={"Teams"}
        />
      )}
      <Box display={"flex"} gap={2}>
        <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
        <>
          {/* select dropdown of month */}
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div>
            <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          </div>
        </>
        <CheckSelectId
          names={allTeamList}
          setValue={(value) => {
            setSelectedTeam(value);
          }}
          value={selectedTeam}
          defaultValue={allTeamList[0]?.id}
        />
      </Box>
      <Teams date={date} epic={epicData?.url} teamUrl={teamUrl} />
    </>
  );
}

export default TeamList;
