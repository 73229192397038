const getMetricRange = (array, num, metricName) => {
  if (!array || !metricName) return null;
  const number = parseFloat(num);
  const Target = array.find(
    (e) => e.RiskLevel == 1 && e?.DisplayName === metricName
  );
  for (const entry of array) {
    if (entry?.DisplayName === metricName) {
      const from = parseFloat(entry?.From?.$numberDecimal);
      const to = parseFloat(entry?.To?.$numberDecimal);
      if (number >= from && number <= to) {
        return { ...entry, To: Target?.To, From: Target?.From };
      }
    }
  }
  return null; // If no entry is found
};

export { getMetricRange };
