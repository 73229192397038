import React from "react";

import { Card, CardContent, makeStyles } from "@material-ui/core";

const style = makeStyles({
  root: {
    minWidth: 275,
  },
  cardContaner: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
  },
  card: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    backgroundColor: "#eff8fa",
    width: "36px",
    height: "36px",
    "border-radius": "50%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "& img": {
      maxWidth: "25px",
      maxHeight: "25px",
    },
  },
  textContianer: {
    "& h6": {
      marginBottom: "0px",
    },
  },
  sideCard: {
    display: "flex",
    alignItems: "center",
    gap: "22px",
    cursor: "pointer",
    "& span": {
      font: "normal normal normal 16px/20px Lexend Deca",
      color: "#5EBDCC",
      opacity: 1,
    },
  },
  graphCard: {
    marginTop: "20px",
  },
  subTitle: {
    display: "flex",
    gap: 10,
    alignItems: "baseline",
  },
  viewMore: {
    color: "#5EBDCC",
    textDecoration: "underline",
    cursor: "pointer",
  },

  subTitleAmt: {
    font: "normal normal normal 18px/23px Lexend Deca",
    letterSpacing: "0px",
    color: "#5EBDCC",
    opacity: 1,
  },
  subTitleText: {
    font: "normal normal normal 12px/15px Lexend Deca",
    letterSpacing: "0px",
    color: "#707070",
  },
  resourceHealthLabel: {
    fontSize: "16px",
    color: "#898585",
  },
});


function CardDetail({ img, value, name, icon, children }) {
  const useStyles = style;
  const classes = useStyles();

  return (
    <Card className={`${classes.card} customCard`}>
      <CardContent style={{ width: '100%' }}>
        <div className={classes.cardContaner}>
          <div className={classes.icon}>
            {img && <img src={img} alt="img" />}
            {icon && (
              <i
                className={icon}
                style={{
                  fontSize: "20px",
                  color: "green",
                }}
              ></i>
            )}
          </div>
          <div className={classes.textContianer}>
            <h3 style={{ fontSize: "24px" }}>{value}</h3>
            <h6>{name}</h6>
          </div>
        </div>
        {children}
      </CardContent>
    </Card>
  );
}

export default CardDetail;
