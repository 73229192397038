import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import instance from "../../utils/axiosHelper";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Card, Grid, Popover, TextField } from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import dollerImg from "../../assets/doller.png";
import { CardContent } from "@material-ui/core";

const WorkAllowTrend = ({
  TrendData,
  yLabel,
  selectedIssue,
  selectedIteration,
}) => {
  const [apiData, setApiData] = useState(null); // State to hold API response data
  const [ShowCost, setShowCost] = useState(null);
  const [constAmt, setConstAmt] = useState("");
  const [AcualCost, setAcualCost] = useState("");

  const handleClose = () => {
    setShowCost(null);
  };

  const ApplyIssueHandler = () => {
    setAcualCost(constAmt);
    setShowCost(null);
  };

  useEffect(() => {
    if (!apiData) {
      setAcualCost("");
      setConstAmt("");
    }
  }, [apiData]);

  console.log(AcualCost, "AcualCost");

  useEffect(() => {
    // Check if TrendData is available
    if (!TrendData || TrendData.length === 0) {
      return; // No data to render
    }

    // Use amCharts theme
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create("trendDiv", am4charts.XYChart);

    chart.colors.step = 4;

    // Set chart data
    chart.data = TrendData;

    // Create category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "StartDate";
    categoryAxis.renderer.grid.template.location = 0;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.outside = true;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.title.text = yLabel;

    // Use a Set to keep track of unique keys
    const uniqueKeys = new Set();

    TrendData.forEach((e) => {
      Object.keys(e).forEach((key) => {
        if (key !== "StartDate") {
          uniqueKeys.add(key);
        }
      });
    });

    // Create series dynamically
    uniqueKeys.forEach((key) => {
      if (key !== "StartDate") {
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = key.charAt(0).toUpperCase() + key.slice(1);
        series.dataFields.valueY = key;
        series.dataFields.categoryX = "StartDate";
        series.sequencedInterpolation = true;
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = `[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}`;

        // Add click event to series (bar click)
        series.columns.template.events.on("hit", (event) => {
          // Get bar key and date
          const dataContext = event.target.dataItem.dataContext;
          const barValue = Object.entries(dataContext).filter(
            ([key, value]) =>
              key !== "StartDate" && value === event.target.dataItem.valueY
          )[0][0];
          const barDate = dataContext["StartDate"];

          // Call your API here
          fetchData(barDate, barValue);
        });

        // Add label bullet
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;
      }
    });

    // Add chart legend
    chart.legend = new am4charts.Legend();
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    chart.legend.markers.template.width = 12;
    chart.legend.markers.template.height = 12;
    chart.legend.position = "bottom";
    chart.legend.itemContainers.template.paddingTop = 2;
    chart.legend.itemContainers.template.paddingBottom = 2;

    // Clean up when component unmounts
    return () => {
      chart.dispose();
    };
  }, [TrendData, yLabel, selectedIssue, selectedIteration]);

  // Effect to call API when AcualCost changes
  useEffect(() => {
    if (!apiData) return;

    // Call your API here
    fetchData(apiData.StartDate, apiData.Key);
  }, [AcualCost]);

  const fetchData = (barDate, barValue) => {
    let url = `/api/v2/resourceallocationClient/getTrendsGraphDetails?startDay=${barDate}&type=${selectedIssue}&iteration=${selectedIteration}&id=${barValue}`;
    if (AcualCost) {
      url += `&cost=${AcualCost}`;
    }
    instance
      .get(url)
      .then((data) => {
        // Update state with API data
        setApiData(data?.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        // Handle error
      });
  };

  // Render popup/modal with API data
  const renderPopup = () => {
    if (!apiData) return null;

    return (
      <div className="popup">
        <div className="popup-content">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <h5>
              {apiData?.StartDate} :{" "}
              <span className="text-primary">{apiData?.Key}</span>
            </h5>
            <div onClick={() => setApiData(null)}>
              <HighlightOffIcon />
            </div>
          </Box>
          <Box display={"flex"} gap={4} my={2}>
            <div className="d-flex">
              <PeopleAltOutlinedIcon />
              <div className="px-2">
                <strong className="px-2">{apiData?.TotalFte}</strong> of total
                FTE
              </div>
            </div>
            <div className="d-flex">
              <BookmarksOutlinedIcon />
              <div className="px-2">
                <strong className="px-2">{apiData?.StoryPoint}</strong> Issue
              </div>
            </div>
            <div className="" onClick={(e) => setShowCost(e.currentTarget)}>
              <img src={dollerImg} alt="dollerImg" width={"32px"} /> Cost $:{" "}
              <strong className="px-2">{apiData?.Cost}</strong>{" "}
            </div>
          </Box>
          <Popover
            open={Boolean(ShowCost)}
            anchorEl={ShowCost}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Card>
              <CardContent>
                <div>
                  <label>
                    Please put your fully loaded engineers cost per Month
                  </label>
                  <br />
                  <Box display={"flex"} alignItems={"center"} gridGap={2}>
                    $
                    <TextField
                      value={constAmt}
                      onChange={(e) => {
                        setConstAmt(e.target.value);
                      }}
                      style={{
                        width: "400px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </Box>
                  <br />
                  <Button
                    disabled={constAmt ? false : true}
                    onClick={() => {
                      ApplyIssueHandler();
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    {" "}
                    Apply
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Popover>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <div
                className="p-4 border overflow-auto"
                style={{ height: "250px" }}
              >
                <h5>Investment Per Issue Type</h5>
                {apiData?.issueTypes.map((item) => (
                  <div
                    style={{ fontSize: "14px" }}
                  >{`${item?._id} (${item?.Percentage}%)`}</div>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="p-4 border overflow-auto"
                style={{ height: "250px" }}
              >
                <h5>
                  Top {apiData?.UniqueResource?.length} People Work On This{" "}
                  {selectedIssue}
                </h5>
                <Grid container>
                  {apiData?.UniqueResource?.map((item) => (
                    <Grid item xs={6}>
                      <div style={{ fontSize: "14px" }}>
                        <ArrowRightIcon />
                        {item}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div id="trendDiv" style={{ width: "100%", height: "400px" }} />
      {renderPopup()}
    </div>
  );
};

export default WorkAllowTrend;
