import { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { green, red } from "@material-ui/core/colors";
import screensIcons from "../../assets/itechgenicLogin.png";
import { useFormik } from "formik";
import { GlobalData } from "../../context/globalData";
import emailIcons from "../../assets/email-logo.png";
import * as React from "react";
import * as yup from "yup";
import { pricePlan } from "../../utils/PricingPlan";
import axios from "../../utils/axiosHelper";
import { Alert, AlertTitle } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Message } from "@material-ui/icons";
import itegenic_logo4 from "../../assets/itegenic_logo4.png";
import arrow from "../../assets/arrowpath.png";

const useStyles = makeStyles((theme) => ({
  head: {
    // marginTop: "5%",
    position: "absolute",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "10% 15%",
  },
  input: {
    width: "300px",
    paddingLeft: "40px",
    height: "10px",
    margin: "22px 5px",
    font: "normal normal normal 18px/22px Lexend Deca",
  },
  notchedOutline: {
    // borderWidth: "1px",
    // borderRadius: "32px",
    background: "transparent",
    border: "none",
    margin: "5% 5%",
    // borderBottom: "solid #eeeeee 0.5px",
    width:'100%',
    borderRadius:'0px',
    borderBottom: "solid #eeeeee 0.5px",
  },
  btn: {
    display: "flex",
    // alignItems:"center",
    justifyContent: "center",
    margin: "10% 20%",
    width: "190px",
    padding: "25px 2px",
    height: "40px",
    borderRadius: "32px",
    background: "#5EBDCC",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#092C49",
    },
  },
  inputWraper: {
    display: "flex",
    // borderBottom: "solid #eeeeee 0.5px",
    // width: "200px",
    // textAlign: "left",
    overflow:'hidden',
    marginLeft:"-30px"
  },
  alert: {
    width: "50%",
    // position: "absolute",
    display: "flex",
    justifyContent: "center",
    margin: "10px",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  arrowClass: {
    top: "-100px",
    left: "-165px",
    width: "18px",
    height: "16px",
    opacity: "1",
    position:"relative",
  },
  buttonSignIN: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#5EBDCC",
    marginLeft:"2px",
  },
  formBlock: {
  // border:"2px solid black",
  marginLeft:"-42px",
  marginTop:"68px",
  },
  usrImg: {
    position: "relative",
    top: "15px",
    left: "35px",
    width: "20px",
    height: "14px",
    marginTop: "4%",
    // marginLeft: "12%",
  },
}));

export default function ForgetPassword() {
  const classes = useStyles();
  const { setIsLogin, setRole, setCurrentRender } = useContext(GlobalData);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const { isMailSend, setIsMail } = useState(false);
  const [statusMessage, setStatusMessage] = useState(true);

  const handleChange = () => {
    setChecked(!checked);
  };
  let history = useHistory();
  const validationSchema = yup.object({
    email: yup
      .string("Enter your Username")
      // .email("Enter a valid email")
      .required("Please enter your registered Email"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      setLoading(true);
      axios
        .post("/api/auth/forgotpassword", values)
        .then((result) => {
          console.log("result", result);
          if (result.data.success) {
            setStatusMessage(false);
            setLoading(false);
            setCurrentRender(0);
            setIsMail(true);
            formik.resetForm();
        
          }
        })
        .catch((err) => {
          setErrorMessage(err.response?.data?.error);
          setLoading(false);
          // setError(true);
          // setTimeout(function () {
          //   setError(false);
          // }, 3000);
        });
    },
  });


  const loginBackHandler = () => {
    history.push("/login");
  };

  return (
    <div
      className="row "
      // style={{
      //   top: "0px",
      //   right: "0px",
      //   bottom: "0px",
      //   left: "0px",
      //   position: "absolute",
      // }}
    >
      <div
        className="col-7 "
        style={{ width: "50%", backgroundColor: "#5EBDCC", height: "100vh" }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={itegenic_logo4}
              alt="itechgenic"
              style={{
                width: "429px",
                height: "424px",
                marginTop: "15%",
                textAlign: "center",
                backgroundColor: "#5EBDCC",
              }}
            />
          </div>
          <div
            className="col-12 d-flex justify-content-center"
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "800",
              letterspacing: "0px",

              opacity: "0.5",
            }}
          >
            Enhance your cycle time and improve code quality.
          </div>
        </div>
      </div>
      <div className="col-5">
        <div className={classes.head}>
          <div className={classes.arrowClass}>
            <span className={classes.buttonSignIN} onClick={loginBackHandler}>
              <img src={arrow} />
            </span>
          </div>
          {statusMessage ? (
            <>
              {/* <div className={classes.arrowClass}>
                <span
                  className={classes.buttonSignIN}
                  onClick={loginBackHandler}
                >
                  <img src={arrow} />
                </span>
              </div > */}
              <div className={classes.formBlock}>
                <form
                  noValidate
                  autoComplete="off"
                  className={classes.root}
                  onSubmit={formik.handleSubmit}
                >
                  {/* <Message>{statusMessage}</Message> */}
                  <h4
                    style={{
                      color: "black",
                      font: "normal normal normal 24px/22px Lexend Deca",
                      letterspacing: "0px",
                      color: " #000000",
                      opacity: "1",
                      marginBottom: "15px",
                    }}
                  >
                Forgot password
                  </h4>
                  {/* <h1
                    style={{
                      font: "normal normal normal 14px/22px Lexend Deca",
                      marginBottom: "25px",
                      marginTop: "15px",
                    }}
                  >
                    Please enter your email
                  </h1> */}
                  <div className={classes.inputWraper}>
                    {/* <img
                      src={emailIcons}
                      alt="email-logo"
                      style={{
                        width: "20px",
                        height: "14px",
                        marginTop: "8%",
                      }}
                    /> */}
                      <img src={emailIcons} className={classes.usrImg} />
                    <TextField
                      id="email"
                      name="email"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      placeholder="Email address"
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.btn}
                      disabled={loading}
                    >
                      Submit
                    </Button>

                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </form>
              </div>
              {error && (
                <div className={classes.alert}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                </div>
              )}
            </>
          ) : (
            <>
              <h4
                style={{
                  marginTop: "130px",
                  width: "340px",
                  marginLeft: "-40px",
                }}
              >
                Password reset link has been sent. Please Check Your Mail.
              </h4>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
