import { Box, Button, ButtonGroup, Card } from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import OverviewTrend from "./OverviewTrend";

const SprintTrend = ({ graphData, setTrendType, TrendType, loading }) => {
  const selectedType = (type) => {
    setTrendType(type);
  };
  return (
    <Box my={3}>
      <Card>
        <Box
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          variant="outline"
        >
          <h5>Sprint Overview Trend</h5>
          <Box>
            <ButtonGroup size="small" aria-label="Small button group">
              <Button onClick={() => selectedType("Story Point")} key="one">
                Story Point
              </Button>
              <Button onClick={() => selectedType("Issue")} key="two">
                Issue
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        {loading ? (
          <OverviewTrend data={graphData} YAxisLabel={TrendType} />
        ) : (
          <Box
            sx={{
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default SprintTrend;
