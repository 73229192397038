import axios from "../../src/utils/axiosHelper";

 export const pricePlan =()=>{
    axios
    .get("/api/pricingplans/userplan")
    .then((result) => {
      if (result?.data?.success) {
       localStorage.setItem("pricePlan", JSON.stringify(result.data.data));
      }
    })
    .catch((err) => {
    console.log('error',err);
    });
}