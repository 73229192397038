import { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import SelectField from "../../components/select/selectField";
import MaterialGrid from "../../components/overview";
import RangeSelector from "../../components/rangeSelector";
import { GlobalData } from "../../context/globalData";

const useStyles = makeStyles((theme) => ({
  fieldGrid: {
    position: "absolute",
    top: "-10px",
    zIndex: 1000,
    display: "flex",
    marginTop: "25px",
    width: "260px",
    justifyContent: "space-between",
  },
}));

export default function Template(props) {
  const {
    key,
    subTab,
    Risks,
    riskValue,
    setRiskValue,
    Columns,
    tableData,
    setTeamValue,
    completeValue,
    setCompleteValue,
    complete,
    teamLink,
    setRender,
    isLoading,
    Metric,
    metricValue,
    setMetricValue,
    rangePickerValue,
    setRangePickerValue,
    Iteration,
    setIteration,
    iterationValue,
  } = props;

  const {
    // iteration,
    // iterationValueGlobal,
    // setIterationValueGlobal,
    setRiskActive,
  } = useContext(GlobalData);
  const classes = useStyles();
  const [openRange, setOpenRange] = useState(false);

  const customRangePickerHandler = (val) => {
    setRangePickerValue(val);
  };

  function ScrollFun() {
    setTimeout(() => {
      document.getElementById("mainRiskAdvisor").style.minHeight = "auto";
    }, 5000);
  }
  return (
    <div
      style={{ position: "relative", marginTop: "15px" }}
      id="mainRiskAdvisor"
    >
      <div className={classes.fieldGrid}>
        <div>
          <SelectField
            input={
              <Button
                onClick={() => setOpenRange(true)}
                style={{
                  border: "1px solid #b7b7b7",
                  width: "150px",
                  textTransform: "capitalize",
                  padding: "5px",
                  fontSize: "16px",
                  fontWeight: 400,
                  paddingLeft: "14px ",
                  paddingRight: "11px",
                  justifyContent: "space-between",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                {rangePickerValue.isCustom
                  ? "Custom"
                  : rangePickerValue.range || ""}
              </Button>
            }
            onClick={() => setOpenRange(true)}
          />
          <RangeSelector
            open={openRange}
            setOpen={setOpenRange}
            pickerHandler={customRangePickerHandler}
            value={rangePickerValue}
          />
        </div>
        <SelectField
          //  ScrollFun={(val)=>ScrollFun(val)}
          render={Iteration}
          value={iterationValue}
          setValue={setIteration}
          RiskAdvisor={true}
        />
        {Risks && (
          <SelectField
            ScrollFun={(val) => ScrollFun(val)}
            render={Risks}
            value={riskValue}
            setValue={setRiskValue}
          />
        )}
        {complete && (
          <SelectField
            ScrollFun={(val) => ScrollFun(val)}
            render={complete}
            value={completeValue}
            setValue={setCompleteValue}
          />
        )}
        {Metric && (
          <SelectField
            ScrollFun={(val) => ScrollFun(val)}
            render={Metric}
            value={metricValue}
            setValue={setMetricValue}
          />
        )}
      </div>
      <div className={classes.table}>
        <MaterialGrid
          key={metricValue}
          columns={Columns}
          data={tableData}
          teamLink={teamLink}
          setTeamValue={setTeamValue}
          setRender={setRender}
          isLoading={isLoading}
          level="risk"
          riskType={Columns[0]?.title}
          subTab={subTab}
        />
      </div>
    </div>
  );
}
