import { Dialog } from "@mui/material";
import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import SummaryTrend from "./SummaryTrend";

const SummaryTable = ({
  onClose,
  open,
  columnData,
  summaryTrend,
  tableTitle,
}) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      sx={{ Padding: "0px 10px" }}
      fullWidth={"xl"}
      maxWidth={"xl"}
      // style={{ maxWidth: "800px" }}
    >
      <Box p={2} className="d-flex justify-content-between">
        <div>{tableTitle}</div>
        <div onClick={() => handleClose()} className="cursorPointer">
          <CancelIcon />
        </div>
      </Box>
      <Box className="d-flex justify-content-evenly">
        <div style={{ width: "40%" }} className="border m-2 p-3">
          <TableContainer component={Paper}>
            <Table sx={{ Width: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "lightgray" }}>
                  <TableCell width={200}></TableCell>
                  <TableCell
                    align="center"
                    width={200}
                    style={{ fontSize: "14px" }}
                  >
                    Before
                  </TableCell>
                  <TableCell
                    align="center"
                    width={200}
                    style={{ fontSize: "14px" }}
                  >
                    After
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {columnData?.tableData?.map((row) => (
                  <TableRow
                    key={row?.rowTitle}
                    sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>{row?.rowTitle}</strong>
                    </TableCell>
                    <TableCell align="center">
                      {row?.rowTitle === "Projection" ? (
                        <>
                          {row?.before}&nbsp;{" "}
                          {row?.beforeStatus && (
                            <div
                              className={
                                row?.beforeStatus?.includes("On Time")
                                  ? "delayOnTimeAfter"
                                  : "delayOnTime"
                              }
                            >
                              {row?.beforeStatus}
                            </div>
                          )}
                        </>
                      ) : (
                        row?.before
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.rowTitle === "Projection" ? (
                        <>
                          {row?.after}&nbsp;{" "}
                          {row?.afterStatus && (
                            <div
                              className={
                                row?.afterStatus?.includes("On Time")
                                  ? "delayOnTimeAfter"
                                  : "delayOnTime"
                              }
                            >
                              {row?.afterStatus}
                            </div>
                          )}
                        </>
                      ) : (
                        row?.after
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
          {columnData?.message && (
            <div className="accelerateDay">{columnData?.message}</div>
          )}
        </div>
        <div style={{ width: "50%" }} className="border m-2">
          <h4 className="text-center">Summary</h4>
          <SummaryTrend data={summaryTrend} />
        </div>
      </Box>
    </Dialog>
  );
};

export default SummaryTable;
