import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../utils/axiosHelper";
import { GlobalData } from "../../context/globalData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import { pricePlan } from "../../utils/PricingPlan";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    marginTop: "25px",
  },
}));

const EmailVerification = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { setIsLogin, setRole, setCurrentRender } = useContext(GlobalData);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const verificationHandler = () => {
    setLoading(true);
    axios
      .post(`${"/api/auth/verifyemail"}/${id}`)
      .then((result) => {
        if (result.data.success) {
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("user", JSON.stringify(result.data.user));
          localStorage.setItem(
            "isSetupDone",
            JSON.stringify(result.data.user.isSetupDone)
          );
          pricePlan();
          setRole(result.data.user.isAdministrator);
          setIsLogin(true);
          setLoading(false);
          setCurrentRender(5);
        }
      })
      .catch((err) => {
        setErrorMessage(err.response?.data?.error);
        setLoading(false);
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 3000);
      });
  };

  useEffect(() => {
    verificationHandler();
  }, []);
  return (
    <>
      <h3 style={{ textAlign: "center" }}>E-Mail Verification</h3>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}

      {error && (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
};

export default EmailVerification;
