import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

import RiskWrapper from "../../components/overview/riskWrapper";
import { useContext } from "react";
import { GlobalData } from "../../context/globalData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    display: "flex",
  },
  risk: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    marginLeft: "40px",
  },
}));

export default function ProjectDisplay(props) {
  const { setSelectedTab, currentRender ,moduleKpiGraphFlag} = useContext(GlobalData);
  const classes = useStyles();
  const { name, risk, riskWidth } = props;
  const history = useHistory();

  const backHandler = () => {
    history.push(`/riskadvisor/${currentRender}`);
    setSelectedTab("");
  };
  const priceInfo = JSON.parse(localStorage.getItem("pricePlan"));
  const isEnabledRisk = priceInfo?.pricingPlan?.featureList?.risk?.isEnabled;
  return (
    <>
      {!moduleKpiGraphFlag && (
        <div className={classes.root}>
          <div className={classes.name}>
            {/* <IconButton size="small" onClick={backHandler}>
        <ArrowBackIosIcon />
      </IconButton> */}
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", marginLeft: "12px" }}
            >
              {name}
            </Typography>
          </div>
          {/* {isEnabledRisk && (
      <div className={classes.risk}>
        <Typography
          variant="caption"
          style={{ fontWeight: "bold", marginRight: "12px" }}
        >
          Risk
        </Typography>
        <RiskWrapper riskWidth={riskWidth}>{risk}</RiskWrapper>
      </div>
    )} */}
          {/* <div className={classes.btn}>
      <Button variant="contained" style={{backgroundColor:'#B7DEF1', border:'1px solid #888888'}} disableElevation>Launch Workforce Advisor</Button>
    </div> */}
        </div>
      )}
    </>
  );
}
