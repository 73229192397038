import React from "react";
import Group from "./group/group";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { makeStyles } from "@material-ui/core/styles";
import MatricLevelRisk from "./matricLevelRisk/matricLevel";
import Matric from "./metric/metric";
import ProjectList from "./Project";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
function Metric() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [value, setValue] = React.useState(page || "3");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(`/settings/Metrics?page=${newValue}`);
  };
  const useStyles = makeStyles((theme) => ({
    btnStyle: {
      float: "right",
      background: "#5EBDCC",
      cursor: "pointer",
      font: "normal normal normal 16px/20px Lexend Deca",
      borderRadius: "22px",
      width: "180px",
      padding: "8px 8px",
      margin: "15px 5px",
      color: "#fff",
      border: "none",
      position: "absolute",
      top: "0px",
      right: 10,
      zIndex: 1,
    },
    btngroup: {
      marginTop: "10px",
    },
    searchnButton: {
      display: "flex",
      margin: "25px 25px",
    },
    headerlabel: {
      margin: "20px 5px",
      font: "normal normal normal 20px/23px Lexend Deca",
    },
    tableStyle: {
      position: "relative",
      "& .MuiTextField-root": {
        position: "absolute",
        left: "10px",
        background: "#f8f8f8",
        borderRadius: 20,
        "& .MuiInput-root": {
          height: 45,
        },
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className="nestingTabs">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            className="m-2"
          >
            {/* <Tab
              className="border"
              style={{
                font: "normal  600 16px/28px Lexend Deca",
                color: "#5EBDCC",
                borderRadius: "6px 6px 0px 0px",
                boxShadow: "inset lightgray 0px 0px 8px 0px",
              }}
              label="Groups"
              value="1"
            /> */}
            {/* <Tab
              className="border me-4"
              style={{
                font: "normal  600 16px/28px Lexend Deca",
                color: "#5EBDCC",
                borderRadius: "6px 6px 0px 0px",
                boxShadow: "inset lightgray 0px 0px 8px 0px",
              }}
              label="Metrics"
              value="2"
            /> */}
            <Tab
              className="border  me-4"
              style={{
                font: "normal  600 16px/28px Lexend Deca",
                color: "#5EBDCC",
                borderRadius: "6px 6px 0px 0px",
                boxShadow: "inset lightgray 0px 0px 8px 0px",
              }}
              label="Metrics level risks  "
              value="3"
            />
            {/* <Tab
              className="border"
              style={{
                font: "normal  600 16px/28px Lexend Deca",
                color: "#5EBDCC",
                borderRadius: "6px 6px 0px 0px",
                boxShadow: "inset lightgray 0px 0px 8px 0px",
              }}
              label="Team"
              value="5"
            /> */}
          </TabList>
        </Box>
        {/* <TabPanel value={value} index={"1"}>
          <Group classes={classes} />
        </TabPanel> */}
        {/* <TabPanel value={value} index={"2"}>
          <Matric classes={classes} />
        </TabPanel> */}
        <TabPanel value={value} index={"3"}>
          <MatricLevelRisk classes={classes} />
        </TabPanel>
        {/* <TabPanel value={value} index={"2"}>
          <ProjectList classes={classes} />
        </TabPanel> */}
      </TabContext>
    </div>
  );
}

export default Metric;
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value == index && <h4>{children}</h4>}</div>;
}
