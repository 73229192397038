import React from "react";
import { useEffect, forwardRef } from "react";
import ScheduleVarient from "./GraphDetailsScreen/scheduleVarient";
import CloseIcon from "@material-ui/icons/Close";
import axios from "./../../utils/axiosHelper";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
export default function GraphDetails(props) {
  const { openPop, setOpenPop, graphData} = props;
  const [errorMssg, setErrorMssg] = useState("");
  const [success, setSuccess] = useState(true);
  const [responseDataOfMatrix, setResponseDateOfmatri] = useState();
  const [isLoading, setLoading] = useState(false);
  const graphLevel = "graphDetails";

  let matrixName = graphData.matrixName;
  let groupIDobj = {
    2: "team_id",
    3: "resource_id",
    4: "module_id",
  };
  let projecturl = `/api/graph/view/get-details?metric_id=${graphData.matrixId}&date=${graphData.date}&iteration=${graphData.Iteration}&group_type_id=${graphData.groupId}&project_id=${graphData.projectId}`;
  let teamLevelUrl = `/api/graph/view/get-details?metric_id=${
    graphData.matrixId
  }&date=${graphData.date}&iteration=${graphData.Iteration}&group_type_id=${
    graphData.groupId
  }&project_id=${graphData.projectIdParams}&${groupIDobj[graphData.groupId]}=${
    graphData.projectId
  }`;
  const getData = () => {
    setLoading(true);
    let url = graphData.groupId === 1 ? projecturl : teamLevelUrl;

    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        setResponseDateOfmatri(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(err?.response?.data?.success);
        setErrorMssg(err?.response?.data?.error);
      });
  };
  useEffect(() => {
    getData();
  }, [graphData, openPop]);
  const componetOfDetailes = (props) => {
    switch (props) {
      case "Total Work Delivered (Story, Task, Bug)":
        return (
          <ScheduleVarient
            graphData={graphData}
            matrixName={matrixName}
            level={graphLevel}
            responseDataOfMatrix={responseDataOfMatrix}
            isLoading={isLoading}
            setOpenPop={setOpenPop}
            isExpant={false}
          />
        );
      case "Schedule Varient":
        return (
          <ScheduleVarient
            graphData={graphData}
            matrixName={matrixName}
            level={graphLevel}
            responseDataOfMatrix={responseDataOfMatrix}
            isLoading={isLoading}
            setOpenPop={setOpenPop}
            isExpant={false}
          />
        );
      case "Commits":
        return (
          <ScheduleVarient
            graphData={graphData}
            matrixName={matrixName}
            level={graphLevel}
            responseDataOfMatrix={responseDataOfMatrix}
            isLoading={isLoading}
            setOpenPop={setOpenPop}
            isExpant={false}
          />
        );
        case "Commits Size":
          return (
            <ScheduleVarient
              graphData={graphData}
              matrixName={matrixName}
              level={graphLevel}
              responseDataOfMatrix={responseDataOfMatrix}
              isLoading={isLoading}
              setOpenPop={setOpenPop}
              isExpant={true}
            />
          );
    }
  };
  const backHandler = () => {
    setOpenPop(false);
  };
  const handleClose = () => {
    setOpenPop(false);
  };
  return (
    <Dialog
      fullScreen
      open={openPop}
      onClose={handleClose}

    >
      <div style={{ padding: "20px" ,marginTop:"90px" }}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "400px",
            }}
          >
            <CircularProgress />{" "}
          </div>
        ) : success ? (
          componetOfDetailes(matrixName)
        ) : (
          <>
            <div
              onClick={backHandler}
              style={{
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                fontSize: "30px",
                fontWeight: "800",
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {errorMssg}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}
