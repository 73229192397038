import React, { useState, useEffect, useContext } from "react";
import { GlobalData } from "../../../context/globalData";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import Navbar from "../../../hoc/layout/navbar";
import AzureModal from "../../../screens/authentication/azure/AzureModal";
import SonarqubeModal from "../../../screens/authentication/sonarqube/SonarqubeModal";
import { green } from "@material-ui/core/colors";
import axios from "../../../utils/axiosHelper";
import Logo from "../../../assets/itechLogo.png";
import jirIcon from "../../../assets/jirIcon.png";
import gitLab from "../../../assets/gitlab.png";
import azureIcon from "../../../assets/azureIcon.png";
import bitBucketIcon from "../../../assets/bitBucketIcon.png";
import githubIcon from "../../../assets/githubIcon.png";
import sonarCube from "../../../assets/sonarcube.png";
import jenkins from "../../../assets/jenkins_logo.png";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import JenKinsModal from "../../../screens/authentication/jenkins";
import JiraScreen from "./JiraScreen";
import BitBucket from "./bitbucket";
import GitHub from "./github";
import GitLab from "./gitlab";
import { Avatar } from "@material-ui/core";
// import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "4px solid #092c49",
    borderRadius: "20px",
    padding: "40px",
    margin: "0px",
    boxShadow: "none",
  },
  toggle: {
    width: 40,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translate(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "green" : "green",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
  inputWraper: {
    display: "grid",
    gridTemplateColumns: "168px 136px",
    textAlign: "left",
    alignItems: "center",
  },
  label: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#082C49",
    marginBottom: "15px",
    marginTop: "15px",
    marginRight: "30px",
  },
  btn: {
    backgroundColor: "#082c49",
    width: "200px",
    fontSize: "14px",
    color: "#fff",
    height: "40px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  btnWraper: {
    width: "200px",
    marginTop: "20px",
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    marginTop: "25px",
  },
  togalebtn: {
    width: "100%",
    display: "flex",
    marginBottom: "30px",
    justifyContent: "space-between",
  },
  dropdownIcons: {
    border: "1px solid lightgray",
    padding: "2px 10px",
    borderRadius: "10px",
  },
  dateBlock: {
    display: "flex",
  },
  description: {
    width: "130%",
    wordWrap: "break-word",
    fontSize: "12px",
    gridColumn: "1 / span 2",
    gridRow: 3,
  },
  desc: {
    width: "468px",
    height: "23px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  mainCtainer: {
    top: "0px",
    left: "0px",
    padding: "10px 50px",
    // width: "1366px",
    minHeight: "600px",
    // background: "#FCFCFC 0% 0% no-repeat padding-box",
  },

  headerLogo: {
    // top: "30px",
    // left: "36px",
    width: "151px",
    height: "30px",
    // position: "relative",
  },

  headingUName: {
    top: "30px",
    left: "266px",
    position: "relative",
    textAlign: "left",
    font: "normal normal normal 24px/30px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
    padding: "36px",
  },

  innerBox: {
    top: "50px",
    padding: 20,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 6px 10px #00000003",
    borderRadius: "14px",
    opacity: "1",
    position: "relative",
  },

  settingInnerBox: {
    boxShadow: "0px 6px 10px #00000003",
    borderRadius: "14px",
  },

  innerBoxHeading: {
    // top: "50px",
    // left: "50px",
    textAlign: "left",
    font: "normal normal 600 24px/30px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
    position: "relative",
  },

  innerBoxHtext: {
    top: "5px",
    // left: "50px",
    textAlign: "left",
    font: "normal normal normal 14px/18px Lexend Deca",
    letterSpacing: "0px",
    color: "#7E7E7E",
    opacity: "1",
    position: "relative",
  },

  boxDiv: {
    display: "flex",
    // top: "30px",
    position: "relative",
    marginTop: "30px",
    justifyContent: "flex-start",
  },

  trackBox: {
    // top: "20px",
    // left: "50px",
    // width: "280px",
    // height: "344px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #E5E5E5",
    borderRadius: "10px",
    opacity: "1",
    position: "relative",
    padding: "20px 18px 60px 20px;",
    marginRight: "15px",
    maxWidth: "280px",
    width: "100%",
  },

  innerBoxtxt: {
    // top: "20px",
    // left: "20px",
    position: "relative",
    textAlign: "left",
    font: "normal normal 600 20px/25px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
  },

  headingCotainer: {
    padding: "20px",
  },

  subHeading: {
    textAlign: "left",
    font: "normal normal normal 12px/15px Lexend Deca",
    letterSpacing: "0px",
    color: "#7E7E7E",
    marginTop: "2px",
  },

  checkSetting: {
    display: "flex",
    padding: "20px 0px 0px 0px",
  },

  pdlr: {
    padding: "0 5px",
  },

  radioBox: {
    width: "15px",
    height: "15px",
    border: "1px solid #5EBDCC",
    borderRadius: "10px",
    margin: "5px",
  },

  trackImg: {
    width: "20px",
    height: "20px",
    // background: transparent url(img/Mask Group 1.png) 0% 0% no-repeat padding-box;
    opacity: "1",
  },

  ImgName: {
    textAlign: "left",
    font: "normal normal normal 16px/20px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
  },

  submtBtnDiv: {
    // textAlign: "center",
    marginTop: "60px",
  },

  submtBtn: {
    // top: 618px;
    // left: 617px;
    width: "132px",
    height: "45px",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    borderRadius: "30px",
    border: "1px solid #5EBDCC",
    opacity: "1",
    color: "#ffffff",
  },
  disableBtn: {
    // top: 618px;
    // left: 617px;
    width: "132px",
    height: "45px",
    background: "grey 0% 0% no-repeat padding-box",
    borderRadius: "30px",
    border: "1px solid #5EBDCC",
    opacity: "1",
    color: "#ffffff",
  },
  /****third row */
  jiraTxt: {
    font: "normal normal 600 16px/25px Lexend Deca !important",
    letterSpacing: "0px",
    color: "#1E1E1E !important",
    verticalAlign: "middle  !important",
    display: "grid  !important",
    gridTemplateColumns: " 25px 1fr 25px  !important",
    justifyContent: "center  !important",
  },

  innerMainHeading: {
    display: "flex",
  },

  JiraImage: {
    marginLeft: "100px",
  },
  conf: {
    color: "white",
    borderRadius: "20px",
    backgroundColor: "green",
    padding: "5px",
  },
  notConf: {
    color: "white",
    borderRadius: "20px",
    backgroundColor: "red",
    padding: "5px",
  },
  hlines: {
    width: "173px",
    height: "4px",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    position: "relative",
    right: "50px",
    marginTop: "20px",
  },

  newHlines: {
    position: "relative",
    bottom: "9px",
    display: "none",
  },

  middleMainTxt: {
    textAlign: "left",
    font: "normal normal 600 24px/30px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
  },

  middelContentRight: {
    width: "45%",
  },

  middelContentLeft: {
    width: "55%",
  },

  middleMTxt: {
    textAlign: "left",
    font: "normal normal 600 16px/20px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    marginTop: "30px",
  },

  middleMTxtS: {
    textAlign: "left",
    font: "normal normal normal 14px/18px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    marginTop: "30px",
  },

  middleMTxtL: {
    textAlign: "left",
    font: "normal normal 600 14px/18px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
  },

  middleMTxtLM: {
    textAlign: "left",
    font: "normal normal 600 14px/18px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    marginTop: "40px",
  },

  middleMTxtLMs: {
    textAlign: "left",
    font: "normal normal 600 14px/18px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
  },

  grntBtn: {
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    borderRadius: "30px",
    border: "1px solid #5EBDCC",
    color: "#ffffff",
    padding: "5px 15px",
    marginRight: "20px",
  },

  cnclBtn: {
    font: "normal normal normal 18px/22px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
  },

  btns: {
    marginTop: "40px",
  },

  middelContent: {
    display: "flex",
    marginTop: "30px",
  },

  configImg: {
    float: "right",
  },

  innerBoxHeadingFld: {
    textAlign: "center",
    font: "normal normal 600 24px/30px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
    position: "relative",
  },

  fldsNew: {
    borderTop: "0px",
    borderLeft: "0px",
    borderRight: "0px",
    borderBottom: "1px solid #5EBDCC !important",
    width: "100%",
    maxWidth: "270px",
    fontSize: "16px",
  },

  fldRow: {
    margin: "30px",
  },
  addMoreIntegration: {
    textAlign: "left",
    font: "normal normal normal 20px/25px Lexend Deca",
    color: "#5EBDCC",
    border: "none",
    outline: "none",
    background: "none",
  },
}));

const SetupForm = ({ isSetting = false }) => {
  const { toolError, setToolsError } = useContext(GlobalData);
  const classes = useStyles();
  const [configTools, setConfigTools] = useState({
    Jira: false,
    Azure: false,
    Github: false,
    Gitlab: false,
    Bitbucket: false,
    Sonarqube: false,
    Jenkins: false,
  });
  const redirectDashboard = () => {
    axios
      .post("/api/auth/setup/tool", {})
      .then((result) => {
        if (result.data.success) {
          localStorage.setItem("isSetupDone", true);

          window.location.href = "/project";
        }
      })
      .catch((err) => {});
  };

  const imageConst = {
    Azure: azureIcon,
    Jira: jirIcon,
    Gitlab: gitLab,
    Bitbucket: bitBucketIcon,
    Github: githubIcon,
    Sonarqube: sonarCube,
    Jenkins: jenkins,
  };

  const [tools, setTools] = useState({});
  // const history = useHistory();
  const getTools = () => {
    axios
      .get("/api/tool")
      .then((res) => {
        setTools(res.data.tools);
        const tools = res.data.tools;
        const confJira = tools?.find((ele) => ele.name === "Jira").isConfigured;
        const confAzure = tools?.find(
          (ele) => ele.name === "Azure"
        ).isConfigured;
        const confGithub = tools?.find(
          (ele) => ele.name === "Github"
        ).isConfigured;
        const confGitlab = tools?.find(
          (ele) => ele.name === "Gitlab"
        ).isConfigured;
        const confBitbucket = tools?.find(
          (ele) => ele.name === "Bitbucket"
        ).isConfigured;
        const confSonarqube = tools?.find(
          (ele) => ele.name === "Sonarqube"
        ).isConfigured;
        const confJenkins = tools?.find(
          (ele) => ele.name === "Jenkins"
        )?.isConfigured;
        setConfigTools({
          Jira: confJira,
          Azure: confAzure,
          Github: confGithub,
          Gitlab: confGitlab,
          Bitbucket: confBitbucket,
          Sonarqube: confSonarqube,
          Jenkins: confJenkins,
        });
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getTools();
  }, []);
  // const [tSystem, setTSystem] = useState([]);
  const [isLogout, setLogout] = useState(false);
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState([]);
  const [mergeResult, setMergeResult] = useState([]);
  const user = JSON.parse(localStorage.getItem("user")).name;

  const getValues = (e) => {
    e.preventDefault();
    setMergeResult(selected);
    localStorage.setItem("selectedTool", JSON.stringify(selected));
    setVisible((prev) => !prev);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedToolIndex", newValue);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const skipHandler = (index) => {
    localStorage.setItem("selectedToolIndex", index + 1);
    setToolsError("");
    if (value < mergeResult.length - 1) {
      handleChange("", index + 1);
    } else {
      redirectDashboard();
      setVisible(false);
    }
  };

  const backButton = () => {
    setVisible((prev) => !prev);
  };
  const GrantAccess = (res) => {
    const selectedTool = tools?.find((ele) => ele.name === res);
    const url = selectedTool.url;
    window.open(url, "_blank");
    setToolsError("");
  };
  const handleChangeCheckBox = (event) => {
    setSelected((prevSelectedValues) =>
      prevSelectedValues.includes(event.target.name)
        ? prevSelectedValues.filter((val) => val !== event.target.name)
        : [...prevSelectedValues, event.target.name]
    );
  };

  useEffect(() => {
    const selctedtools = JSON.parse(localStorage.getItem("selectedTool"));
    const selectedToolIndex = JSON.parse(
      localStorage.getItem("selectedToolIndex")
    );

    if (selctedtools) {
      setVisible(false);
      setSelected(selctedtools);
      setMergeResult(selctedtools);
    }
    if (selectedToolIndex) {
      localStorage.setItem("selectedToolIndex", selectedToolIndex);
      setValue(+selectedToolIndex);
    }
  }, []);

  return (
    <>
      {isSetting === false && (
        <div className={classes.headingCotainer}>
          <div className="row">
            <div className="col-2">
              {" "}
              <img src={Logo} className={classes.headerLogo} alt="" />
            </div>
            <div
              className="col-6"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              Welcome {user}
            </div>
            <div
              className="col-4 text-end"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setLogout(true)}
            >
              Logout{" "}
              <span style={{ display: "none" }}>
                <Navbar isLogout={isLogout} />
              </span>
            </div>
          </div>
        </div>
      )}
      {visible && (
        <div className={classes.mainCtainer}>
          <div
            className={isSetting ? classes.settingInnerBox : classes.innerBox}
          >
            {!isSetting ? (
              <>
                <div className={classes.innerBoxHeading}>
                  Let’s start by selecting which tools you would like to
                  connect.
                </div>
                <div className={classes.middleMTxtLMs}>
                  Remember to connect atleast one of the issue tracking tools to
                  get the insights.
                </div>
              </>
            ) : (
              <div className={`mt-2 ${classes.innerBoxHeading}`}>
                Add more tools
              </div>
            )}
            <form>
              <div className={classes.boxDiv}>
                <div className={classes.trackBox}>
                  <div className={classes.innerBoxtxt}>
                    Issue tracking system
                  </div>
                  <div className={classes.subHeading}>One tool compulsory</div>

                  <div className={classes.checkSetting}>
                    <FormGroup>
                      <FormControlLabel
                        key="Jira"
                        disabled={configTools?.Jira}
                        control={
                          <Checkbox
                            checked={selected.includes("Jira")}
                            onChange={handleChangeCheckBox}
                            name="Jira"
                          />
                        }
                        label={
                          <>
                            {" "}
                            <img
                              src={jirIcon}
                              className={classes.trackImg}
                              alt=""
                            />{" "}
                            Jira{" "}
                            {configTools?.Jira ? (
                              <span>
                                <i
                                  className="fa fa-check-circle"
                                  style={{ color: "green" }}
                                ></i>
                              </span>
                            ) : (
                              <span>
                                {/* <i
                                    className="fa fa-times-circle"
                                    style={{ color: "red" }}
                                  ></i> */}
                              </span>
                            )}
                          </>
                        }
                      />
                      <FormControlLabel
                        key="Azure"
                        disabled={configTools?.Azure}
                        control={
                          <Checkbox
                            checked={selected.includes("Azure")}
                            onChange={handleChangeCheckBox}
                            name="Azure"
                          />
                        }
                        label={
                          <>
                            {" "}
                            <img
                              src={azureIcon}
                              className={classes.trackImg}
                              alt=""
                            />{" "}
                            Azure{" "}
                            {configTools?.Azure ? (
                              <span>
                                <i
                                  className="fa fa-check-circle"
                                  style={{ color: "green" }}
                                ></i>
                              </span>
                            ) : (
                              <span>
                                {/* <i
                                    className="fa fa-times-circle"
                                    style={{ color: "red" }}
                                  ></i> */}
                              </span>
                            )}
                          </>
                        }
                      />
                    </FormGroup>
                    {/* <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="gender"
                        value={tSystem}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel
                          value="Jira"
                          disabled={confJira}
                          control={<Radio />}
                          label={
                            <>
                              <img
                                src={jirIcon}
                                className={classes.trackImg}
                                alt=""
                              />{" "}
                              Jira{" "}
                              {confJira ? (
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "green" }}
                                  ></i>
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </>
                          }
                        />
                        <FormControlLabel
                          value="Azure"
                          isDisabled={confAzure}
                          control={<Radio />}
                          label={
                            <>
                              {" "}
                              <img
                                src={azureIcon}
                                className={classes.trackImg}
                                alt=""
                              />{" "}
                              Azure{" "}
                              {confAzure ? (
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "green" }}
                                  ></i>
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </>
                          }
                        />
                      </RadioGroup>
                    </FormControl> */}
                  </div>
                </div>
                <div className={classes.trackBox}>
                  <div className={classes.innerBoxtxt}>
                    Source control system
                  </div>
                  <div className={classes.subHeading}>
                    No compulsion, But Max one
                  </div>

                  <div className={classes.checkSetting}>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          key="Bitbucket"
                          disabled={configTools?.Bitbucket}
                          control={
                            <Checkbox
                              checked={selected.includes("Bitbucket")}
                              onChange={handleChangeCheckBox}
                              name="Bitbucket"
                            />
                          }
                          label={
                            <>
                              {" "}
                              <img
                                src={bitBucketIcon}
                                className={classes.trackImg}
                                alt=""
                              />{" "}
                              Bitbucket{" "}
                              {configTools?.Bitbucket ? (
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "green" }}
                                  ></i>
                                </span>
                              ) : (
                                <span>
                                  {/* <i
                                    className="fa fa-times-circle"
                                    style={{ color: "red" }}
                                  ></i> */}
                                </span>
                              )}
                            </>
                          }
                        />
                        <FormControlLabel
                          key="Github"
                          disabled={configTools?.Github}
                          control={
                            <Checkbox
                              checked={selected.includes("Github")}
                              onChange={handleChangeCheckBox}
                              name="Github"
                            />
                          }
                          label={
                            <>
                              {" "}
                              <img
                                src={githubIcon}
                                className={classes.trackImg}
                                alt=""
                              />{" "}
                              Github{" "}
                              {configTools?.Github ? (
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "green" }}
                                  ></i>
                                </span>
                              ) : (
                                <span>
                                  {/* <i
                                    className="fa fa-times-circle"
                                    style={{ color: "red" }}
                                  ></i> */}
                                </span>
                              )}
                            </>
                          }
                        />
                        <FormControlLabel
                          key="Gitlab"
                          disabled={configTools?.Gitlab}
                          control={
                            <Checkbox
                              checked={selected.includes("Gitlab")}
                              onChange={handleChangeCheckBox}
                              name="Gitlab"
                            />
                          }
                          label={
                            <>
                              {" "}
                              <img
                                src={gitLab}
                                className={classes.trackImg}
                                alt=""
                              />{" "}
                              Gitlab{" "}
                              {configTools?.Gitlab ? (
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "green" }}
                                  ></i>
                                </span>
                              ) : (
                                <span>
                                  {/* <i
                                    className="fa fa-times-circle"
                                    style={{ color: "red" }}
                                  ></i> */}
                                </span>
                              )}
                            </>
                          }
                        />
                        <FormControlLabel
                          key="Sonarqube"
                          disabled={configTools?.Sonarqube}
                          control={
                            <Checkbox
                              checked={selected.includes("Sonarqube")}
                              onChange={handleChangeCheckBox}
                              name="Sonarqube"
                            />
                          }
                          label={
                            <>
                              <img
                                src={sonarCube}
                                className={classes.trackImg}
                                alt=""
                              />{" "}
                              Sonarqube{" "}
                              {configTools?.Sonarqube ? (
                                <span>
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "green" }}
                                  ></i>
                                </span>
                              ) : (
                                <span>
                                  {/* <i
                                    className="fa fa-times-circle"
                                    style={{ color: "red" }}
                                  ></i> */}
                                </span>
                              )}
                            </>
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <div className={classes.trackBox}>
                  <div className={classes.innerBoxtxt}>CI/CD Tools</div>
                  <div className={classes.subHeading}>
                    No compulsion, But Max one
                  </div>

                  <div className={classes.checkSetting}>
                    <FormControlLabel
                      key="Jenkins"
                      control={
                        <Checkbox
                          // disabled={true}
                          checked={selected.includes("Jenkins")}
                          onChange={handleChangeCheckBox}
                          name="Jenkins"
                        />
                      }
                      label={
                        <>
                          <img
                            src={jenkins}
                            className={classes.trackImg}
                            alt=""
                          />{" "}
                          Jenkins{" "}
                          {configTools?.Jenkins ? (
                            <span>Configured</span>
                          ) : (
                            ""
                            // <span><i className="fa fa-times-circle" style={{color:"red"}}></i></span>
                          )}
                        </>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={classes.submtBtnDiv}>
                <button
                  // type="submit"
                  // value="Connect"
                  className={
                    selected?.length < 1 ? classes.disableBtn : classes.submtBtn
                  }
                  onClick={getValues}
                  disabled={selected?.length === 0 ? true : false}
                >
                  Connect
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {visible === false && (
        <>
          <div className={classes.mainCtainer}>
            {/* <div className="col-12 p-3" style={{ cursor: "pointer" }}>
              <i
                className="fa fa-arrow-circle-left mx-2"
                style={{ fontSize: "20px" }}
                onClick={backButton}
              >
                {" "}
              </i>
              Back
            </div> */}
            <div className={classes.innerBox}>
              <div className={classes.innerMainHeading}>
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    position: "relative",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {mergeResult.map((res, index) => (
                          <Tab
                            // disabled
                            icon={
                              <>
                                <Avatar
                                  style={{ width: 20, height: 20 }}
                                  className="mb-0"
                                  alt="test avatar"
                                  src={imageConst[res]}
                                />
                                {index < value && (
                                  <>
                                    {configTools[res] ? (
                                      <i
                                        className="fa fa-check-circle"
                                        style={{
                                          color: "green",
                                          position: "absolute",
                                          right: "10px",
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        class="fas fa-info-circle"
                                        style={{
                                          color: "#ffa200",
                                          position: "absolute",
                                          right: "10px",
                                        }}
                                      ></i>
                                    )}
                                  </>
                                )}
                              </>
                            }
                            label={capitalizeFirst(res)}
                            value={index}
                            style={{
                              filter: `grayscale(${index <= value ? 0 : 1})`,
                            }}
                            className={`${classes.jiraTxt}`}
                          />
                        ))}
                        <button
                          onClick={backButton}
                          className={classes.addMoreIntegration}
                        >
                          +Add More Integration
                        </button>
                      </TabList>
                    </Box>
                    {mergeResult.map((res, index) => (
                      <TabPanel value={index}>
                        {res === "Jira" && (
                          <JiraScreen
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                          />
                        )}
                        {res === "Bitbucket" && (
                          <BitBucket
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                          />
                        )}
                        {res === "Github" && (
                          <GitHub
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                          />
                        )}
                        {res === "Gitlab" && (
                          <GitLab
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                          />
                        )}
                        {res === "Azure" && (
                          <AzureModal
                            data={tools || ""}
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                          />
                        )}
                        {res === "Sonarqube" && (
                          <SonarqubeModal
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                            getTools={getTools}
                          />
                        )}
                        {res === "Jenkins" && (
                          <JenKinsModal
                            GrantAccess={GrantAccess}
                            skipHandler={skipHandler}
                            res={res}
                            classes={classes}
                            index={index}
                            configTools={configTools}
                            getTools={getTools}
                          />
                        )}
                      </TabPanel>
                    ))}
                    <p className="text-danger">{toolError}</p>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
          {/* {flag && <AzureModal data={data8 || ""} />}
          {sonarqubeFlag && (
            <>
              {selctedTabsName === "Sonarqube" ? (
                <SonarqubeModal />
              ) : (
                <JenKinsModal />
              )}
            </>
          )} */}
        </>
      )}
    </>
  );
};

export default SetupForm;
