import { makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Popover,
  TextField,
} from "@mui/material";
import React from "react";
import CommonTable from "../../components/commonTable";
import dollerImg from "../../assets/doller.png";

const useStyles = makeStyles((theme) => ({
  optionButton: {
    color: "#5EBDCC",
    borderColor: "#5EBDCC",
    "&.Mui-selected": {
      backgroundColor: "#5EBDCC",
      color: "#fff",
    },
  },
}));
const WorkIssueLevel = ({
  setIssueType,
  issueType,
  columnList,
  columnData,
  IssuesList,
  showCost,
  showCostPopup,
  handleClose,
  setConstAmt,
  constAmt,
  ApplyIssueHandler,
}) => {
  const classes = useStyles();

  const typeHandler = (type) => {
    setIssueType(type);
  };
  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        m={2}
      >
        <div className="d-flex">
          <h5>Issue Level</h5>
          {/* <Box className="d-flex mx-4" onClick={(e) => showCost(e)}>
            <img src={dollerImg} alt="dollerImg" width={"32px"} /> Cost $:{" "}
          </Box>
          <Popover
            open={Boolean(showCostPopup)}
            anchorEl={showCostPopup}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Card>
              <CardContent>
                <div>
                  <label>
                    Please put your fully loaded engineers cost per Month
                  </label>
                  <br />
                  <Box display={"flex"} alignItems={"center"} gridGap={2}>
                    $
                    <TextField
                      value={constAmt}
                      onChange={(e) => {
                        setConstAmt(e.target.value);
                      }}
                      style={{
                        width: "400px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </Box>
                  <br />
                  <Button
                    disabled={constAmt ? false : true}
                    onClick={() => {
                      ApplyIssueHandler();
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    {" "}
                    Apply
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Popover> */}
        </div>
        <div>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            size="small"
          >
            {IssuesList?.map((item, index) => (
              <Button
                variant={issueType === item?.value ? "contained" : "outlined"}
                className={classes.optionButton}
                onClick={() => typeHandler(item?.value)}
                key={index}
              >
                {item?.value}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </Box>
      <CommonTable columns={columnList} data={columnData} />
    </div>
  );
};

export default WorkIssueLevel;
