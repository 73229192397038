import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ExpandJiraDynamicTable } from "../../../../components/jira/tables/expandJiraDynamicTable";
import PaginationTable from "../../../../components/jira/PaginationTable";
import { GlobalData } from "../../../../context/globalData";
import axios from "../../../../utils/axiosHelper";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import SelectField from "../../../../components/select/selectField";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import RangeSelector from "../../../../components/rangeSelector";
import TablePage from "../../../../components/jira/tables/IssueLevelExpandTable";

const IssueLevelTable = ({ issueType, projectId, epic }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [metricTableData, setMetricTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [metricColumnName, setMetricColumnName] = useState([]);

  const [openRange, setOpenRange] = useState(false);
  const customRangePickerHandler = (val) => {
    setDate(val);
  };

  // Fist time pass issue api call
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/issuelevel/firstTimePassIssue/${projectId}?${dateUrl}`;
    if (epic) {
      url += `&${epic.substring(1)}`;
    }
    axios(url)
      .then((res) => {
        let avgLeadTime = res?.data?.data?.map((item) => {
          var date1 = new Date(`${item?.CreatedDate}`);
          var date2 = new Date(`${item?.ResolutionDate}`);
          var diff = (date2 - date1) / 1000;
          diff = Math.abs(Math.floor(diff));
          var days = Math.floor(diff / (24 * 60 * 60));
          return {
            IssueType: item?.IssueType?.name || "No Issue Type",
            CreatedDate: item?.CreatedDate || "No Date",
            ResolutionDate: item?.ResolutionDate || "No Date",
            LeadTime: days || "0",
            Status: item?.Status?.name || "No Status",
            EpicName: item?.Key || "No epic linked",
            EpicKey: item?.Key || "No epic Linked",
            url: item?.url,
            // Expand: item?.ChangeLog,
            Expand: { logOne: item?.ChangeLog, logTwo: item?.ChangeLog2 },
          };
        });
        if (
          !avgLeadTime ||
          !Array.isArray(avgLeadTime) ||
          avgLeadTime?.length === 0
        )
          return;
        let header = [];
        let key = Object.keys(avgLeadTime[0]);
        if (!key) return;
        key?.map((item) => {
          if (item?.includes("_")) {
            let toBeRemove = "_";
            header.push({
              title: item?.replace(toBeRemove, ""),
              keyItem: item,
            });
          } else {
            header.push({
              title: item?.split(/(?=[A-Z])/).join(" "),
              keyItem: item,
              toggle: false,
            });
          }
        });
        setMetricColumnName(header);
        setMetricTableData(avgLeadTime);
        // Update postsPerPage state based on the new metricTableData length to maintain consistency
        setPostsPerPage(5);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [date, issueType]);

  // Calculate pagination values based on metricTableData length and currentPage
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;

  return (
    <Box py={2}>
      <h4 className="p-3">{issueType}</h4>
      <div>
        {/* Date drop down */}
        <SelectField
          input={
            <Button
              onClick={() => setOpenRange(true)}
              style={{
                border: "1px solid #b7b7b7",
                width: "230px",
                textTransform: "capitalize",
                padding: "5px",
                color: "#000",
                fontSize: "14px",
                fontWeight: 400,
                paddingLeft: "14px ",
                paddingRight: "11px",
                justifyContent: "space-between",
                borderRadius: "25px",
                height: "48px",
              }}
              endIcon={<DateRangeOutlinedIcon />}
            >
              {getSelectedDate(date)}
            </Button>
          }
          onClick={() => setOpenRange(true)}
        />
        <RangeSelector
          open={openRange}
          setOpen={setOpenRange}
          pickerHandler={customRangePickerHandler}
          value={date}
        />
      </div>
      {/* <ExpandJiraDynamicTable
        searchJiraIssue={metricTableData.slice(firstPostIndex, lastPostIndex)}
        keysOfObject={metricColumnName}
      /> */}
      <TablePage
        searchJiraIssue={metricTableData.slice(firstPostIndex, lastPostIndex)}
        keysOfObject={metricColumnName}
      />
      <PaginationTable
        totalPosts={metricTableData.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </Box>
  );
};

export default IssueLevelTable;
