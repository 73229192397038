import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    filter: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        background: "rgb(239, 239, 239)"

    },
    scenarionContainer: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        "& > div": {
            flex: 1
        },
        "& .MuiLinearProgress-barColorPrimary": {
            background: "#26a6bb"
        }

    },
    border: {
        borderRight: "1px solid rgb(215 208 208)"
    },
    select: {
        background: 'none',
        border: 'none',
        outline: 'none'
    }
});
