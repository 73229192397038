import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { securedRoutes } from "./RouterPath";
import Sidebar from "../../../components/sidebar/sidebar";
import { makeStyles } from "@material-ui/core";
import { GlobalData } from "../../../context/globalData";
import axios from "../../../utils/axiosHelper";
import { useEffect } from "react";
import ConfigureForm from "../../../pages/auth/onBoardingScreens/ConfigureForm";
import Azure from "../../../screens/authentication/azure/azure";
import BitBucket from "../../../screens/authentication/bitBucket/bitBucket";
import GitHub from "../../../screens/authentication/gitHub/gitHub";
import GitLab from "../../../screens/authentication/gitLab/gitLab";
import Jira from "../../../screens/authentication/jira/jira";
import Sonarqube from "../../../screens/authentication/sonarqube/sonarqube";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  layoutWrapper: {},
  containet: {
    marginLeft: "236px",
    padding: "30px",
  },
}));

const Routes = () => {
  const {
    setProjectList,
    titleId: projectId,
    setTitleID,
    setMetricData,
    isLogin,
    setmetricDetails,
    setEnableAi,
    setFutureDates,
    setAllMetricData,
  } = useContext(GlobalData);

  let isSetupDone = JSON?.parse(localStorage?.getItem("isSetupDone"));
  const style = useStyles();

  const projectListHandler = () => {
    axios
      .get("/api/v2/projects/list")
      .then((result) => {
        setProjectList(result?.data);
        if (result?.data?.length > 0 && !projectId) {
          setTitleID(result?.data[0]?.Id);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    projectListHandler();
  }, []);

  const getMetric = (clientId) => {
    axios
      .get("/api/metrics/7")
      .then((result) => {
        setAllMetricData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!isLogin) return;
    axios
      .get("/api/client")
      .then((result) => {
        setEnableAi(result.data.enableAI);
        localStorage.setItem("ClientId", result.data.ClientId);
        getMetric(result.data.ClientId);
        result.data.enableAI
          ? setFutureDates(
              result?.data?.futureDates ? result?.data?.futureDates : 90
            )
          : setFutureDates(result.data.futureDates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setMetricData, isLogin, setmetricDetails]);

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname === "/") {
      history.push("/project");
    }
  }, []);

  return (
    <>
      <Switch>
        {isSetupDone === false ? (
          <>
            <Route path="/auth/onBoarding" component={ConfigureForm} />
            <Route path="/auth/azure/callback" component={Azure} />
            <Route path="/auth/bitbucket/callback" component={BitBucket} />
            <Route path="/auth/github" component={GitHub} />
            <Route path="/auth/gitlab" component={GitLab} />
            <Route path="/jira" component={Jira} />
            <Route path="/sonarqube" component={Sonarqube} />
            <Redirect to="/auth/onBoarding" />
          </>
        ) : (
          <div className={style.layoutWrapper}>
            <Sidebar />
            <div className={style.containet}>
              <Route path="/auth/onBoarding" component={ConfigureForm} />
              <Route path="/auth/azure/callback" component={Azure} />
              <Route path="/auth/bitbucket/callback" component={BitBucket} />
              <Route path="/auth/github" component={GitHub} />
              <Route path="/auth/gitlab" component={GitLab} />
              <Route path="/jira" component={Jira} />
              <Route path="/sonarqube" component={Sonarqube} />
              {securedRoutes?.map(({ path, Component }) => (
                <Route path={path} exact={true} key={path}>
                  {Component}
                </Route>
              ))}
              {/* <Redirect to="/project" /> */}
            </div>
          </div>
        )}
      </Switch>
    </>
  );
};

export default Routes;
