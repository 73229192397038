import { dateConvert } from "./apiGen";

const dateRangeMap = {
  "Last 180 Days": 180,
  "Last 90 Days": 90,
  "Last 60 Days": 60,
  "Last 30 Days": 30,
  "Last 20 Days": 20,
  "Last 15 Days": 15,
  "Last 7 Days": 7,
  "Next 7 Days": 7,
  "Next 15 Days": 15,
  "Next 30 Days": 30,
  "Next 60 Days": 60,
  "Next 90 Days": 90,
};

// api for detail
export const PROJEECT_API_URL_GEN = (
  rangePickerValue,
  projectId,
  // metricValue,
  iterationValue
) => {
  const project_id = projectId ? `&project_id=${projectId}` : "";
  if (rangePickerValue.isCustom) {
    return `no_of_days=${rangePickerValue.range + 1}&startDay=${dateConvert(
      rangePickerValue.startDate
    )}&endDay=${dateConvert(
      rangePickerValue.endDate
    )}&iteration=${iterationValue}`;
  } else if (rangePickerValue.range.includes("Next")) {
    const lastDate = new Date();
    lastDate.setDate(
      lastDate.getDate() + (dateRangeMap[rangePickerValue.range] - 1)
    );
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate());
    return `no_of_days=${
      dateRangeMap[rangePickerValue.range]
    }&startDay=${dateConvert(nextDate)}&endDay=${dateConvert(
      lastDate
    )}&iteration=${iterationValue}`;
  } else {
    const lastDate = new Date();
    lastDate.setDate(lastDate.getDate() - 1);
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() - dateRangeMap[rangePickerValue.range]);
    return `no_of_days=${
      dateRangeMap[rangePickerValue.range]
    }&startDay=${dateConvert(nextDate)}&endDay=${dateConvert(
      lastDate
    )}&iteration=${iterationValue}`;
  }
};