import { format } from "date-fns";
import { config } from "../../../../Config/permission";

const OverViewList = (
  teamList,
  res,
  classes,
  setIsTrendOpen,
  setSelectedResourceDetail,
  sprintBarGraphData,
  history
) => {
  const data = { ...teamList };

  data.columnsList = [
    // {
    //   field: "Name",
    //   title: "Name",
    //   width: "150px",
    //   render: (rowData) => (
    //     <div
    //       style={{ cursor: "pointer", color: "#5EBDCC" }}
    //       onClick={() => {
    //         history.push({
    //           pathname: `/project/${rowData?.ProjectId}/single-resource/${rowData?.results[0]?.Id}`,
    //           state: {
    //             projectName: `Sprint`,
    //             resourceName: rowData?.Name,
    //             teamName: rowData?.results[0]?.TeamName,
    //             startDate: format(
    //               new Date(sprintBarGraphData?.startDate),
    //               "yyyy-MM-d"
    //             ),
    //             endDate: format(
    //               new Date(sprintBarGraphData?.endDate),
    //               "yyyy-MM-d"
    //             ),
    //             date: {
    //               startDate: new Date(sprintBarGraphData?.startDate),
    //               endDate: new Date(sprintBarGraphData?.endDate),
    //               key: "selection",
    //               isCustom: true,
    //               range: 14,
    //             },
    //           },
    //         });
    //       }}
    //     >
    //       {rowData.Name}
    //     </div>
    //   ),
    // },
    {
      field: "Name",
      title: "Team Name",
      width: "100px",
      render: (rowData) => <div>{rowData.Name ? rowData.Name : "-"}</div>,
    },
    {
      title: "Utilization Trends,based on five sprint avg.",
      field: "utilizationStrength",
      width: "100px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "utilization" });
          }}
          className={classes.ViewMore}
          style={{ textAlign: "center" }}
        >
          View More
        </button>
      ),
    },
    {
      title: `${config.type} Completed  (Trends)`,
      field: "storyPoint",
      width: "100px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "story" });
          }}
          className={classes.ViewMore}
          style={{ textAlign: "center" }}
        >
          View More
        </button>
      ),
    },
    {
      title: `${config.type} Spillover  (Trends)`,
      field: "spSpillover",
      width: "100px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "Spillover" });
          }}
          className={classes.ViewMore}
          style={{ textAlign: "center" }}
        >
          View More
        </button>
      ),
    },
  ];

  data.data = res.data.filter((e) => e.Name);
  return data;
};
export { OverViewList };
