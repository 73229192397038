import { useEffect, useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import SelectField from "../../components/select/selectField";
import ScrollToTop from "../../components/scroll/scrollToTop";
import { GlobalData } from "../../context/globalData";
import { useContext } from "react";
import Modal from "@material-ui/core/Modal";
import MaterialTable from "material-table";
import RangeSelector from "../../components/rangeSelector";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { iterationDaysMap } from "../../utils/textMap";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { tableIcons } from "../../utils/tableIcon";
import PageNotFound from "../pageNotFound/PageNotFound";
import DefinaionPopup from "../../components/definationPopup/DefinaionPopup";
import { Box } from "@mui/material";
import CompareTable from "../../components/compare/compareTable";
import UtilizationPerformance from "../../components/utilizationVsPerformance";
import { getSelectedDate } from "../../utils/moduleTabApi";

const useStyles = makeStyles((theme) => ({
  fieldGrid: {
    display: "flex",
    marginTop: "25px",
    justifyContent: "space-between",
  },
  charts: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "30px",
    marginTop: "16px",
  },
  tableFieldGrid: {
    position: "absolute",
    top: "-10px",
    zIndex: 1000,
    display: "flex",
    marginTop: "16px",
    width: "260px",
    justifyContent: "space-between",
  },
  table: {
    position: "relative",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 2),
    top: "50%",
    left: "50%",
    transform: "translate(-50% ,-50%)",
    textAlign: "center",
    borderRadius: "20px",
    outline: "none",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  root: {
    marginTop: "5px",
  },
  inputWraper: {
    textAlign: "center",
    marginBottom: "22px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#082C49",
    marginBottom: "15px",
    marginTop: "15px",
  },
  input: {
    width: "400px",
    height: "15px",
    borderRadius: 0,
  },
  btn: {
    backgroundColor: "#5EBDCC",
    fontSize: "14px",
    padding: "0px 32px",
    borderRadius: "30px",
    color: "#fff",
    height: "40px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  btnWraper: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  medium: {
    color: "#ECCB47",
  },
  low: {
    color: "#209728",
  },
  high: {
    color: "#E65C5C",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  mainHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "7px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  projectDropdown: {
    height: "20px !important",
  },
}));

export default function Template(props) {
  const {
    Metric,
    metricValue,
    setMetricValue,
    rangePickerValue,
    setRangePickerValue,
    dropdownProjectList,
    setTitleID,
    projectId,
    moduleDetailData,
    materialTableColums,
    moduleKpiData,
    kpiModalMaterialTableColums,
    isProject,
    tableOption = {},
    selectModuleName,
    isMetricValue,
    kpiName,
    isCompare,
    title,
    isUtilization,
    isDate = true,
    isItration = true,
  } = props;
  const classes = useStyles();
  const [openRange, setOpenRange] = useState(false);

  const [isCompareOpen, setIscompareOpen] = useState(false);
  const [isUtilizationOpen, setIsUtilizationOpen] = useState(false);

  const {
    iterationValueGlobal,
    setIterationValueGlobal,
    moduleKpiFlag,
    setModuleKpiFlag,
    // isDefinaionPopup,
    // setIsDefinaionPopup,
  } = useContext(GlobalData);

  const customRangePickerHandler = (val) => {
    setRangePickerValue(val);
  };

  function ScrollFun() {
    setTimeout(() => {
      document.getElementById("mainRiskAdvisor").style.minHeight = "auto";
    }, 5000);
  }

  return (
    <div id="mainRiskAdvisor">
      <>
        <div className={classes.mainHeader}>
          <div style={{ display: "flex" }}>
            <div className={classes.projectDropdown}>
              {/* project drop down */}
              <ScrollToTop />
              {isProject !== false && (
                <SelectField
                  render={dropdownProjectList?.map((e) => {
                    return { value: e?.Id, lable: e?.Name };
                  })}
                  withLable={true}
                  value={projectId ?? ""}
                  setValue={(item) => {
                    setTitleID(item);
                  }}
                  isValueSelect={true}
                />
              )}
            </div>
            <div>
              {/* Date drop down */}
              {isDate === true && (
                <>
                  <SelectField
                    input={
                      <Button
                        onClick={() => setOpenRange(true)}
                        style={{
                          border: "1px solid #b7b7b7",
                          width: "230px",
                          textTransform: "capitalize",
                          padding: "5px",
                          fontSize: "14px",
                          fontWeight: 400,
                          paddingLeft: "14px ",
                          paddingRight: "11px",
                          justifyContent: "space-between",
                          borderRadius: "25px",
                          height: "48px",
                        }}
                        endIcon={<DateRangeOutlinedIcon />}
                      >
                        {/* {rangePickerValue.isCustom
                      ? "Custom"
                      : rangePickerValue.range || ""} */}
                        {getSelectedDate(rangePickerValue)}
                      </Button>
                    }
                    onClick={() => setOpenRange(true)}
                  />
                  <RangeSelector
                    open={openRange}
                    setOpen={setOpenRange}
                    pickerHandler={customRangePickerHandler}
                    value={rangePickerValue}
                  />
                </>
              )}
            </div>
            {isItration === true && (
              <div>
                {/* Weekly drop down */}
                <SelectField
                  render={iterationDaysMap}
                  value={iterationValueGlobal}
                  setValue={setIterationValueGlobal}
                  RiskAdvisor={true}
                />
              </div>
            )}
          </div>
          <div>
            <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
              {/* Group-(Metric) drop down */}
              {isMetricValue !== false && (
                <SelectField
                  ScrollFun={(val) => ScrollFun(val)}
                  render={Metric}
                  value={metricValue}
                  setValue={setMetricValue}
                />
              )}
              {isUtilization === true && (
                <Button
                  variant="outlined"
                  style={{
                    border: "1px solid #5EBDCC",
                    color: "#5EBDCC",
                    borderRadius: "40px",
                    height: "47px",
                    marginRight: 20,
                    marginLeft: 10,
                    textTransform: "none",
                  }}
                  onClick={() => setIsUtilizationOpen(true)}
                >
                  Utilization vs Performance
                </Button>
              )}
              {isCompare === true && (
                <Button
                  variant="contained"
                  style={{
                    background: "#5EBDCC",
                    color: "#fff",
                    borderRadius: "40px",
                    height: "47px",
                    width: 120,
                  }}
                  onClick={() => setIscompareOpen(true)}
                >
                  Compare
                </Button>
              )}
            </Box>
          </div>
        </div>
        <div className={classes.table}>
          {moduleDetailData && moduleDetailData?.length > 0 ? (
            <>
              <DefinaionPopup matricName={kpiName} />
              <MaterialTable
                style={{ boxShadow: "none" }}
                title=""
                columns={materialTableColums}
                icons={tableIcons}
                data={moduleDetailData?.sort((a, b) => {
                  return b?.risk - a?.risk;
                })}
                options={tableOption}
              />
            </>
          ) : (
            <PageNotFound />
          )}

          {/*  Modal for Kpi   */}
          <Modal
            className="paginationStyling"
            open={moduleKpiFlag}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <>
              <div className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ fontSize: "15px", fontWeight: "600" }}>
                    {selectModuleName}
                  </div>
                  <div>
                    <HighlightOffOutlinedIcon
                      style={{
                        color: "#B1B1B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setModuleKpiFlag(!moduleKpiFlag)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    top: "52px",
                    zIndex: 1,
                  }}
                >
                  <SelectField
                    input={
                      <Button
                        onClick={() => setOpenRange(true)}
                        style={{
                          border: "1px solid #b7b7b7",
                          width: "230px",
                          textTransform: "capitalize",
                          padding: "5px",
                          fontSize: "14px",
                          fontWeight: 400,
                          paddingLeft: "14px ",
                          paddingRight: "11px",
                          justifyContent: "space-between",
                          borderRadius: "25px",
                          height: "48px",
                        }}
                        endIcon={<DateRangeOutlinedIcon />}
                      >
                        {getSelectedDate(rangePickerValue)}
                      </Button>
                    }
                    onClick={() => setOpenRange(true)}
                  />
                  <RangeSelector
                    open={openRange}
                    setOpen={setOpenRange}
                    pickerHandler={customRangePickerHandler}
                    value={rangePickerValue}
                  />
                </div>
                <DefinaionPopup matricName={kpiName} />
                <MaterialTable
                  style={{ boxShadow: "none" }}
                  title=""
                  columns={kpiModalMaterialTableColums}
                  enableRowNumbers
                  rowNumberMode="static"
                  icons={tableIcons}
                  // data={moduleKpiData}
                  data={moduleKpiData?.sort((a, b) => {
                    return b?.RiskLevel - a?.RiskLevel;
                  })}
                  options={tableOption}
                />
              </div>
            </>
          </Modal>

          {/* compare modal start*/}
          {isCompareOpen && (
            <CompareTable
              open={isCompareOpen}
              handleClose={() => setIscompareOpen(false)}
              title={title}
              list={moduleDetailData}
              column={materialTableColums}
            />
          )}
          {/* compare modal end */}

          {isUtilizationOpen && (
            <UtilizationPerformance
              open={isUtilizationOpen}
              handleClose={() => setIsUtilizationOpen(false)}
              classes={classes}
            />
          )}
        </div>
      </>
    </div>
  );
}
