export const SortingONTitle = (header, toggle , index , dataOfTable) => {
    let sortedData;
   if (!toggle) {
    sortedData=  dataOfTable.sort((a, b) =>
       a[header?.keyItem] > b[header?.keyItem]
         ? 1
         : b[header?.keyItem] >
           a[header?.keyItem]
         ? -1
         : 0
     );  
   } else {
      sortedData = dataOfTable.sort((a, b) =>
       a[header?.keyItem] < b[header?.keyItem]
         ? 1
         : b[header?.keyItem] <
           a[header?.keyItem]
         ? -1
         : 0
     );
   }
return {sortedDataOfTable:sortedData,indexOfHeader:index}
 };