import axios from "../../utils/axiosHelper";
import { useEffect, useState,useContext } from "react";
import SelectField from "../../components/select/selectField";
import { format } from "date-fns";
import MaterialGrid from "../../components/overview";
import { useDashaboardColumnHook } from "../../utils/dashaboardColumnHook";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UpgradePlanRequest from "../../utils/UpgradePlanRequest";
import { GlobalData } from "../../context/globalData";

const useStyles = makeStyles((theme) => ({
  wd: {
    // maxHeight: "700px !important",
    // minHeight: "700px !important",
    // border: "1px solid black",
  },
  root: {
    flexGrow: 1,
  },
  mainheader: {
    position: "relative",
  },
  subhead: {
    position: "absolute",
    top: "20px",
    zIndex: "1",
    marginTop: "2px",
  },
  feature: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px"
  },
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn:{
    display: "flex",
    justifyContent: "center",
  },
}));
const Duration = ["Weekly", "BiWeekly", "Monthly"];
let iteration = {
  Weekly: 7,
  BiWeekly: 14,
  Monthly: 30,
};
export const Dashboard = () => {
  const [duration, setDuration] = useState("Weekly");
  const [isLoading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const classes = useStyles();
  const graphLevel = "DashBoard";
  let matrixName = "DashBoard";

  const { isPlanUpgradeRequest, setIsPlanUpgradeRequest } =
  useContext(GlobalData);

  const {
    setColumnVal: setsetColumnVal30,
    column: column30,
    data: data30,
  } = useDashaboardColumnHook(tableColumn, matrixName ,'Next 30 Days');
  const {
    setColumnVal: setsetColumnVal60,
    column: column60,
    data: data60,
  } = useDashaboardColumnHook(tableColumn, matrixName,'Next 60 Days');
  const {
    setColumnVal: setsetColumnVal90,
    column: column90,
    data: data90,
  } = useDashaboardColumnHook(tableColumn, matrixName,'Next 90 Days');
  const {
    setColumnVal: setsetColumnVal180,
    column: column180,
    data: data180,
  } = useDashaboardColumnHook(tableColumn, matrixName,'Last 180 Days');

  const apiCalls = (days) => {
    setLoading(true);
    let startDay=format(
      new Date(),
      "yyyy-MM-d"
    );
    let startDate180= format(
      new Date(new Date(new Date().setDate(new Date().getDate() -1))),
      "yyyy-MM-d"
    );
    let endDay = format(
      new Date(new Date(new Date().setDate(new Date().getDate() + days))),
      "yyyy-MM-d"
    );
    let url;
    if(days===-180){
      url = `/api/project/summary-detail?no_of_days=${Math.abs(days)}&group_id=1&startDay=${endDay }&endDay=${startDate180}&iteration=${iteration[duration]}`;
    }else{
      url = `/api/project/summary-detail?no_of_days=${Math.abs(days+1)}&group_id=1&startDay=${startDay}&endDay=${endDay}&iteration=${iteration[duration]}`;
    }
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        let tableColumn2 = [];
        tableColumn2?.push("ProjectName");
        res?.data?.map((item, index) => {
          if (index === 0)
            item?.MetricGroup?.map((header) => {
              tableColumn2?.push(header?.name);
            });
        });

        setTableColumn(tableColumn2);

        let data = res?.data?.map((item) => {
          let dataOfTable = {};
          for (let i = 0; i < tableColumn2.length; i++) {
            if (i === 0) {
              dataOfTable[tableColumn2[i]] = item?.ProjectName;
            } else {
              item?.MetricGroup?.map((entity, index) => {
                if (tableColumn2[i] === entity.name) {
                  dataOfTable[tableColumn2[i]] = entity.RiskLevel;
                }
              });
              dataOfTable['ProjectId'] = item?.ProjectId
              dataOfTable['risk'] = item?.riskLevel
            }
          }
          return dataOfTable;
        });
        let dataForTable = { data };
        if (days === -180) {
          setsetColumnVal180(dataForTable);
        } else if (days === 29) {
          setsetColumnVal30(dataForTable);
        } else if (days === 59) {
          setsetColumnVal60(dataForTable);
        } else {
          setsetColumnVal90(dataForTable);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    apiCalls(-180);
    apiCalls(29);
    apiCalls(59);
    apiCalls(89);
  }, [duration]);

  let pricePlan= JSON.parse(localStorage.getItem("pricePlan"));
  let featureList =pricePlan?.pricingPlan?.featureList?.dashBoard;

  const planRequestHandler=()=>{
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  }

  return (
    <>
      {featureList === true ? (
        <>
          <div style={{ marginTop: "15px" }}>
            <SelectField
              render={Duration}
              value={duration}
              setValue={setDuration}
            />
          </div>

          <div className={`${classes.root} `}>
            <Grid container spacing={6}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                  <div className={classes.mainheader}>
                    <div className={classes.subhead}>
                      <label style={{ fontSize: "14px" }}>Current Risk</label>
                    </div>
                    <div className={`${classes.wd} tableOverFlowHidden`}>
                      <MaterialGrid
                        columns={column180}
                        data={data180}
                        level={graphLevel}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.mainheader}>
                    <div className={classes.subhead}>
                      <label style={{ fontSize: "14px" }}>30 Days Risk</label>
                    </div>
                    <div className={`${classes.wd} tableOverFlowHidden`}>
                      <MaterialGrid
                        columns={column30}
                        data={data30}
                        level={graphLevel}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                  <div className={classes.mainheader}>
                    <div className={classes.subhead}>
                      <label style={{ fontSize: "14px" }}>60 Days Risk</label>
                    </div>
                    <div className={`${classes.wd} tableOverFlowHidden`}>
                      <MaterialGrid
                        columns={column60}
                        data={data60}
                        level={graphLevel}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.mainheader}>
                    <div className={classes.subhead}>
                      <label style={{ fontSize: "14px" }}>90 Days Risk</label>
                    </div>
                    <div className={`${classes.wd} tableOverFlowHidden`}>
                      <MaterialGrid
                        columns={column90}
                        data={data90}
                        level={graphLevel}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className={`${classes.feature}`}>
            <h4>You dont have access. Please upgrade your plan.</h4>
          </div>
          <div className={`${classes.featureBtn}`}>
            <Button variant="contained" className={classes.btn} type="button" onClick={()=>{planRequestHandler()}}>
              Upgrade
            </Button>
          </div>
          {isPlanUpgradeRequest && <UpgradePlanRequest />}
        </>
      )}
    </>
  );
};

// MetricGroup

