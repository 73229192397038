import { useState, useEffect, memo, useContext } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { GlobalData } from "../../../context/globalData";
import { useHistory } from "react-router-dom";
import { iterationMap } from "../../../utils/textMap";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import GraphDetails from "../../../pages/riskAdvisor/GraphDetails";

// import { Data } from "./data";

am4core.options.queue = true;
am4core.options.autoDispose = true;

const formatPrecision = (precision, formatType) => {
  let format = "#.";
  for (let i = 0; i <= precision; i++) {
    format += "0";
  }
  format = format.slice(0, -1);

  if (formatType === "normal") {
    return `{name}: {valueY.formatNumber('${format}')}`;
  }
  if (formatType === "sprint1") {
    return `{categoryX}: {valueY.formatNumber('${format}')}`;
  }
};

function MetricGraph(props) {
  const {
    body,
    isSprint,
    isStories,
    isTask,
    graphData,
    height,
    level,
    precision,
    groupType,
    valueOfIteration,
    groupId,
    matrixName,
  } = props;

  const routeParams = useParams();
  const { settingData } = useContext(GlobalData);
  const [openPop, setOpenPop] = useState(false);
  const [objectOfSeries, setObjectOfSeries] = useState({});

  useEffect(() => {
    if (level === "SPRINTS") return;

    let chart = null;
    chart = am4core.create(body || "Metricdiv", am4charts.XYChart);
    chart.colors.step = 4;
    chart.responsive.enabled = true;
    chart.paddingRight = 30;
    // chart.colors.list = [
    //   am4core.color("red"),
    //   am4core.color("blue"),
    //   am4core.color("green"),
    //   am4core.color("yellow"),
    //   am4core.color("pink"),
    //   am4core.color("black"),
    //   am4core.color("sky"),
    //   am4core.color("purple"),

    // ];

    // Add data
    chart.data = graphData;

    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;

    // dateAxis.title.text = "Date";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.minGridDistance = 100;
    // valueAxis.renderer.grid.template.disabled = true;
    // valueAxis.title.text = "Percent";

    if (graphData && graphData[0]) {
      let counter = 0;
      let skip = false;
      if (!graphData[0].Name) skip = true;
      Object.keys(graphData[0]).map((val) => {
        if (val.includes("Name")) {
          if (skip) {
            counter++;
            skip = false;
          }
          const name = "Name" + (counter === 0 ? "" : counter);
          const value = "Value" + (counter === 0 ? "" : counter);
          const text = graphData[0][name];
          seriesMaker(chart, value, name, counter === 0, text);
          counter++;
        }
        return val;
      });
    }
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#595858");
    chart.legend.labels.template.text = "[bold]{name}[/]";

    chart.legend.fontSize = 12;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = dateAxis;

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [body, isSprint, isStories, isTask, graphData, level, settingData]);

  const seriesMaker = (chart, val, name, isDashed, text) => {
    const series = chart.series.push(new am4charts.LineSeries());
    // series.stroke =
    //   am4core.color("#2776BD");

    // chart.colors.list = [
    //   am4core.color("#2776BD"),

    // ];

    // series.realStroke=
    series.dataFields.valueY = val;
    series.dataFields.dateX = "Time";
    series.dataFields.name = name;

    if (text) series.name = text;
    series.tooltipText = formatPrecision(precision, "normal");

    // using for the click on exsiting series in metric graph , route

    series.segments.template.interactionsEnabled = true;
    series?.segments?.template?.events?.on("hit", (ev) => {
      let accessFirst =
        ev.target.dataItem.component.tooltipDataItem.dataContext; // use for hit data console
      let accessSecond = ev.target.series.dataFields;

      let objectOfSeries = {
        matrixName: matrixName,
        value: accessFirst[accessSecond.valueY],
        projectId:
          accessFirst[
            `ProjectId${accessSecond.valueY.charAt(
              accessSecond.valueY.length - 1
            )}`
          ],
        date: format(
          new Date(
            ev.target.dataItem.component.tooltipDataItem.dataContext.Time
          ),
          "yyyy-MM-dd"
        ),
        matrixId: groupType,
        Iteration: iterationMap[valueOfIteration],
        groupId: groupId,
        projectIdParams: routeParams?.projectId,
      };
      setObjectOfSeries(objectOfSeries);
      setOpenPop(true);
    });

    // Stroke width  Change
    series.strokeWidth = settingData.stroke;
    if (isDashed) series.strokeDasharray = "3,3";
    series.tensionX = 0.8;
  };

  useEffect(() => {
    if (level !== "SPRINTS") return;

    let chart = null;
    chart = am4core.create(body || "Metricdiv", am4charts.XYChart);

    chart.colors.step = 4;
    chart.responsive.enabled = true;

    chart.data = graphData;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "Name";
    categoryAxis.renderer.grid.template.disabled = true;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "Name";
    series.dataFields.valueY = "Value";
    series.tensionX = 0.8;
    series.strokeWidth = 3;
    series.tooltipText = formatPrecision(precision, "sprint1");
    series.strokeWidth = 3;
    series.hiddenInLegend = true;

    const series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.categoryX = "Name";
    series1.dataFields.valueY = "Value1";
    series1.dataFields.name = "Target";
    series1.strokeDasharray = "3,3";
    series1.strokeWidth = 3;

    series1.tooltipText = formatPrecision(precision, "normal");
    series1.strokeWidth = 3;
    series1.name = "Target";

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = "[bold {color}]{name}[/]";
    // // chart.legend.position = "top"
    chart.legend.fontSize = 10;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.yAxis = valueAxis;
  }, [level, graphData]);

 
  return (
    <>
      <div
        id={body || "Metricdiv"}
        style={{ width: "100%", height: height || "490px" }}
      />
      {openPop && (
        <GraphDetails
          openPop={openPop}
          setOpenPop={setOpenPop}
          graphData={objectOfSeries}
        />
      )}
    </>
  );
}

export default memo(MetricGraph);
