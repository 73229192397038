import { Box, Button, Dialog } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { fix } from "../../../../utils/changeDecimal";
import { Alert, DialogTitle } from "@mui/material";
import MStepper from "../../../../components/mobileSteper";
import CommonPopup from "../../../../components/commonPopup";
import TrendWithCategoryAxis from "../../../../components/trendGraph/trendWithCategory";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import instance from "../../../../utils/axiosHelper";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { datePayload } from "../../../../utils/moduleTabApi";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { config } from "../../../../Config/permission";
import { GlobalData } from "../../../../context/globalData";
import RangeDatePicker from "../../../../components/RangeDatePicker";
import EpicDropDown from "../../../../components/epicDropDown";

function TeamCommunication({ classes, resourceHealth, handleClose, type }) {
  const { iterationValueGlobal, projectRangePickerValue: rangePickerValue } =
    useContext(GlobalData);
  const [value, setValue] = useState(0);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [date, setDate] = useState(rangePickerValue);
  const [showAlert, setShowAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [trendData, setTrendData] = useState([]);
  const [showTrend, setShowTrend] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const { state } = useLocation();
  const { projectId } = useParams();
  // const alertsData = [
  //   {
  //     title: "Recommendation:",
  //     actions: [
  //       "We suggest you follow industry standards in order to make your Quality healthy. For Code Churn, try to keep it below 5%, PR merged with Review > 90%, First time pass-through rate > 90%, PR size < 98 code changes, Legacy refactor < 15%, and PR Comments (2 to 4) comments per pull request.",
  //     ],
  //   },
  //   {
  //     title: "Action: For Unusually high code churn",
  //     actions: [
  //       "Ask for a pre-commit code review and design documentation",
  //       "Ask to split the work or assign a mentor",
  //       "Ask a more senior engineer to assess what “good enough” is in the context of the project.",
  //       "If the problem is difficult, or if the domain is unfamiliar, bring in another engineer to pair program.",
  //     ],
  //   },
  //   {
  //     title: "Action: For PR merged without review",
  //     actions: [
  //       "Please insist on merging PR only after review.",
  //       "Disable the ability for the engineer to merge on repeated mistakes.",
  //       "Setup the process or pre-merge hooks to make sure the review was done by another engineer.",
  //     ],
  //   },
  //   {
  //     title: "Action: For Low First-Time pass-through rate",
  //     actions: [
  //       "Make sure that there is strong collaboration and communication between team members throughout the delivery process.",
  //       "Make sure that the well-defined Tickets are clearly understood by developers and QA testers.",
  //       "Try to reduce high work in progress, allowing engineers to focus on a single Ticket at one time.",
  //       "Maintain a well-supported QA process that’s engaged throughout the process and clear on the functionality that needs to be tested.",
  //     ],
  //   },
  //   {
  //     title: "Action: For Long Running PR",
  //     actions: [
  //       "Assign the responsibility or ownership of a long-pending PR to the engineer.",
  //       "Explain why long-running PR results in code churn or extra work in the long term.",
  //     ],
  //   },
  //   {
  //     title: "Action: For High Legacy Refactor",
  //     actions: [
  //       "Look at the Work Log for outsized code commits in sets of files that seem completely unrelated to the feature at hand.",
  //       "Talk to the engineer and open the topic up for discussion with the team.",
  //       "Ask team members to make a case for and against the refactor, and then come to a conclusion about whether it’s best to move forward with the project, drop it, or tackle it with a different approach.",
  //       "Provide standards around what success is — what “done” looks like. That way, everyone’s clear around what the project is and isn’t, and so the expanding refactor doesn’t consume too much of your team’s time and energy.",
  //     ],
  //   },
  // ];

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    const url = `/api/v2/cm/communications/teams/trends/${projectId}?${dateUrl}&teamId=${type?.id}`;
    instance
      .get(url)
      .then((res) => {
        const data = res?.data?.map((e) => {
          const originalDate = parseISO(e?.Date);
          return {
            Status: e?.Status,
            Date: e?.Date && format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  return (
    <>
      <Box
        padding={3}
        display={"grid"}
        gridGap={4}
        gridColumnGap={8}
        gridTemplateColumns="1fr 1fr"
        sx={{ maxHeight: 800, overflow: "auto" }}
      >
        <div
          className={classes.closeIconSingleRes}
          onClick={() => handleClose(null)}
        >
          <div>
            <HighlightOffIcon />
          </div>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Avg Code Churn </label>
          <p>{resourceHealth.avgCodeChurn.toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Pull Request Review Time (Days)
          </label>
          <p>{(resourceHealth?.prReviewTime).toFixed(2)}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Submitted Pull Request
          </label>
          <p>{(resourceHealth?.submittedPrPercent).toFixed()} %</p>
        </div>{" "}
        <div>
          <label className={classes.resourceHealthLabel}>First Time</label>
          <p>{(resourceHealth?.firstTimePassPercent).toFixed()} %</p>
        </div>{" "}
        <div>
          <label className={classes.resourceHealthLabel}>
            Merged Pull Request
          </label>
          <p>{(resourceHealth?.mergedPrPercent).toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Merged Reviewed Pr
          </label>
          <p>{(resourceHealth?.mergedReviwedPrPercent).toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Pull Request Has Comment{" "}
          </label>
          <p>{(resourceHealth?.prHasCommentPercent).toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Receptiveness</label>
          <p>{(resourceHealth?.prAddressedPercent).toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Health Score (%)
          </label>
          <p>{(resourceHealth?.HealthScore * 100).toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Health status</label>
          <p>{resourceHealth?.HealthStatus}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Comment Addressed (%)
          </label>
          <p>{resourceHealth?.commentAddressed}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Pr Depth</label>
          <p>{resourceHealth?.prDepth}</p>
        </div>
        {/* <div>
          <label className={classes.resourceHealthLabel}>Avg Code Churn </label>
          <p>{resourceHealth.avgCodeChurn.toFixed()}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Completed Issues
          </label>
          <p>{resourceHealth?.completedIssues} </p>
        </div>

        <div>
          <label className={classes.resourceHealthLabel}>
            UnReviewed Pull Request{" "}
          </label>
          <p>{resourceHealth?.unReviewedPr} %</p>
        </div>

       X

       <div>
          <label className={classes.resourceHealthLabel}>
            Reviewed Pull Request
          </label>
          <p>{resourceHealth?.mergedReviwedPrPercent} %</p>
        </div>{" "}

        <div>
          <label className={classes.resourceHealthLabel}>
            Pull Request Count  
          </label>
          <p>{resourceHealth?.prCount}</p>
        </div>

        <div>
          <label className={classes.resourceHealthLabel}>
            Pull Request Review Time (Days)
          </label>
          <p>{(resourceHealth?.prReviewTime).toFixed(2)}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Receptiveness</label>
          <p>{resourceHealth?.receptiveNess}</p>
        </div>
       

        <div>
          <label className={classes.resourceHealthLabel}>Health Status</label>
          <p>{resourceHealth?.HealthStatus}</p>
        </div> */}
      </Box>

      <Box display={"flex"} gridGap={10} paddingX={3} paddingBottom={2}>
        {resourceHealth?.qaualityStatus !== "GOOD" && (
          <>
            <Button
              variant="outlined"
              style={{ textTransform: "capitalize" }}
              color="primary"
              onClick={() => setShowInsights(!showInsights)}
            >
              {showInsights ? "Hide" : "Show"} Insights
            </Button>
            <Button
              variant="outlined"
              style={{ textTransform: "capitalize" }}
              color="primary"
              onClick={() => setShowAlert(!showAlert)}
            >
              {showAlert ? "Hide" : "Show"} Recommendation
            </Button>
          </>
        )}
        <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => setShowTrend(!showTrend)}
        >
          {showTrend ? "Hide" : "Show"} Trend
        </Button>
      </Box>
      <CommonPopup open={showAlert} setOpen={setShowAlert} width={600}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>{resourceHealth?.alertsData[activeStep].title}</h6>
            <ul style={{ paddingLeft: 20 }}>
              {resourceHealth?.alertsData[activeStep].actions.map(
                (action, i) => (
                  <li key={i}>{action}</li>
                )
              )}
            </ul>
          </Alert>
          <MStepper
            steps={resourceHealth?.alertsData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>
      </CommonPopup>
      <CommonPopup open={showInsights} setOpen={setShowInsights} width={600}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>Insights:</h6>
            <ul style={{ paddingLeft: 20 }}>
              Based on our analysis, you need to improve on
              <b> {resourceHealth?.additionalText} </b>so that you can make your
              Communication healthy. View Our Show Recommendation Tab to Know
              More...
            </ul>
          </Alert>
        </Box>
      </CommonPopup>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box display={"flex"} gap={2}>
          {/* {(value === 0 || value === 1 || value === 3) && (
            <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
          )} */}
          {(value === 0 || value === 3) && (
            <>
              <Box marginLeft={2}>
                <RangeDatePicker
                  setDate={setDate}
                  date={date}
                  isNextDate={false}
                />
              </Box>
            </>
          )}
        </Box>
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trendData} />
        </Box>
      </CommonPopup>
    </>
  );
}

export default TeamCommunication;
