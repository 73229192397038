import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./header";
import NavTabs from "../../components/tabs/tabs";
import Teams from "../Teams/team";
import Resources from "../Resource/ResourceList/resources";
import Modules from "./modules";
// import Sprints from "./sprints";
// import Risk from "./risk&recommend";
import axios from "../../utils/axiosHelper";
import { useColumnHook } from "../../utils/columnhook";
import { useMetricHook } from "../../utils/metricHook";
import { idMap } from "../../utils/idsMap";
import { API_TREND_GEN, API_GRID_GEN } from "../../utils/apiGen";
import { GlobalData } from "../../context/globalData";
import RiskPage from "../risks";
import { useLocation, useParams, useHistory } from "react-router-dom";
// import { useSprintHook } from "../../utils/sprintHook";
// import { SprintMap } from "../../utils/levelMap";
import {
  CheckboxDataGenrate,
  CheckboxDataGenrateForTable,
  CountValue,
  MetricGraphDataSorted,
} from "../../utils/riskAdvisorReuseFn";
import Button from "@material-ui/core/Button";
import UpgradePlanRequest from "../../utils/UpgradePlanRequest";

const iterationMap = {
  Weekly: 7,
  Daily: 1,
  Biweekly: 14,
  Monthly: 30,
  Quarterly: 90,
};
const Risks = ["All Risks", "High Risk", "Medium Risk", "Low Risk"];
// const complete = ["All Sprints", "Completed Sprints", "Ongoing Sprints"];
const Metric = ["Metric Details", "Metric Summary"];

const Tabs = ["TEAMS", "RESOURCES", "MODULES", "RISKS"];

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    marginTop: "25px",
  },
  feature: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px",
  },
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ProjectDisplay(props) {
  const history = useHistory();
  // let {id:paramId,projectName:name,projectId:proId} =useParams();
  let urlCheck = window.location.href;
  let j = urlCheck.indexOf("#");
  let strnew = "";
  for (let i = j + 1; i < urlCheck.length; i++) {
    strnew = strnew + urlCheck[i];
  }
  const { setSelectedTab } = useContext(GlobalData);
  const classes = useStyles();
  const { projectName, projectId, render, dropdownProjectList, setTitleID } =
    props;
  // const [render, setRender] = useState(0);
  const tab = Tabs[render];
  const group_id = idMap[tab];
  const location = useLocation();
  // const { projectName, projectId } = props.match.params;

  // const risk = props.location.hash.substring(1);
  const [metricValue, setMetricValue] = useState("Metric Details");
  const { setColumnVal, column, data } = useColumnHook(
    tab.slice(0, -1),
    tab === "TEAMS",
    metricValue
  );

  const { metricGraphData, setMetricGraph, setGridData, flush } =
    useMetricHook();
  const {
    metricGraphData: metricGraphData1,
    setMetricGraph: setMetricGraph1,
    setGridData: setGridData1,
    flush: flush1,
  } = useMetricHook();
  const {
    metricGraphData: metricGraphData2,
    setMetricGraph: setMetricGraph2,
    setGridData: setGridData2,
    flush: flush2,
  } = useMetricHook();
  const {
    metricGraphData: metricGraphData3,
    setMetricGraph: setMetricGraph3,
    setGridData: setGridData3,
    flush: flush3,
  } = useMetricHook();
  const [graphTrendApi, setGraphTrendApi] = useState();
  const [graphTrendApi1, setGraphTrendApi1] = useState();
  const [graphTrendApi2, setGraphTrendApi2] = useState();
  const [graphTrendApi3, setGraphTrendApi3] = useState();
  const [riskValue, setRiskValue] = useState("All Risks");
  const [teamValue, setTeamValue] = useState("All Teams");
  // const [completeValue, setCompleteValue] = useState("All Sprints");

  const {
    teamsval,
    setTeamVal,
    setIsSummary,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    iterationValueGlobal,
    setGraphLoading,
    setProjectNameData,
    setGraphLoading2,
    setProjectLevelData,
    setProjectLevelData2,
    isPlanUpgradeRequest,
    setIsPlanUpgradeRequest,
  } = useContext(GlobalData);

  const [isLoading, setLoading] = useState(false);
  const [gridResponse, setGridResponse] = useState(null);
  // const { setSprintData, setFilter, sprintTrend, setSprintFilter } =
  //   useSprintHook();
  // const {
  //   setSprintData: setSprintData2,
  //   setFilter: setFilter2,
  //   sprintTrend: sprintTrend2,
  //   setSprintFilter: setSprintFilter2,
  // } = useSprintHook();
  const [firstTimeGraphLoad, setFirstTimeGraphLoad] = useState(false);
  // allprojectname,allmetricgraphdata,selectedprojectnamedata names for metric graph
  const [allProjectsName, setAllProjectNames] = useState([]);
  const [allMetricGraphDataFirst, setAllMetricGraphDataFirst] = useState({});
  const [allMetricGraphDataTwo, setAllMetricGraphDataTwo] = useState({});
  const [allData, setAllData] = useState([]);
  const [countOfChecked, setCountOfChecked] = useState(0);

  const [namesOFTable, setNamesOFTable] = useState([]);

  let pricePlan = JSON.parse(localStorage.getItem("pricePlan"));
  let teamLevel = pricePlan?.pricingPlan?.featureList?.teamLevel;
  let resouceLevel = pricePlan?.pricingPlan?.featureList?.resouceLevel;
  let moduleLevel = pricePlan?.pricingPlan?.featureList?.moduleLevel;

  useState({});
  useEffect(() => {
    setSelectedTab(tab);
    let unmounted = false;
    setLoading(true);
    if (tab === "RISKS") return;
    const source = axios.CancelToken.source();
    setGridResponse(null);
    setColumnVal([]);
    setIsSummary(metricValue === "Metric Summary");
    setFirstTimeGraphLoad(false);
    axios
      .get(
        API_GRID_GEN(
          rangePickerValue,
          riskValue,
          group_id,
          projectId,
          null,
          metricValue,
          iterationMap[iterationValueGlobal]
        ),
        {
          cancelToken: source.token,
        }
      )
      .then((result) => {
        // let genratedDataOfCheckBox = [];
        // genratedDataOfCheckBox = CheckboxDataGenrateForTable(result);
        // setAllProjectNames(genratedDataOfCheckBox);
        // setAllData(genratedDataOfCheckBox);

        const nameList = result.data.map((val) => val.ProjectName);
        setProjectNameData(nameList);
        setGridResponse(result.data);
        if (tab !== "RESOURCES") {
          setGridData(nameList);
          setGridData2(nameList);
          setColumnVal(result.data);
        }
        setLoading(false);
        if (result.data.some((val) => val.TeamName)) {
          const obj = {};
          let arr = [];
          arr = result.data.map((val) => {
            obj[val.TeamName] = val.TeamId;
            return val.TeamName;
          });
          arr.unshift("All Teams");
          setTeamVal([...new Set(arr)]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (unmounted) setLoading(true);
      });
    return () => {
      unmounted = true;
      source.cancel();
    };
  }, [
    setColumnVal,
    rangePickerValue,
    setGridData,
    setGridData2,
    setGridResponse,
    riskValue,
    tab,
    metricValue,
    iterationValueGlobal,
    setSelectedTab,
    setIsSummary,
    group_id,
    projectId,
    setProjectNameData,
    setTeamVal,
  ]);

  useEffect(() => {
    if (!graphTrendApi) return;
    if (tab === "RISKS" || tab === "SPRINTS") return;
    if (!gridResponse) return;
    const source = axios.CancelToken.source();
    const API = API_TREND_GEN(
      graphTrendApi,
      rangePickerValue,
      group_id,
      projectId,
      null,
      iterationMap[iterationValueGlobal]
    );
    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        let genratedDataOFCheckBox =
          // allProjectsName.length > 0
          //   ? allProjectsName
          // :
          CheckboxDataGenrate(res);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);

        // let lengthOfResponse = res.data.trends[0];
        // if (!lengthOfResponse && lengthOfResponse > 0) {
        //   setProjectLevelData(res.data.trends[0]);
        // } else {
        //   console.log("check response.data.trends[0]");
        // }

        let dataOfTenSelectedProjectName = createDataForMetricGraphFirst(
          genratedDataOFCheckBox,
          res.data
        );

        setMetricGraph(dataOfTenSelectedProjectName);
        setAllMetricGraphDataFirst(res.data);
        setFirstTimeGraphLoad(true);
        setGraphLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();
    };
  }, [
    graphTrendApi,
    setMetricGraph,
    rangePickerValue,
    tab,
    gridResponse,
    iterationValueGlobal,
    group_id,
    projectId,
    setGraphLoading,
  ]);

  useEffect(() => {
    if (!graphTrendApi) return;
    if (tab === "RISKS" || tab === "SPRINTS") return;
    if (!gridResponse) return;
    const source = axios.CancelToken.source();
    const API = API_TREND_GEN(
      graphTrendApi,
      rangePickerValue,
      group_id,
      projectId,
      null,
      iterationMap[iterationValueGlobal]
    );
    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        let genratedDataOFCheckBox =
          // allProjectsName.length > 0
          //   ? allProjectsName
          // :
          CheckboxDataGenrate(res);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);

        // let lengthOfResponse = res.data.trends[0];
        // if (!lengthOfResponse && lengthOfResponse > 0) {
        //   setProjectLevelData(res.data.trends[0]);
        // } else {
        //   console.log("check response.data.trends[0]");
        // }

        let dataOfTenSelectedProjectName = createDataForMetricGraphFirst(
          genratedDataOFCheckBox,
          res.data
        );

        setMetricGraph1(dataOfTenSelectedProjectName);
        setAllMetricGraphDataFirst(res.data);
        setFirstTimeGraphLoad(true);
        setGraphLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();
    };
  }, [
    graphTrendApi,
    setMetricGraph1,
    rangePickerValue,
    tab,
    gridResponse,
    iterationValueGlobal,
    group_id,
    projectId,
    setGraphLoading,
  ]);
  useEffect(() => {
    if (!graphTrendApi) return;
    if (tab === "RISKS" || tab === "SPRINTS") return;
    if (!gridResponse) return;
    const source = axios.CancelToken.source();
    const API = API_TREND_GEN(
      graphTrendApi,
      rangePickerValue,
      group_id,
      projectId,
      null,
      iterationMap[iterationValueGlobal]
    );
    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        let genratedDataOFCheckBox =
          // allProjectsName.length > 0
          //   ? allProjectsName
          // :
          CheckboxDataGenrate(res);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);

        // let lengthOfResponse = res.data.trends[0];
        // if (!lengthOfResponse && lengthOfResponse > 0) {
        //   setProjectLevelData(res.data.trends[0]);
        // } else {
        //   console.log("check response.data.trends[0]");
        // }

        let dataOfTenSelectedProjectName = createDataForMetricGraphFirst(
          genratedDataOFCheckBox,
          res.data
        );

        setMetricGraph2(dataOfTenSelectedProjectName);
        setAllMetricGraphDataFirst(res.data);
        setFirstTimeGraphLoad(true);
        setGraphLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();
    };
  }, [
    graphTrendApi,
    setMetricGraph2,
    rangePickerValue,
    tab,
    gridResponse,
    iterationValueGlobal,
    group_id,
    projectId,
    setGraphLoading,
  ]);
  useEffect(() => {
    if (!graphTrendApi) return;
    if (tab === "RISKS" || tab === "SPRINTS") return;
    if (!gridResponse) return;
    const source = axios.CancelToken.source();
    const API = API_TREND_GEN(
      graphTrendApi,
      rangePickerValue,
      group_id,
      projectId,
      null,
      iterationMap[iterationValueGlobal]
    );
    axios
      .get(API, {
        cancelToken: source.token,
      })
      .then((res) => {
        let genratedDataOFCheckBox =
          // allProjectsName.length > 0
          //   ? allProjectsName
          // :
          CheckboxDataGenrate(res);
        setAllProjectNames(genratedDataOFCheckBox);
        setAllData(genratedDataOFCheckBox);

        // let lengthOfResponse = res.data.trends[0];
        // if (!lengthOfResponse && lengthOfResponse > 0) {
        //   setProjectLevelData(res.data.trends[0]);
        // } else {
        //   console.log("check response.data.trends[0]");
        // }

        let dataOfTenSelectedProjectName = createDataForMetricGraphFirst(
          genratedDataOFCheckBox,
          res.data
        );

        setMetricGraph3(dataOfTenSelectedProjectName);
        setAllMetricGraphDataFirst(res.data);
        setFirstTimeGraphLoad(true);
        setGraphLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();
    };
  }, [
    graphTrendApi,
    setMetricGraph3,
    rangePickerValue,
    tab,
    gridResponse,
    iterationValueGlobal,
    group_id,
    projectId,
    setGraphLoading,
  ]);

  // useEffect(() => {
  //   if (!graphTrendApi2) return;
  //   if (tab === "RISKS" || tab === "SPRINTS") return;
  //   if (!gridResponse) return;
  //   if (!firstTimeGraphLoad) return;
  //   const source = axios.CancelToken.source();

  //   const API = API_TREND_GEN(
  //     graphTrendApi2,
  //     rangePickerValue,
  //     group_id,
  //     projectId,
  //     null,
  //     iterationMap[iterationValueGlobal]
  //   );
  //   axios
  //     .get(API, {
  //       cancelToken: source.token,
  //     })
  //     .then((res) => {
  //       let lengthOfResponse = res.data.trends[0];
  //       if (!lengthOfResponse && lengthOfResponse > 0) {
  //         setProjectLevelData2(res.data.trends[0]);
  //       } else {
  //         console.log("check response.data.trends[0]");
  //       }
  //       // let genratedDataOfCheckBox = [];
  //       // genratedDataOfCheckBox = CheckboxDataGenrate(res);
  //       // // setAllProjectNames(genratedDataOfCheckBox);

  //       // let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
  //       //   genratedDataOfCheckBox,
  //       //   res.data
  //       // );

  //       let dataOfTenSelectedProjectName = createDataForMetricGraphTwo(
  //         allProjectsName,
  //         res.data
  //       );
  //       setMetricGraph2(dataOfTenSelectedProjectName);
  //       setAllMetricGraphDataTwo(res.data);
  //       // setAllData(genratedDataOfCheckBox);
  //       setGraphLoading2(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   return () => {
  //     source.cancel();
  //   };
  // }, [
  //   graphTrendApi2,
  //   setMetricGraph2,
  //   rangePickerValue,
  //   tab,
  //   gridResponse,
  //   firstTimeGraphLoad,
  //   // iterationValueGlobal,
  // ]);

  // Resources case

  useEffect(() => {
    let trend;
    let grid;

    if (tab !== "RESOURCES") return;
    if (!gridResponse) return;
    if (teamValue === "All Teams") {
      trend = gridResponse.map((val) => val.ProjectName);
      grid = gridResponse;
    } else {
      trend = gridResponse
        .filter((val) => val.TeamName === teamValue)
        .map((val) => val.ProjectName);

      grid = gridResponse.filter((val) => val.TeamName === teamValue);
    }

    setColumnVal(grid);
    setGridData(trend);
    setGridData2(trend);
  }, [teamValue, gridResponse, tab, setGridData, setGridData2, setColumnVal]);

  //sprints case

  // useEffect(() => {
  //   if (!graphTrendApi || !gridResponse) return;
  //   if (tab !== "SPRINTS") return;
  //   setSprintData(gridResponse);
  //   setFilter(graphTrendApi);
  //   setSprintFilter(completeValue);
  //   if (SprintMap[completeValue]) {
  //     setColumnVal(
  //       gridResponse.filter((val) => {
  //         return val.State === SprintMap[completeValue];
  //       })
  //     );
  //   } else {
  //     setColumnVal(gridResponse);
  //   }
  // }, [graphTrendApi, gridResponse, completeValue]);

  // useEffect(() => {
  //   if (!graphTrendApi2 || !gridResponse) return;
  //   if (tab !== "SPRINTS") return;
  //   setSprintData2(gridResponse);
  //   setFilter2(graphTrendApi2);
  //   setSprintFilter2(completeValue);
  // }, [graphTrendApi2, gridResponse, completeValue]);

  const planRequestHandler = () => {
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  };

  let renderGraphs = null;
  if (render === 0) {
    renderGraphs = teamLevel ? (
      <Teams
        metricGraphData={metricGraphData}
        metricGraphData1={metricGraphData1}
        metricGraphData2={metricGraphData2}
        metricGraphData3={metricGraphData3}
        column={column}
        data={data}
        graphTrendApi={graphTrendApi}
        setGraphTrendApi={setGraphTrendApi}
        setGraphTrendApi1={setGraphTrendApi1}
        setGraphTrendApi2={setGraphTrendApi2}
        setGraphTrendApi3={setGraphTrendApi3}
        dropdownVal={0}
        dropdownVal2={1}
        Risks={Risks}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        teamLink
        setTeamValue={setTeamValue}
        // setRender={setRender} //
        level={tab}
        isLoading={isLoading}
        frstGraphClear={flush}
        secGraphClear={flush2}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
        setAllProjectNames={setAllProjectNames}
        allMetricGraphDataFirst={allMetricGraphDataFirst}
        setAllMetricGraphDataFirst={setAllMetricGraphDataFirst}
        allProjectsName={allProjectsName}
        allData={allData}
        setAllData={setAllData}
        countOfChecked={countOfChecked}
        setCountOfChecked={setCountOfChecked}
        CountValue={CountValue}
        setMetricGraph={setMetricGraph}
        allMetricGraphDataTwo={allMetricGraphDataTwo}
        setAllMetricGraphDataTwo={setAllMetricGraphDataTwo}
        setMetricGraph2={setMetricGraph2}
        dropdowntest={"team"}
        groupId={2}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  } else if (render === 1) {
    renderGraphs = resouceLevel ? (
      <Resources
        dropdowntest={"resources"}
        metricGraphData={metricGraphData}
        metricGraphData2={metricGraphData2}
        column={column}
        data={data}
        setGraphTrendApi={setGraphTrendApi}
        setGraphTrendApi2={setGraphTrendApi2}
        dropdownVal={0}
        dropdownVal2={1}
        Risks={Risks}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        Teams={teamsval}
        teamValue={teamValue}
        setTeamValue={setTeamValue}
        level={tab}
        isLoading={isLoading}
        frstGraphClear={flush}
        secGraphClear={flush2}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
        allProjectsName={allProjectsName}
        setAllProjectNames={setAllProjectNames}
        allMetricGraphDataFirst={allMetricGraphDataFirst}
        setAllMetricGraphDataFirst={setAllMetricGraphDataFirst}
        allData={allData}
        setAllData={setAllData}
        countOfChecked={countOfChecked}
        setCountOfChecked={setCountOfChecked}
        CountValue={CountValue}
        setMetricGraph={setMetricGraph}
        allMetricGraphDataTwo={allMetricGraphDataTwo}
        setAllMetricGraphDataTwo={setAllMetricGraphDataTwo}
        setMetricGraph2={setMetricGraph2}
        groupId={3}
        dropdownProjectList={dropdownProjectList}
        setTitleID={setTitleID}
        projectId={projectId}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  } else if (render === 2) {
    renderGraphs = moduleLevel ? (
      <Modules
        dropdowntest={"modules"}
        metricGraphData={metricGraphData}
        metricGraphData2={metricGraphData2}
        column={column}
        data={data}
        graphTrendApi={graphTrendApi}
        setGraphTrendApi={setGraphTrendApi}
        setGraphTrendApi2={setGraphTrendApi2}
        dropdownVal={0}
        dropdownVal2={1}
        Risks={""}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        level={tab}
        isLoading={isLoading}
        frstGraphClear={flush}
        secGraphClear={flush2}
        Metric={Metric}
        // metricValue={metricValue}
        // setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
        allProjectsName={allProjectsName}
        allData={allData}
        allMetricGraphDataTwo={allMetricGraphDataTwo}
        setAllMetricGraphDataTwo={setAllMetricGraphDataTwo}
        setAllData={setAllData}
        setMetricGraph2={setMetricGraph2}
        setAllProjectNames={setAllProjectNames}
        setCountOfChecked={setCountOfChecked}
        CountValue={CountValue}
        setMetricGraph={setMetricGraph}
        countOfChecked={countOfChecked}
        allMetricGraphDataFirst={allMetricGraphDataFirst}
        setAllMetricGraphDataFirst={setAllMetricGraphDataFirst}
        groupId={4}
        dropdownProjectList={dropdownProjectList}
        setTitleID={setTitleID}
        projectId={projectId}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  }
  // else if (render === 3) {
  //   renderGraphs = (
  //     <Sprints
  //       metricGraphData={sprintTrend}
  //       metricGraphData2={sprintTrend2}
  //       column={column}
  //       data={data}
  //       setGraphTrendApi={setGraphTrendApi}
  //       setGraphTrendApi2={setGraphTrendApi2}
  //       dropdownVal={0}
  //       dropdownVal2={1}
  //       complete={complete}
  //       completeValue={completeValue}
  //       setCompleteValue={setCompleteValue}
  //       level={tab}
  //       isLoading={isLoading}
  //     />
  //   );
  // }
  else if (render === 3) {
    renderGraphs = <RiskPage projectLevel />;
  }
  // graph releated all functions
  // sorting object using projects name . function build for metric graph
  let createDataForMetricGraphFirst = (projectName, allData) => {
    let mainObject = MetricGraphDataSorted(projectName, allData);
    return mainObject;
  };
  // let createDataForMetricGraphTwo = (projectName, allData) => {
  //   let mainObject = MetricGraphDataSorted(projectName, allData);
  //   return mainObject;
  // };

  // useEffect(() => {
  //   if (paramId) {
  //     setRender(+paramId);
  //   }
  // }, [paramId]);

  // const tabRender=(render)=>{
  //   history.push({
  //     pathname: `/riskadvisor/project/${name.replace(
  //       "/",
  //       ""
  //     )}/${proId}/${render}`,
  //     hash:strnew||"",
  //   });
  // }
  return (
    <div>
      <Header name={projectName} riskWidth="80px" />
      {/* risk={risk} */}
      {/* <div className={classes.tabWrapper}>
        <NavTabs
          value={render}
          //  groupId={00}
          // setValue={setRender}
          setValue={(render)=>tabRender(render)}
          render={Tabs}
          clearGridResponse={setGridResponse}
          resetGrid={setColumnVal}
          clearFrstGraph={flush}
          clearSecGraph={flush2}
          fontsize="22px"
          isroot
        />
      </div> */}
      {renderGraphs}
    </div>
  );
}
