export const getTableData = (openDrawerHandler, data, unit) => {
  const obj = data[0] || {};
  const issues = Object.keys(obj).filter((key) => key !== "category");
  const categories = data.map((item) => item.category);
  const columns = [
    { title: "Work Category", field: "issue" },
    ...categories.map((category) => ({
      title: category,
      field: category,
      render: (row) => (
        <div
          onClick={() => openDrawerHandler(row, category)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {unit === "$" ? (
            <>
              {unit} {row[category]}{" "}
            </>
          ) : (
            <>
              {row[category]} {unit}
            </>
          )}
        </div>
      ),
    })),
  ];
  // Generate dynamic table data
  const tableData = issues.map((issue) => {
    const rowData = { issue };
    categories.forEach((category) => {
      rowData[category] = data.find((item) => item.category === category)[
        issue
      ];
    });
    return rowData;
  });
  return { columns, tableData };
};
