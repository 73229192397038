import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
}));

export default function RangeSelectorGitHub({
  open,
  setOpen,
  pickerHandler,
}) {
  const classes = useStyles();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  // useEffect(() => {
  //     let startDate=  new Date(new Date(new Date().setDate(new Date().getDate() -30)));
  //   let endDate = new Date();

  //   setState([
  //     {
  //       startDate: startDate,
  //       endDate: endDate,
  //       key: "selection",
  //     },
  //   ]);
  // }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const custormPicker = (val) => {
    setState([val.selection]);
  };

  const applyHandler = () => {
    pickerHandler(state[0]);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      style={{ width: 500 }}
      open={open}
      onClose={handleClose}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogContent style={{ overflow: "hidden" }}>
        <Grid container>
          <Grid item xs={12}>
            <center>
              <DateRange
                onChange={(item) => custormPicker(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                maxDate={new Date()}
                // maxDate={addDays(new Date(), enableAi ? futureDates - 1 : 0)}
                ranges={state}
                retainEndDateOnFirstSelection={true}
                direction="vertical"
                editableDateInputs={true}
                scroll={{ enabled: true }}
                fixedHeight={true}
              />
            </center>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={applyHandler} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
