import React, { useContext, useEffect, useMemo, useState } from "react";
import StackedColumnChart from "../../../../components/stackedColumnChart/StackedColumnChart";
import { GlobalData } from "../../../../context/globalData";
import instance from "../../../../utils/axiosHelper";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../../../utils/moduleTabApi";
import Filter from "../../../../components/effortAllocation/filter";
import CommonTable from "../../../../components/commonTable";
import { getTableData } from "./config/columnName";
import DetailPopup from "../../../../components/effortAllocation/detailPopup";
import { config } from "../../../../Config/permission";
import AllTeamTrendBar from "../trend/AllTeamTrend";
import { teamGetTableData } from "./config/TeamColumnName";
import { format } from "date-fns";
import { Card } from "@material-ui/core";

function EffortAllocation({ projectId }) {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [iteration, setIteration] = useState(config.type === "Task" ? 14 : 30);
  const [issueType, setIssueType] = useState("Task");
  const [selectedViewBy, setSelectedViewBy] = useState("Allocation");
  const [tag, setTag] = useState("Bug");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [costAmt, setCostAmt] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowCost, setSelectedRowCost] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [filterTagOption, setFilterTagOption] = useState([]);
  const [allTeamList, setAllTeamList] = useState([{}]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamUrl, setTeamUrl] = useState("");
  const [issueLevelTeamName, setIssueLevelTeamName] = useState("");

  useEffect(() => {
    if (selectedTeam?.length > 0) {
      let url = " ";
      selectedTeam.forEach((e, index) => {
        if (index === 0) {
          url += `&teamId=${e}`;
        } else {
          url += `&teamId=${e}`;
        }
      });
      setTeamUrl(url.trim(), selectedTeam);
    } else {
      setTeamUrl("", selectedTeam);
    }
  }, [selectedTeam]);

  const getData = (row) => {
    const dateUrl = format(new Date(row.category), "yyyy-MM");
    let url = `/api/v2/projects/allocationsdata/trends?startDay=${dateUrl}&cost=${selectedRowCost}&tag=${tag}&issue=${row.issue}`;
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    // if (issueLevelTeamName) {
    //   url += `&teamName=${issueLevelTeamName}`;
    // }
    instance
      .get(url)
      .then((res) => {
        const response = res.data[0];
        const data = [
          {
            value: `${response.FTE} FTE's`,
          },
          {
            value: `${response.AllocationHrs} Hrs`,
          },
          {
            value: `$ ${response.Cost}`,
          },
          {
            value: `${response.IssueCount} Issue`,
          },
          {
            value: `${response.People} People (Unique Resource)`,
          },
        ];

        setPopupData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const openDrawerHandler = (row, category, teamName) => {
    setSelectedRow({ ...row, category, teamName });
    setSelectedRowCost(null);
    setPopupData(null);
    setIsDrawerOpen(true);
    setIssueLevelTeamName(teamName);
  };
  const handleConst = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCostClose = () => {
    setAnchorEl(null);
  };

  const ApplyHandler = () => {
    setSelectedViewBy(`Cost${Math.random()}`);
    handleCostClose();
  };
  const viewType = useMemo(() => {
    const type = selectedViewBy.includes("Cost") ? "Cost" : selectedViewBy;
    return type;
  }, [selectedViewBy]);
  const graphHandler = () => {
    const dateUrl = datePayload(date);
    const leadingZero = addLeadingZeroToDateRange(dateUrl);

    // let url = `/api/v2/projects/allocationsdata?issueType=${issueType}&${leadingZero}&tag=${tag}&type=${viewType}`;
    let url = `/api/v2/projects/allocationsdata/multipleTeam?issueType=${issueType}&${leadingZero}&tag=${tag}&type=${viewType}${teamUrl}`;
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    instance
      .get(url)
      .then((res) => {
        // Graph
        const AllTeamItem = res?.data?.map((e) => {
          return {
            SprintName: e?.category || "N/A",
            Team:
              e?.Teams?.map((team) => {
                return {
                  TeamName: team?.TeamName || "Not Assigned",
                  Development: team?.Development || 0,
                  Testing: team?.Testing || 0,
                  "Quality Check": team["Quality Check"] || 0,
                };
              }) || [],
          };
        });
        setGraphData(AllTeamItem);
        // setGraphData(res?.data);
        const data = teamGetTableData(
          openDrawerHandler,
          res.data,
          yAxesLabels[viewType]
        );
        setColumns(data.columns);
        setTableData(data.tableData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    graphHandler();
  }, [date, tag, iteration, viewType, epicData, teamUrl]);

  const yAxesLabels = {
    FTE: "",
    Cost: "$",
    Allocation: " Hrs",
  };
  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    const url = `/api/v2/issueTypeTag`;
    instance
      .get(url)
      .then((response) => {
        setFilterTagOption(response?.data?.IssueTypeTags);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${projectId}`;
    instance
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return {
            name: item?.TeamName,
            id: item?.Id,
          };
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Filter
        setDate={setDate}
        date={date}
        issueType={issueType}
        setIssueType={setIssueType}
        iteration={iteration}
        setIteration={setIteration}
        selectedViewBy={viewType}
        setSelectedViewBy={setSelectedViewBy}
        anchorEl={anchorEl}
        handleClose={handleCostClose}
        handleClick={handleConst}
        ApplyHandler={ApplyHandler}
        tag={tag}
        setTag={setTag}
        costAmt={costAmt}
        setCostAmt={setCostAmt}
        epicDropDownHandler={epicDropDownHandler}
        filterTagOption={filterTagOption}
        showTeamSelect={allTeamList}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
      />
      <Card className="my-4">
        <AllTeamTrendBar
          iteration={true}
          data={graphData}
          YAxisLabel={`${viewType} ${yAxesLabels[viewType]}`}
          unit={yAxesLabels[viewType]}
        />
      </Card>
      {/* Without Team */}
      {/* <StackedColumnChart
        graphData={graphData}
        yAxesLabel={`${viewType} ${yAxesLabels[viewType]}`}
        unit={yAxesLabels[viewType]}
      /> */}
      <CommonTable
        columns={columns}
        data={tableData}
        options={{ sorting: true }}
      />

      <DetailPopup
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        popupData={popupData}
        selectedRow={selectedRow}
        getData={getData}
        setSelectedRowCost={setSelectedRowCost}
        selectedRowCost={selectedRowCost}
      />
    </>
  );
}

export default EffortAllocation;
