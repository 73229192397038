import React, { useContext, useEffect, useState } from "react";
import SelectField from "../../components/select/selectField";
import { Box, Button, Card } from "@material-ui/core";
import RangeSelector from "../../components/rangeSelector";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { GlobalData } from "../../context/globalData";
import AutoComp from "../../components/jira/autoComp";
import axios from "../../utils/axiosHelper";
import { useStyles } from "./style";
import { MODULE_API_URL_GEN, getSelectedDate } from "../../utils/moduleTabApi";
import { iterationMap } from "../../utils/textMap";
import { createData, generateColumns } from "./data";
import CommonTable from "../../components/commonTable";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DefinaionPopup from "../../components/definationPopup/DefinaionPopup";
import CompareDialog from "../../components/compare/compareChart";
import CompareTable from "../../components/compare/compareTable";

// Sprint status list for dropdown
let statusList = [
  { id: "all", name: "All" },
  { id: "closed", name: "Closed" },
  { id: "active", name: "Active" },
];

function Sprint() {
  const history = useHistory();
  const [openRange, setOpenRange] = useState(false);
  const [boardList, setBoardList] = useState([]);
  const [boardsName, setBoardsName] = useState();
  const [projectList, setProjectList] = useState([]);
  const [sprintList, setSprintList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState({
    boardList: [],
  });
  const [kpiName, setKpiName] = useState();
  const [sprintStatusName, setSprintStatusName] = useState(statusList?.[0]);
  const [selectedSprintList, setSelectedSprintList] = useState([]);
  const [isCompareOpen, setIscompareOpen] = useState(false);

  let clientId = localStorage.getItem("ClientId");
  //context api
  const {
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    iterationValueGlobal,
    setIsDefinaionPopup,
  } = useContext(GlobalData);

  // table props for material-table
  const tableOption = {
    sorting: true,
    search: true,
    toolbar: true,
    searchFieldStyle: {
      padding: "5px 10px 5px 15px",
      borderRadius: "30px",
      disableUnderline: true,
      backgroundColor: "#efefef",
    },
    pageSize: 10,
  };

  useEffect(() => {
    setSprintStatusName(statusList?.[0]);
  }, []);

  //  set range picker value
  const customRangePickerHandler = (val) => {
    setRangePickerValue(val);
  };

  //   handle project change
  const handleProjectChange = (value) => {
    if (value?.boardList?.length > 0) {
      setBoardList(value?.boardList);
      const defaultBoard =
        value?.boardList.find((e) => e.id === 140) || value?.boardList[0];
      setBoardsName(defaultBoard);
    } else {
      setBoardList([]);
      setBoardsName({});
    }
  };
  const classes = useStyles();

  const getProject = (clientId) => {
    //project,board name api
    axios
      .get(`/api/sprint/bargraph/getProjects/${clientId}`)
      .then((response) => {
        if (!response.data) return;
        let data = response.data?.map((e) => {
          return {
            id: e["ProjectId"],
            name: e["ProjectName"],
            boardList: e.Boards?.map((data) => {
              return { id: data["board_id"], name: data["board_name"] };
            }),
          };
        });
        setProjectList(data);
        setSelectedProjectName(data[0]);
        const defaultBoard =
          data[0]?.boardList.find((e) => e.id === 140) || data[0]?.boardList[0];
        setBoardsName(defaultBoard);
        setBoardList(data[0]?.boardList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSprintList = () => {
    if (boardsName && selectedProjectName) {
      let urlPrams = MODULE_API_URL_GEN(
        rangePickerValue,
        selectedProjectName.id,
        iterationMap[iterationValueGlobal]
      );

      const url = `/api/v2/sprints/project/dashboard/?${urlPrams}&board_id=${boardsName.id}`;
      axios
        .get(url)
        .then((res) => {
          const data = createData(res.data);
          setSprintList(data);
          setSelectedSprintList(data);
          setColumnList(
            generateColumns(data, viewMore, definationPopupHandler)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const viewMore = (row) => {
    history.push({
      pathname: `/sprint/${row.Id}`,
      state: {
        ...row,
        board: boardsName,
        project: selectedProjectName,
      },
    });
  };

  const definationPopupHandler = (value) => {
    setKpiName(value);
    setIsDefinaionPopup(true);
  };

  useEffect(() => {
    if (clientId === null) return;
    getProject(clientId);
  }, [clientId]);

  useEffect(() => {
    getSprintList();
  }, [rangePickerValue, selectedProjectName, boardsName]);

  const filterHandler = (value) => {
    if (value.id === "all") {
      setSelectedSprintList(sprintList);
    } else if (value.id === "active") {
      let list = sprintList?.filter((item) => item?.State == "active");
      setSelectedSprintList(list);
    } else if (value.id === "closed") {
      let list = sprintList?.filter((item) => item?.State == "closed");
      setSelectedSprintList(list);
    }
  };

  return (
    <>
      <BreadCrumb list={[]} active={"Sprint"} />
      <Card className="customCard">
        <Box display={"flex"} justifyContent={"space-between"}>
          <div className={classes.filterHeader}>
            <div>
              {" "}
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRange(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "230px",
                      textTransform: "capitalize",
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                      borderRadius: "25px",
                      height: "48px",
                    }}
                    endIcon={<DateRangeOutlinedIcon />}
                  >
                    {getSelectedDate(rangePickerValue)}
                  </Button>
                }
                onClick={() => setOpenRange(true)}
              />
              <RangeSelector
                open={openRange}
                setOpen={setOpenRange}
                pickerHandler={customRangePickerHandler}
                value={rangePickerValue}
              />
            </div>
            <div>
              <AutoComp
                className={classes?.tp}
                data={projectList}
                setValue={setSelectedProjectName}
                value={selectedProjectName}
                onChange={handleProjectChange}
              />
            </div>
            <div>
              <AutoComp
                className={classes?.tp}
                data={boardList || []}
                setValue={setBoardsName}
                value={boardsName}
              />
            </div>
            <div>
              <AutoComp
                className={classes?.tp}
                data={statusList || []}
                setValue={setSprintStatusName}
                value={sprintStatusName}
                onChange={(value) => filterHandler(value)}
              />
            </div>
          </div>
          <Button
            variant="contained"
            style={{
              background: "#5EBDCC",
              color: "#fff",
              borderRadius: "40px",
              height: "47px",
              width: 120,
            }}
            onClick={() => setIscompareOpen(true)}
          >
            Compare
          </Button>
        </Box>
        <DefinaionPopup matricName={kpiName} />
        <CommonTable
          // data={sprintList}
          data={selectedSprintList}
          columns={columnList}
          options={tableOption}
        />
      </Card>

      {isCompareOpen && (
        // <CompareDialog
        //   open={isCompareOpen}
        //   handleClose={() => setIscompareOpen(false)}
        //   title={"Sprint"}
        //   list={selectedSprintList}
        // />
        <CompareTable
          open={isCompareOpen}
          handleClose={() => setIscompareOpen(false)}
          title={"Sprint"}
          list={selectedSprintList}
          column={columnList.filter((e) => e.title !== "Action")}
        />
      )}
    </>
  );
}

export default Sprint;
