const e = process.env;

const config = {
  // common
  type: e.REACT_APP_TYPE || "Story Point",
  name: e.REACT_APP_NAME || "Story Point",

  // PROJECT LEVEL
  project: {
    commitWithoutTicketRef: toBoolean(
      e.REACT_APP_PROJECT_COMMIT_WITHOUT_TICKET_REFERENCE
    ),
    unReviewedPR: toBoolean(e.REACT_APP_PROJECT_UNREVIEWED_PR),
    CommitWithPullRequest: toBoolean(
      e.REACT_APP_PROJECT_COMMIT_WITH_PULL_REQUEST
    ),
    prComments: toBoolean(e.REACT_APP_PROJECT_PR_COMMENTS),
    prReviewTime: toBoolean(e.REACT_APP_PROJECT_PR_REVIEW_TIME),
    receptiveness: toBoolean(e.REACT_APP_PROJECT_RECEPTIVENESS),
    commentsAddressed: toBoolean(e.REACT_APP_PROJECT_COMMENTS_ADDRESSED),
    workBreakdown: toBoolean(e.REACT_APP_PROJECT_WORK_BREAKDOWN),
    projectTabCommunication: toBoolean(
      e.REACT_APP_PROJECT_TAB_ITEM_COMMUNICATION
    ),
    projectTabHealthToProcessCommunicationQuality: toBoolean(
      e.REACT_APP_PROJECT_TAB_HEALTH_ITEM_PROCESS_COMMUNICATION_AND_ITEM_QUALITY
    ),
  },

  // TEAM LEVEL
  team: {
    commitWithoutTicketRef: toBoolean(
      e.REACT_APP_TEAM_COMMIT_WITHOUT_TICKET_REFERENCE
    ),
    codeChurn: toBoolean(e.REACT_APP_TEAM_CODE_CHURN),
    unReviewedPR: toBoolean(e.REACT_APP_TEAM_UNREVIEWED_PR),
    CommitWithPullRequest: toBoolean(e.REACT_APP_TEAM_COMMIT_WITH_PULL_REQUEST),
    prComments: toBoolean(e.REACT_APP_TEAM_PR_COMMENTS),
    prReviewTime: toBoolean(e.REACT_APP_TEAM_PR_REVIEW_TIME),
    firstTimePassIssue: toBoolean(e.REACT_APP_TEAM_FIRST_TIME_PASS_ISSUE),
    receptiveness: toBoolean(e.REACT_APP_TEAM_RECEPTIVENESS),
    commentsAddressed: toBoolean(e.REACT_APP_TEAM_COMMENTS_ADDRESSED),
    teamQualityHealth: toBoolean(e.REACT_APP_TEAM_QUALITY_HEALTH),
    teamProcessHealth: toBoolean(e.REACT_APP_TEAM_PROCESS_HEALTH),
    teamProductivity: toBoolean(e.REACT_APP_TEAM_PRODUCTIVITY),
    teamCommunication: toBoolean(e.REACT_APP_TEAM_COMMUNICATION),
  },

  // RESOURCE LEVEL
  resource: {
    commitWithoutTicketRef: toBoolean(
      e.REACT_APP_RESOURCE_COMMIT_WITHOUT_TICKET_REFERENCE
    ),
    codeChurn: toBoolean(e.REACT_APP_RESOURCE_CODE_CHURN),
    resourceProductivity: toBoolean(e.REACT_APP_RESOURCE_RESOURCE_PRODUCTIVITY),
    unReviewedPR: toBoolean(e.REACT_APP_RESOURCE_UNREVIEWED_PR),
    commitWithPullRequest: toBoolean(
      e.REACT_APP_RESOURCE_COMMIT_WITH_PULL_REQUEST
    ),
    communication: toBoolean(e.REACT_APP_RESOURCE_COMMUNICATION),
    processHealth: toBoolean(e.REACT_APP_RESOURCE_PROCESS_HEALTH),
    qualityHealth: toBoolean(e.REACT_APP_RESOURCE_QUALITY_HEALTH),
    graphWorkBreakDown: toBoolean(e.REACT_APP_RESOURCE_GRAPH_WORK_BREAKDOWN),
    graphCycleTimeBreakDown: toBoolean(
      e.REACT_APP_RESOURCE_GRAPH_CYCLE_TIME_BREAKDOWN
    ),
    singleResourceTableColum: toBoolean(
      e.REACT_APP_RESOURCE_SINGLE_RESOURCE_TABLE_COLUM
    ),
  },

  // SPRINT LEVEL
  sprint: {
    sideBarSprint: toBoolean(e.REACT_APP_SIDEBAR_SPRINT),
    tableColumnOfStoryPoint: toBoolean(
      e.REACT_APP_SPRINT_TABLE_STORY_POINT_COLUMN
    ),
    activeSprintStoryPointCard: toBoolean(
      e.REACT_APP_SPRINT_ACTIVE_SPRINT_STORY_POINT_CARD
    ),
    closeSprintStoryPointCard: toBoolean(
      e.REACT_APP_SPRINT_CLOSE_SPRINT_STORY_POINT_CARD
    ),
    sprintOverViewMetrics: toBoolean(e.REACT_APP_SPRINT_OVERVIEW_METRICS),
  },

  // SETTING LEVEL
  setting: {},

  //...... add more config here if required
};

export { config };

function toBoolean(string) {
  return string === "false" ? false : true;
}
