import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalData } from "../../../../context/globalData";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import axios from "../../../../utils/axiosHelper";
import WIPGraph from "./WIPGraph";
import { Card } from "@mui/material";
import NormalSelect from "../../../../components/select/normalSelect";
import SelectField from "../../../../components/select/selectField";
import { Button } from "rsuite";
import RangeSelector from "../../../../components/rangeSelector";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { config } from "../../../../Config/permission";

const WIPTrend = ({ projectId, teamId, issueType, epic }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [data, setData] = useState([]);
  const [iteration, setIteration] = useState(30);
  const [openRangeTrend, setOpenRangeTrand] = useState(false);
  const myDivRef = useRef(null);

  useEffect(() => {
    const dateUrl = datePayload(date);
    if (issueType === "WIP Average Age") {
      let url = `/api/v2/projects/avgAgingTrend/${projectId}?iteration=${iteration}&${dateUrl}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      wipTrend(url);
    } else if (issueType === "Team WIP Average Age") {
      let url = `/api/v2/projects/avgAgingTrend/${projectId}?iteration=${iteration}&${dateUrl}&teamId=${teamId}`;
      if (epic) {
        url += `&${epic.substring(1)}`;
      }
      wipTrend(url);
    }
  }, [iteration, date, issueType]);

  const scrollToDiv = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const wipTrend = (url) => {
    axios(url)
      .then((response) => {
        const WIPData = response?.data?.map((item) => {
          return {
            category: item.StartDate,
            "Work In Progress": item?.StoryPoints?.toFixed(),
            "Avg Age": item?.Time?.toFixed(),
          };
        });
        setData(WIPData);
        scrollToDiv();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <div ref={myDivRef}>
      <Card className="customCard" style={{ marginTop: "30px", width: "100%" }}>
        <h4 className="p-3">{issueType} Trend</h4>
        <div
          style={{
            width: "300px",
            paddingLeft: 20,
            paddingTop: 20,
            display: "flex",
            gap: 10,
            width: 500,
          }}
        >
          <NormalSelect
            option={[
              { label: "Monthly", value: 30 },
              { label: "Biweekly", value: 14 },
              { label: "Weekly", value: 7 },
              { label: "Daily", value: 1 },
            ]}
            value={iteration}
            handleChange={(value) => setIteration(value)}
          />
          {date && (
            <div>
              {/* Date drop down */}
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRangeTrand(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "230px",
                      textTransform: "capitalize",
                      backgroundColor: "transparent",
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                      borderRadius: "25px",
                      height: "48px",
                    }}
                    endIcon={<DateRangeOutlinedIcon />}
                  >
                    {getSelectedDate(date)}
                  </Button>
                }
                onClick={() => setOpenRangeTrand(true)}
              />
              <RangeSelector
                isNextDate={false}
                open={openRangeTrend}
                setOpen={setOpenRangeTrand}
                pickerHandler={(val) => setDate(val)}
                value={date}
              />
            </div>
          )}
        </div>
        <WIPGraph data={data} />
      </Card>
    </div>
  );
};

export default WIPTrend;
