import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";

function Sonarqube() {
const [loader,setLoader]=useState(true)
  return (<>
   <h1>Sonarqube</h1>
  {loader && <CircularProgress />}
  </>)
}

export default Sonarqube;