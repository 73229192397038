
import React, { useState, useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
// import Checkbox from '@mui/joy/Checkbox';
// import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
// import Select from '@mui/joy/Select';
// import Option from '@mui/joy/Option';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

function createData(Srno,teamName, teamMates,teamCreatedDate,) {
  return {
    Srno,
    teamName,
    teamMates,
    teamCreatedDate,
  };
}
let Range = [];
const rows = [
  createData('1','Design', '','12 June 2023',),
  createData('2','Development', '','12 June 2023',),
  createData('3','Testing','','12 June 2023',),
  createData('4','Product', '','12 June 2023',),
  createData('5','Management', '','12 June 2023',),
  createData('6','UX Design1', '','12 June 2023',),
  createData('7','UX Design2','','12 June 2023',),
  createData('8','Akshay Sagvekar', '','12 June 2023',),
  createData('9','Pranav Telavane', '','12 June 2023',),

];

function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  console.log("stabilizedThis",stabilizedThis);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'sr.no',
    Srno:"1",
    teamName:"Design",
    teamMates:"",
    teamCreatedDate:"12 June 2023",
    label: 'Sr.no',
  },
  {
    id: 'team name',
    Srno:"2",
    teamName:"Development",
    teamMates:"",
    teamCreatedDate:"12 June 2023",
label: 'Team Name',
  },
  {
    id: 'team mates',
    Srno:"3",
    name:"Testing",
    teamMates:"",
    teamCreatedDate:"12 June 2023",
label: 'Teammates',
  },
  {
    id: 'team created date',
    Srno:"4",
    teamName:"Product",
    teamMates:"",
    teamCreatedDate:"12 June 2023",
label: 'Team Created date',
  },
  {
    id: 'add teammate',
    Srno:"5",
    teamName:"Management",
    teamMates:"",
    teamCreatedDate:"12 June 2023",
    label: 'Add teammate',
  },
  {
    id: 'actions',
    Srno:"6",
    teamName:"UX Design1",
    teamMates:"",
    teamCreatedDate:"12 June 2023",
label: 'Actions',
  },
 
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    console.log("event n property",event , property);
    onRequestSort(event, property);
  };

  return (
    <thead>
      <tr>
        {/* <th>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            slotProps={{
              input: {
                'aria-label': 'select all desserts',
              },
            }}
            sx={{ verticalAlign: 'sub' }}
          />
        </th> */}
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          return (
            <th
              key={headCell.id}
              aria-sort={
                active ? { asc: 'ascending', desc: 'descending' }[order] : undefined
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                underline="none"
                color="background: #B1B1B1 0% 0% no-repeat padding-box"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(headCell.id)}
                fontWeight="lg"
                startDecorator={
                  headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                endDecorator={
                  !headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },
                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        py: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: 'background.level1',
        }),
        borderTopLeftRadius: 'var(--unstable_actionRadius)',
        borderTopRightRadius: 'var(--unstable_actionRadius)',
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          level="h6"
          sx={{ flex: '1 1 100%' }}
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )} */}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton size="sm" color="danger" variant="solid">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton size="sm" variant="outlined" color="neutral">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Box>
  );
}

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    float:"right",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    width: "184px",
    height: "40px",
    borderRadius: "22px",
    color: "#FFFFFF",
    Padding: "3px",
    cursor: "pointer",
font:  "16px/20px Lexend Deca",

  },
  headerlabel:{
    margin:"20px 0"
  }
}))
export default function Teams(props) {
    const { frstGraphClear, secGraphClear, isOpenSetting, setOpenSetting } =
    props;
  const [order, setOrder] = React.useState('asc');
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [defaultDate, setDefaultDate] = useState("Last 90 Days");
  const [defaultDateValue, setDefaultDateValue] = useState();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    console.log("event",event);
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleDateRange = (event) => {
        setDefaultDate(event?.name);
        setDefaultDateValue(event?.value);
    
        graphClear();
        // setGlobalDefaultDate(event.target.value);
      };

      const graphClear = () => {
        if (frstGraphClear) frstGraphClear();
        if (secGraphClear) secGraphClear();
      };

  return (
   
    <Sheet
    sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
    >
      <div className={classes.headerlabel}><span >Teams</span>
      <Button className={classes.buttonStyle}>Create New Team</Button>
      </div>
      <EnhancedTableToolbar numSelected={selected.length} />
      <Table
       variant="outlined"
        aria-labelledby="tableTitle"
        sx={{
          '--TableCell-headBackground': 'transparent',
          '& thead th:nth-child(1)': {
            width: '70px',
            height:"25px"
          },
        }}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <tbody>

          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              console.log("row,index",row , index);
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
      <tr>
                  <th id={labelId} scope="row">
                    {row.Srno}
                  </th>
                  <th>
                    {row.teamName}
                  </th>
                  <th>
                    {row.teamMates}
                  </th>
                  <th>{row.teamCreatedDate}</th>
                  <th>
                  <FormControl sx={{ m:2,width: 190,}}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          multiple
          value={personName}
          onChange={handleChange}
        >
          {names.map((name) => (
            console.log("name", name),
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
              </th>
            <th><Grid item xs={4} sx={{ml:3}}>
        <EditIcon />
        <DeleteOutlinedIcon xs={6} sx={{ml:3}} />
      </Grid></th>
                </tr>
          
              );
            })}
          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                '--TableRow-hoverBackground': 'transparent',
              }}
            >
              <td colSpan={7} />
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={7}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 140,
                  justifyContent: 'flex-start',
                }}
              >
                {/* <FormControl orientation="horizontal" size="sm">
                  <FormLabel>Show
                  <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                  </Select>
                  Per page</FormLabel>
                </FormControl> */}
                {/* <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: rows.length === -1 ? -1 : rows.length,
                  })}
                </Typography> */}
                <Box sx={{ float:"right" }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={
                      rows.length !== -1
                        ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}