import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";

import SettingsIcon from "@material-ui/icons/Settings";
// import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import ClearIcon from "@material-ui/icons/Clear";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Logo from "../../assets/itechLogo.png";
import { GlobalData } from "../../context/globalData";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import axios from "../../utils/axiosHelper";
import { useHistory } from "react-router-dom";

let Range = [];

const useStyles = makeStyles((theme) => ({
  text1: {
    backgroundColor: "red",
  },
  grow: {
    flexGrow: 1,
    position: "inherit",
    top: "-10px",
    width: "100%",
    padding: "10px",
    margin: "0px",
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    fontWeight: "bold",
  },
  navigation: {
    display: "flex",
    marginLeft: theme.spacing(15),
    width: 250,
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "grid",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navbar: {
    backgroundColor: "white",
    boxShadow: "none",
    padding: 0,
    margin: 0,
  },
  baseLink: {
    cursor: "pointer",
    color: "#cccccc",
    textDecoration: "none",
  },
  activeLink: {
    color: "#fff",
  },
  button: {
    color: "#092c49",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },

  userProfile: {
    // backgroundColor: "orange",
    // fontSize: "14px",
    fontSize:"24px",
    color: "#092c49",
    fontWeight: "bold",
    // borderRadius: "50px",
    // "&:hover": {
    //   backgroundColor: "orange",
    // },
  },
  buttonCross: {
    color: "#00000",
    fontWeight: "bold",
  },
  videoadjust: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    right: 0,
  },
  settingHeader: {
    justifyContent: "space-between",

    display: "flex",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  text: {
    fontSize: "16px",
    alignSelf: "center",
    color: "#000000DE",
    marginLeft: 15,

    fontStyle: "bold",
  },
  textTite: {
    fontSize: "20px",
    alignSelf: "center",
    color: "#000000DE",
    fontStyle: "bold",
  },
}));

export default function Navbar(props) {
  const { frstGraphClear, secGraphClear } = props;
  const history = useHistory();

  const classes = useStyles();
  // const history = useHistory();
  // const {
  //   location: { pathname },
  // } = history;
  const [localStroke, setLocalStroke] = useState(3);
  const [defaultDate, setDefaultDate] = useState("Last 90 Days");
  const [defaultDateValue, setDefaultDateValue] = useState(-89);
  const {
    setIsLogin,
    isLogin,
    enableAi,
    setProjectRangePickerValue: setRangePickerValue,
    setMetricData,
    setAllMetricData,
    setTeamVal,
    setTrendData,
    setPopupPrecision,
    setSelectedMetricTrend,
    setIsSummary,
    setEnableAi,
    setFutureDates,
    settingData,
    setSettingData,
    setRole,
    iteration,
    iterationValueGlobal,
    setIterationValueGlobal,
    setIteration,
    setGraphLoading,
    setGraphLoading2,
    riskadvisor,
    setRiskAdvisor,
    isSignUp,
    setIsSignUp,
    isVerification,
    setIsVerification,
    setOpen,
    open,
  } = useContext(GlobalData);
  const [iterationValue, setIterationValue] = useState("Weekly");
  const [iterationValueLocal, setIterationValueLocal] =
    useState(iterationValue);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const [open, setOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const futureRage = [
    // { name: "Next 7 Days", value: 7 },
    // { name: "Next 15 Days", value: 15 },
    { name: "Next 30 Days", value: 30 },
    { name: "Next 60 Days", value: 60 },
    { name: "Next 90 Days", value: 90 },
  ];

  const signUpHandler = () => {

    history.push("/signUp");
    setIsSignUp(false);
    setIsVerification(false);
  };

  useEffect(() => {
    if (props.isLogout) {
      localStorage.clear();
      setIsLogin(false);
      setRangePickerValue({
        isCustom: false,
        range: "Last 90 Days",
      });
      setIteration([]);
      setFutureDates(0);
      setEnableAi(false);
      setIsSummary(false);
      setSelectedMetricTrend(null);
      setPopupPrecision(null);
      setTrendData([]);
      setTeamVal(["All Teams"]);
      setAllMetricData([]);
      setMetricData([]);
      // setStrokeGlobal(3);
      setDefaultDateValue(-89);
      setDefaultDate("Last 90 Days");
      setSettingData({ range: -89, rangeData: "Last 90 Days", stroke: 3 });
      setIterationValueGlobal("Weekly");
      setRiskAdvisor(false);
    }
  }, [props.isLogout]);
  
  const logout = () => {
    localStorage.clear();
    setIsLogin(false);
    setRangePickerValue({
      isCustom: false,
      range: "Last 90 Days",
    });
    setIteration([]);
    setFutureDates(0);
    setEnableAi(false);
    setIsSummary(false);
    setSelectedMetricTrend(null);
    setPopupPrecision(null);
    setTrendData([]);
    setTeamVal(["All Teams"]);
    setAllMetricData([]);
    setMetricData([]);
    // setStrokeGlobal(3);
    setDefaultDateValue(-89);
    setDefaultDate("Last 90 Days");
    setSettingData({ range: -89, rangeData: "Last 90 Days", stroke: 3 });
    setIterationValueGlobal("Weekly");
    setRiskAdvisor(false);
  };
  const handleClose = () => {
    setIterationValue(iterationValueLocal);
    setLocalStroke(settingData?.stroke);

    setDefaultDate(settingData.rangeData);
    setOpen(false);
  };
  const handleStrokeChange = (event) => {
    setLocalStroke(event.target.value);
    // setOpen(false);
  };
  const handleDateRange = (event) => {
    setDefaultDate(event?.name);
    setDefaultDateValue(event?.value);

    graphClear();
    // setGlobalDefaultDate(event.target.value);
  };
  const handleIterationChange = (event) => {
    setIterationValue(event);
  };
  const graphClear = () => {
    if (frstGraphClear) frstGraphClear();
    if (secGraphClear) secGraphClear();
  };

  const handleApply = () => {
    setGraphLoading(true);
    setGraphLoading2(true);
    const body = {
      thickness: localStroke,
      range: defaultDate,
      rangeValue: defaultDateValue,
      Iteration: iterationValue,
    };

    axios
      .post("/api/auth/settings", body)
      .then((result) => {
        if (result.status === 200) {
          var obj = {
            isCustom: false,
            range: defaultDate,
          };

          setIterationValueLocal(iterationValue);
          setIterationValueGlobal(iterationValue);
          setRangePickerValue(obj);
          setSettingData({
            stroke: localStroke,
            rangeData: defaultDate,
            range: defaultDateValue,
          });
          setOpen(false);
          graphClear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userName = userDetails?.name;
  const getSettingData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setRole(user?.isAdministrator);
    const source = axios.CancelToken.source();
    axios
      .get("/api/auth/me", {
        cancelToken: source.token,
      })
      .then((result) => {
        let setting = result.data.user?.settings;
        setRole(result.data.user?.isAdministrator);
        if (setting) {
          setLocalStroke(setting?.stroke);
          if (setting?.Iteration) {
            setIterationValueGlobal(setting?.Iteration);
            setIterationValueLocal(setting?.Iteration);
            setIterationValue(setting?.Iteration);
          }

          setting?.rangeValue
            ? setDefaultDateValue(setting.rangeValue)
            : setDefaultDateValue(-89);
          if (setting?.range) {
            setRangePickerValue({
              isCustom: false,
              range: setting?.range,
            });
            setDefaultDate(setting?.range);

            setSettingData({
              stroke: setting?.thickness,
              range: setting?.rangeValue,
              rangeData: setting?.range,
            });
          } else {
            setRangePickerValue({
              isCustom: false,
              range: "Last 90 Days",
            });
            setDefaultDate("Last 90 Days");
            setSettingData({
              stroke: 3,
              range: -89,
              rangeData: "Last 90 Days",
            });
          }
        } else {
          setDefaultDate("Last 90 Days");
          // setIterationDefault();
          setLocalStroke(3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();

      // setting ? "" : source.cancel();
    };
  };
  useEffect(() => {
    Range = [
      // { name: "Last 7 Days", value: -6 },
      // { name: "Last 15 Days", value: -14 },
      { name: "Last 30 Days", value: -29 },
      { name: "Last 60 Days", value: -59 },
      { name: "Last 90 Days", value: -89 },
      { name: "Last 180 Days", value: -179 },
    ];

    setLocalStroke(settingData.stroke);

    // eslint-disable-next-line no-unused-expressions
    isLogin ? (riskadvisor ? null : getSettingData()) : null;
  }, [iterationValueGlobal]);

  useEffect(() => {
    setLocalStroke(settingData.stroke);
  }, [settingData]);
  useEffect(() => {
    setLocalStroke(settingData.stroke);

    if (!enableAi) {
      return Range;
    } else {
      futureRage.map((data) => {
        Range.unshift({ name: data.name, value: data.value });
      });
    }
    return Range;
  }, [enableAi]);
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <NotificationsIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {/* <AppBar position="static" className={classes.navbar}> */}
      {/* <div
        className="row"
        style={{
          background: "#FCFCFC",
          height: "100px",
          padding: "20px",
          flexGrow: 1,
          position: "inherit",
          top: "-10px",
          width: "100%",
          margin: "5px",
          right:0
        }}
      >
   */}

        {/* {isLogin && (
          <div
          className="row"
          style={{
            background: "#FCFCFC",
            height: "100px",
            padding: "42px",
            flexGrow: 1,
            position: "inherit",
            top: "-10px",
            width: "100%",
            margin: "5px",
            right:0,
            left:"290px"
          }}z
        >
    
           
          <span className={classes.userProfile}> Welcome, {userName}</span>
          </div>
        )} */}
        {/* <div className={classes.navigation}>
            <NavLink
              to="/riskadvisor"
              activeClassName={classes.activeLink}
              className={classes.baseLink}
            >
              <Typography
                variant="subtitle1"
                noWrap
                onClick={() => history.push("/")}
              >
                Risk Advisor
              </Typography>
            </NavLink>

            <NavLink
              to="/workforce"
              activeClassName={classes.activeLink}
              className={classes.baseLink}
            >
              <Typography
                variant="subtitle1"
                noWrap
                onClick={() => history.push("/workforce")}
              >
                Workforce Advisor
              </Typography>
            </NavLink>
          </div> */}

        {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}

        {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <NotificationsIcon />
            </IconButton> */}
        {/* <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 d-flex justify-content-start align-items-center">
          <div className="row">
            <div className="row m-0 p-0 mt-2">
              <div className="col-6 d-flex justify-content-start align-items-center">
                {isLogin && (
                  <Button
                    className={classes.button}
                    onClick={() => setOpen(true)}
                    endIcon={<SettingsIcon />}
                   style={{display:"none"}}>
                    Settings
                  </Button>
                )}
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                {isLogin && (
                  <Button
                    className={classes.button}
                    onClick={logout}
                    endIcon={<PowerSettingsNewIcon />}
                    style={{display:"none"}}
                  >
                    Signout
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div> */}
{/* 
        <div className={classes.sectionMobile}>
 
          {!isLogin && isSignUp && (
            <Button
              className={classes.button}
              onClick={signUpHandler}
              endIcon={<ExitToAppSharpIcon />}
            >
              SignUp
            </Button>
          )}

          {isLogin && (
            <Button
              className={classes.button}
              onClick={() => setOpen(true)}
              endIcon={<SettingsIcon />}
              style={{display:"none"}}
            >
              Settings
            </Button>
          )}
          {isLogin && (
            <Button
              className={classes.button}
              onClick={logout}
              endIcon={<PowerSettingsNewIcon />}
              style={{display:"none"}}
            >
              Signout
            </Button>
          )}
        </div> */}
      {/* </div> */}
      {/* <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.settingHeader}>
          <b className={classes.textTite}>Settings</b>
          <Button
            className={classes.buttonCross}
            onClick={handleClose}
            endIcon={<ClearIcon />}
          ></Button>
        </div>
        <hr color="#cacaca" width={"95%"} />
        <div className={classes.videoadjust}>
          <span className={classes.text} style={{ marginRight: 8 }}>
            Default Date Range
          </span>

          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <Select value={defaultDate}>
              {Range.map((val, i) => {
                return (
                  <MenuItem
                    key={i + val}
                    value={val.name}
                    onClick={() => handleDateRange(val)}
                  >
                    {val.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className={classes.videoadjust}>
          <span className={classes.text} style={{ marginRight: 28 }}>
            Default Iteration
          </span>

          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <Select value={iterationValue}>
              {iteration.map((val, i) => {
                return (
                  <MenuItem
                    key={i + val}
                    value={val}
                    onClick={() => handleIterationChange(val)}
                  >
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className={classes.videoadjust}>
          <span className={[classes.text]} style={{ marginRight: 20 }}>
            Graph Line Width
          </span>

          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <Select value={localStroke} onChange={handleStrokeChange}>
              <MenuItem value={2}>Thin</MenuItem>

              <MenuItem value={3}>Medium</MenuItem>
              <MenuItem value={4}>Thick</MenuItem>
            </Select>
          </FormControl>
        </div>

        <DialogActions>
          <Button onClick={handleClose}>
            <span style={{ fontSize: 16 }}>Cancel</span>
          </Button>
          <Button onClick={handleApply}>
            {" "}
            <span style={{ fontSize: 16 }}>Apply</span>
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* </AppBar> */}

      {renderMobileMenu}
      {renderMenu}
    </>
  );
}
