import MaterialTable from "material-table";
import React from "react";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Modal from "@material-ui/core/Modal";
import { tableIcons } from "../../utils/tableIcon";
import { makeStyles } from "@material-ui/core/styles";

function CommonPopup({
    open,
    setOpen,
    title,
    children,
    width = 600
}) {

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: "absolute",
            backgroundColor: theme.palette.background.paper,
            border: "2px solid transparent",
            outline: "none",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1, 2, 2),
            top: "50%",
            left: "50%",
            transform: "translate(-50% ,-50%)",
            textAlign: "center",
            borderRadius: "20px",
            width: width,
        },
    }));

    const classes = useStyles();
    return (
        <Modal
            className="paginationStyling"
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.paper}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "12px",
                        marginTop: "10px",
                    }}
                >
                    <div style={{ fontSize: "15px", fontWeight: "600" }}>{title}</div>
                    <div>
                        <HighlightOffOutlinedIcon
                            style={{
                                color: "#B1B1B1",
                                cursor: "pointer",
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    </div>
                </div>

                {children}
            </div>
        </Modal>
    );
}

export default CommonPopup;
