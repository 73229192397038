import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function TrendBar({ graphData, yAxesLabel, issueType }) {
  useEffect(() => {
    // Check if graphData is available
    if (!graphData || graphData.length === 0) {
      console.error("No data provided for the chart");
      return;
    }

    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.XYChart);

    // Add data
    chart.data = graphData;

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.title.text = "Date"; // Set the title for the category axis
    categoryAxis.renderer.grid.template.location = 0;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = yAxesLabel; // Use the provided label for the y-axis

    // Create series
    const createSeries = (e) => {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = e;
      series.dataFields.valueY = e;
      series.dataFields.categoryX = "category";
      if (
        issueType === "WIP Average Age" ||
        issueType === "Team WIP Average Age" ||
        issueType === "Deployment Frequency" ||
        issueType === "Change failure rate" ||
        issueType === "Deployment rollback" ||
        issueType === "Team Deployment Frequency" ||
        issueType === "Team Change failure rate" ||
        issueType === "Team Deployment rollback"
      ) {
        series.tooltipText = "{name}: [bold]{valueY}[/]";
      } else {
        series.tooltipText = "{name}: [bold]{valueY}[/] %";
      }
      series.stacked = true;
      series.columns.template.adapter.add("fill", function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });
    };
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Clean up when unmounting
    if (graphData.length > 0) {
      Object.keys(graphData[0])?.forEach((e) => {
        if (e !== "category") {
          createSeries(e);
        }
      });
    }
    return () => {
      chart.dispose();
    };
  }, [graphData, yAxesLabel]); // Add dependencies to useEffect

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }} />;
}

export default TrendBar;
