import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import RangeSelector from '../rangeSelector'
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

import { getSelectedDate } from '../../utils/moduleTabApi';

function RangeDatePicker({ date, setDate, style = {}, isNextDate = true }) {
    const [openRange, setOpenRange] = useState(false);

    return (
        <div>
            <Button
                onClick={() => setOpenRange(true)}
                style={{
                    border: "1px solid #b7b7b7",
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    height: "54px", ...style
                }}
                endIcon={<DateRangeOutlinedIcon />}
            >
                {getSelectedDate(date)}
            </Button>

            <RangeSelector
                open={openRange}
                setOpen={setOpenRange}
                pickerHandler={(val) => setDate(val)}
                value={date}
                isNextDate={isNextDate}
            />
        </div>
    )
}

export default RangeDatePicker