import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  rootpop: {
    position: "absolute",
    zIndex: "10000",
    width: "500px",
    height: "150px",
    overflowY: "auto",
    backgroundColor: "white",
    border: "4px solid #d6e4ec",
    borderRadius: "20px",
  },
  popup: {
    position: "absolute",
    zIndex: "10000",
    width: "500px",
    backgroundColor: "white",
    border: "4px solid #d6e4ec",
    borderRadius: "20px",
    padding: "10px",
    display: "grid",
    justifyItems: "center",
    margin: "8px",
  },
  desc: {
    // color: "red",
    fontSize: "15px",
  },
  Heading: {
    fontSize: "14px",
    fontWeight: "bold",
    color:"black"
  },
}));
// const data = [
//   {
//     DisplayName: "Resolved Defects Lead Time",
//     Desc: "The time it takes for the team to find the bugs in the software and to verify and validate the fix.  In short, from tracking the bugs and making sure the bugs are fixed the way they were supposed to, to closing out the issue in a reasonable time, this KPI ensures it all.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Resolved Defects",
//     Desc: "A bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to you to a reasonably tolerable amount.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Defects In Progress",
//     Desc: "Number of bugs which is yet to get fixed.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Defects In Backlog",
//     Desc: "The set of all known and unresolved defects in the project; defect backlog in stored in the defect database.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Commits",
//     Desc: "An operation which sends the latest changes of the source code to the repository, making these changes part of the head revision of the repository.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Pull Requests",
//     Desc: "Let you tell others about changes you've pushed to a branch in a repository on GitHub. Once a pull request is opened, you can discuss and review the potential changes with collaborators and add follow-up commits before your changes are merged into the base branch.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "% Commits without Pull Requests",
//     Desc: "This metric will highlight the percentage of code commits made to your default branch that do not have a related pull request.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Pull Request Resolution Time",
//     Desc: "How long it takes to resolve pull requests.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Resolved Vulnerabilities Per Team Member",
//     Desc: "",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Resolved Vulnerabilities Lead Time",
//     Desc: "The time it takes to find the point of code that is open to attack until the code is  is attackfree.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Resolved Vulnerabilities Cycle Time",
//     Desc: "The time it takes to start fixing the point of code that open to attack  until the code is attackfree.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Resolved Vulnerabilities",
//     Desc: "Fixing  A point in your code that's open to attack.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Story Point Cycle Time",
//     Desc: "The time it takes from when you start work on a user story that's ready for implementation, to when it's ready for delivery (release).",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Defects In Backlog Per Team Member",
//     Desc: "Number of bugs  that are there in the backlog.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Schedule Varient",
//     Desc: "This is the difference between the percentage of work completed versus the percentage expected to be completed by a particular date.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Commits without Pull Requests",
//     Desc: "Commits Without a Pull Request (CWPR) is an important engineering metric for all organisations and particularly those focused on software engineering quality and DevSecOps.",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Stories Completed",
//     Desc: "",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Story Points Completed Per Team Member",
//     Desc: "",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Story Points Completed",
//     Desc: "",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
//   {
//     DisplayName: "Resolved Defects Per Team Member",
//     Desc: "",
//     Why_it_Matters: "",
//     How_to_use_it: "",
//   },
// ];

function Popup(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <>
      {data?.filter((e) => e.DisplayName === props.selectedValue) && (
        <>
          {/* <div style={{ width: "500px", textAlign: "end" }}>
            {" "}
            <i
              className="fa fa-times fa-2x"
              style={{ color: "red", cursor: "pointer" }}
              aria-hidden="true"
              onClick={props.closePopup}
            ></i>
          </div> */}
          {data
            ?.filter((e) => e.DisplayName === props.selectedValue)
            ?.map((e) => (
              // <div className={classes.rootpop}>

              <div className={classes.popup}>
                <i
                  className="fa fa-times-circle fa-2x"
                  style={{
                    color: "red",
                    cursor: "pointer",
                    position: "absolute",
                    right: "0px",
                    margin: "4px",
                  }}
                  aria-hidden="true"
                  onClick={props.closePopup}
                ></i>
                <h6 className={classes.Heading}>
                  <span>{e.DisplayName}</span>
                  <span></span>
                </h6>
                <p className={classes.desc}>{e.Desc}</p>
              </div>
              // </div>
            ))}
        </>
      )}
    </>
  );
}

export default Popup;
