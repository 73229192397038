import {
  forwardRef,
  useEffect,
  useState,
  useRef,
  memo,
  useContext,
} from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useHistory } from "react-router-dom";
import { GlobalData } from "../../context/globalData";
import { ExcelExport } from "../../utils/excelExport";
// import { makeStyles } from "@material-ui/core";
import "./index.css";
import axios from "../../utils/axiosHelper";
import MaterialGrid from "../overview/index";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function ExpandMaterialGrid(props) {
  const {
    columns,
    data,
    isLink,
    teamLink,
    setTeamValue,
    setRender,
    isLoading,
    level,
    subTab,
    tableID,
    riskType,
    frstGraphClear,
    secGraphClear,
    admin,
    deatils,
    title,
  } = props;

  const [tableData, setTableData] = useState([]);
  const [tableColumn, setColumn] = useState([]);
  const [rowDataID, setRowDatID] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const history = useHistory();
  const tableRef = useRef(null);
  const { isSummary, setmetricDetails } = useContext(GlobalData);
  const [columnVal, setColumnVal] = useState([]);
  const [dataVAl, setDataVal] = useState([]);
  useEffect(() => {
    setColumn(columns);

    setTableData(JSON.parse(JSON.stringify(data)));
    tableRef.current.dataManager.changePageSize(50);
    let table = tableRef.current.tableContainerDiv.current;

    table.style.borderRadius = "10px";

    table?.offsetParent &&
      (table.offsetParent.children[0].querySelector(
        "[aria-label='Export']"
      ).style.display = "none");
    return () => {
      setColumn([]);
      setTableData([]);
    };
  }, [columns, data]);

  // const downloadCsv = (data, fileName) => {
  //   const finalFileName = fileName.endsWith(".csv")
  //     ? fileName
  //     : `${fileName}.csv`;
  //   const a = document.createElement("a");
  //   a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  //   a.setAttribute("download", finalFileName);
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const RowClickHandler = (rowData) => {
    setRowDatID(rowData.id);
    let URL = "";
    if (rowData.id === 2) {
      URL = "/api/metrics/list?isActive=true";
    } else if (rowData.id === 1) {
      URL = "/api/metricgroups/getall";
    } else if (rowData.id === 3) {
      URL = "/api/risks/";
    } else if (rowData.id === 4) {
      URL = "/api/consolidatedrisk/";
    }

    axios
      .get(URL)
      .then((result) => {
        setmetricDetails(result.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (rowData.id === 5) {
      return history.push({
        pathname: `/riskadvisor/setting`,
        // hash: rowData.Type,
        state: {
          details: "addDetails",
          title: rowData.Type,
          AllDetails: rowData,
          id: rowData.id,
        },
      });
    }
    history.push({
      pathname: `/riskadvisor`,
      hash: rowData.Type,
      // state: "details",
      state: {
        details: "details",
        title: rowData.Type,
        AllDetails: rowData,
        id: rowData.id,
      },
    });
  };

  const deletePopUp = (id, clientid) => {
    setOpen(true);
    if (title === "Metric Level Risks") {
      setDeleteId(id);
    } else {
      setDeleteId(clientid);
    }
  };
  const deleteHandler = () => {
    let URL = "";
    let listUrl = "";
    if (title === "Metric Level Risks") {
      URL = `/api/risks/delete/${deleteId}`;
      listUrl = "/api/risks/";
    } else {
      URL = `/api/consolidatedrisk/delete/${deleteId}`;
      listUrl = "/api/consolidatedrisk/";
    }

    axios
      .delete(URL)
      .then((response) => {
        setOpen(false);
        axios
          .get(listUrl)
          .then((result) => {
            setmetricDetails(result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setDeleteId("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columnAndData = (data) => {
    let title = ["LinesAdded", "LinesRemoved", "ChangeType", "File"];
    const init = [];
    const matricsData = [];
    title?.map((coloumnName) => {
      init.push({
        field: coloumnName,
        title: coloumnName,
        cellStyle: {
          fontSize: "16px",
        },
        tableData: {
          columnOrder: 0,
          groupSort: "asc",
          width: "calc((100% - (0px + 120px)) / 6)",
          id: 0,
        },
      });
    });

    data.map((tr) => {
      let pathName = "";
      pathName = tr.path.split("/").at(-1);
      matricsData.push({
        LinesAdded: tr.linesAdded,
        LinesRemoved: tr.linesRemoved,
        ChangeType: tr.changeType,
        File: pathName,
      });
    });

    let dataCol = {
      coloumn: init,
      data: matricsData,
    };

    return dataCol;
  };
  return (
    <div
    //   style={{ height: data.length === 0 && "50vh" }}
    //   className={admin ? "setup" : null}
    >
      <MaterialTable
        enableExpanding={true}
        enableExpandAll={false}
        tableRef={tableRef}
        title=""
        icons={tableIcons}
        columns={tableColumn}
        isLoading={isLoading}
        detailPanel={(rowData) => {
          let dataNCol = columnAndData(rowData.expandRow);
          return (
            <div className="expandTable">
              <MaterialTable
                columns={dataNCol.coloumn}
                data={dataNCol.data}
                admin={"admin"}
                options={{
                  paging: false,
                  search: false,
                  showTitle: false,
                  sorting: false,
                }}
              />
            </div>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        style={{ boxShadow: "none" }}
        data={tableData}
        actions={[
          {
            icon: (props, ref) => <SaveAlt {...props} ref={ref} />,
            tooltip: "Export Spreadsheet",
            position: "toolbar",
            isFreeAction: true,
            onClick: () => {
              setTimeout(function () {
                tableRef.current.tableContainerDiv.current.offsetParent.children[0]
                  .querySelector("[aria-label='Export']")
                  .click();
                tableRef.current.tableContainerDiv.current.ownerDocument.activeElement.style.display =
                  "none";
                tableRef.current.tableContainerDiv.current.ownerDocument.activeElement.click();
              }, 100);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          exportAllData: true,
          exportButton: true,
          emptyRowsWhenPaging: false,
          exportCsv: (columns, data) => {
            const headerRow = columns
              .filter((val) => !(val.title === "Details" || val.title === "Id"))
              .map((col) => col.title);

            const dataRows = data.map((item) => {
              return headerRow.map((val) => {
                if (level === "graphDetails") {
                  return item[val];
                }
              });
            });

            let newHeaderRow;
            if (level === "graphDetails") {
              newHeaderRow = headerRow;
            }

            const { exportDelimiter } = tableRef.current.props.options;

            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [newHeaderRow, ...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const csvContent2 = [...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const subLevel = headerRow.find(
              (element) => element === "Resource"
            );

            const csvFileName = level === "risk" ? "Risks" : "Metrics";

            ExcelExport(
              newHeaderRow,
              dataRows,
              csvFileName,
              level,
              isSummary,
              subLevel,
              subTab,
              riskType
            );

            //downloadCsv(csvContent, csvFileName);
          },
          search: admin ? false : true,
          sorting: admin ? false : true,
          paging: admin ? false : true,
          pageSizeOptions: [],
          doubleHorizontalScroll: true,
          // loadingType: "linear",
          style: {
            top: 0,
            display: "none",
          },
          headerStyle: {
            backgroundColor: "#F9F9F9",
            color: "black",

            fontSize: "14px",
            fontWeight: "bold",
            position: "sticky",
            top: 0,
          },
        }}
      />
    </div>
  );
}

export default memo(ExpandMaterialGrid);
