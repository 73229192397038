import React, { useContext, useEffect, useState } from "react";
import SprintHeader from "./SprintHeader";
import { Box, Button, Card } from "@mui/material";
import SelectField from "../../../../components/select/selectField";
import RangeSelector from "../../../../components/rangeSelector";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { GlobalData } from "../../../../context/globalData";
import MaterialTable from "material-table";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import axios from "../../../../utils/axiosHelper";
import { useStyles } from "../style";
import { tableIcons } from "../../../../utils/tableIcon";
import TrendContainer from "../trends";
import SprintTrend from "./Trend";
import { OverViewList } from "./OverviewColumnList";
import CheckSelect from "../../../../components/select/CheckSelect";

const SprintOverview = ({ headerChips, projectId }) => {
  const classes = useStyles();
  const { projectRangePickerValue } = useContext(GlobalData);
  const [openRange, setOpenRange] = useState(false);
  const [date, setDate] = useState(projectRangePickerValue);
  const [isTrendOpen, setIsTrendOpen] = useState(false);
  const [selectedResourceDetail, setSelectedResourceDetail] = useState({});
  const [TrendData, setTrendData] = useState([]);
  const [SprintAllMetrics, setSprintAllMetrics] = useState({});
  const [TrendType, setTrendType] = useState("Issue");
  const [allTeamList, setAllTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedTeamUrl, setSelectedTeamUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState({
    columnsList: [],
    data: [],
  });
  const WorkCommitment = headerChips["Work Commitment"][0];
  const options = {
    sorting: false,
    search: false,
    toolbar: false,
    headerStyle: {
      backgroundColor: "#efefef",
    },
  };
  const { sprintId } = useParams();
  const { state } = useLocation();
  useEffect(() => {
    if (selectedTeam?.length > 0) {
      let url = " ";
      selectedTeam.forEach((e, index) => {
        if (index === 0) {
          url += `&teamName=${e}`;
        } else {
          url += `&teamName=${e}`;
        }
      });
      setSelectedTeamUrl(url.trim(), selectedTeam);
    } else {
      setSelectedTeamUrl("", selectedTeam);
    }
  }, [selectedTeam]);

  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return item?.TeamName;
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/sprints/sprintOverall/?${dateUrl}&project_id=${projectId}&board_id=${state?.board?.id}${selectedTeamUrl}`;
    axios
      .get(url)
      .then((response) => {
        setSprintAllMetrics(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date, selectedTeamUrl]);

  // sprint overview trend
  useEffect(() => {
    setIsLoading(false);
    const dateUrl = datePayload(date);
    let url = `/api/v2/sprints/sprintOverview/?${dateUrl}&project_id=${projectId}&board_id=${state?.board?.id}${selectedTeamUrl}`;
    axios
      .get(url)
      .then((response) => {
        setIsLoading(true);
        const AllTeamItem = response?.data?.map((e) => {
          return {
            SprintName: e?.SprintName || "N/A",
            Team:
              e?.Team?.map((team) => {
                return {
                  TeamName: team?.TeamName || "N/A",
                  "CarryOver 2x": team?.TwoXcarryOver || 0,
                  Completed: team?.Completed || 0,
                  "Newly Committed": team?.NewlyCommitted || 0,
                  Carryover: team?.Carryover || 0,
                  Added: team?.Added || 0,
                };
              }) || [],
          };
        });
        setTrendData(AllTeamItem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date, selectedTeamUrl, TrendType]);

  const getTeamList = () => {
    axios
      .get(
        `/api/v2/sprints/LastSevenSprintsMembers/?projectId=${projectId}&boardId=${state?.board?.id}${selectedTeamUrl}`
      )
      .then((res) => {
        const data = OverViewList(
          teamList,
          res,
          classes,
          setIsTrendOpen,
          setSelectedResourceDetail
          // sprintBarGraphData,
          // history
        );
        setTeamList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTeamList();
  }, [state, selectedTeamUrl]);

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <div className="mx-2 mb-2">
          <SelectField
            input={
              <Button
                onClick={() => setOpenRange(true)}
                style={{
                  border: "1px solid #b7b7b7",
                  width: "230px",
                  textTransform: "capitalize",
                  padding: "5px",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "14px ",
                  paddingRight: "11px",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  height: "54px",
                }}
                endIcon={<DateRangeOutlinedIcon />}
              >
                {getSelectedDate(date)}
              </Button>
            }
            onClick={() => setOpenRange(true)}
          />
          <RangeSelector
            open={openRange}
            setOpen={setOpenRange}
            pickerHandler={(val) => setDate(val)}
            value={date}
            isNextDate={false}
          />
        </div>
        <Box>
          <CheckSelect
            names={allTeamList}
            value={selectedTeam}
            setValue={(value) => {
              setSelectedTeam(value);
            }}
            defaultValue={allTeamList[0]}
          />
        </Box>
      </Box>
      <SprintHeader SprintAllMetrics={SprintAllMetrics} />
      <SprintTrend
        graphData={TrendData}
        setTrendType={setTrendType}
        TrendType={TrendType}
        loading={isLoading}
      />

      <Card>
        <h5 className="p-3">
          Completed deliverables (How did we deliver in the past )
        </h5>
        <MaterialTable
          style={{ boxShadow: "none" }}
          columns={teamList.columnsList}
          data={teamList.data}
          enableRowNumbers
          rowNumberMode="static"
          icons={tableIcons}
          options={options}
        />
      </Card>
      {isTrendOpen && (
        <TrendContainer
          open={isTrendOpen}
          setOpen={setIsTrendOpen}
          selectedResourceDetail={selectedResourceDetail}
          isSprintOverView={true}
        />
      )}
    </>
  );
};

export default SprintOverview;
