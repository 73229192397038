import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import SettingsIcon from "@material-ui/icons/Settings";
// import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import ClearIcon from "@material-ui/icons/Clear";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Logo from "../../assets/itechLogo.png";
import { GlobalData } from "../../context/globalData";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import axios from "../../utils/axiosHelper";
import { useHistory, useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import ConfigureForm from "../../pages/auth/onBoardingScreens/ConfigureForm";
import User from "./User";
import Teams from "./Teams";
import Metrics from "./Metrics";
import Risk from "./risk/Risk";
import Sprint from "./Sprint";
import AlertFromRisks from "../../pages/settingPages/alertFrameWork/AlertFromRisks";
import { iterationDaysMap } from "../../utils/textMap";
import { Card } from "@material-ui/core";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import Metric from "../../pages/settingPages/metric/Metric";
import GeneralSetting from "../../pages/settingPages/general";
import ReportList from "../../pages/settingPages/reports";

let Range = [];

const useStyles = makeStyles((theme) => ({
  text1: {
    backgroundColor: "red",
  },
  grow: {
    flexGrow: 1,
    position: "inherit",
    top: "-10px",
    width: "100%",
    padding: "10px",
    margin: "0px",
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    fontWeight: "bold",
  },
  navigation: {
    display: "flex",
    marginLeft: theme.spacing(15),
    width: 250,
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    Width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "grid",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navbar: {
    backgroundColor: "white",
    boxShadow: "none",
    padding: 0,
    margin: 0,
  },
  baseLink: {
    cursor: "pointer",
    color: "#cccccc",
    textDecoration: "none",
  },
  activeLink: {
    color: "#fff",
  },
  button: {
    color: "#092c49",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },

  userProfile: {
    // backgroundColor: "orange",
    // fontSize: "14px",
    fontSize: "24px",
    color: "#092c49",
    fontWeight: "bold",
    // borderRadius: "50px",
    // "&:hover": {
    //   backgroundColor: "orange",
    // },
  },
  buttonCross: {
    color: "#00000",
    fontWeight: "bold",
  },
  videoadjust: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    right: 0,
    width: "250px",
  },
  settingHeader: {
    justifyContent: "space-between",

    display: "flex",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  text: {
    fontSize: "16px",
    alignSelf: "center",
    color: "#000000DE",
    marginLeft: "15px",
    marginTop: "15px",
    fontStyle: "bold",
  },
  textTite: {
    fontSize: "20px",
    alignSelf: "center",
    color: "#000000DE",
    fontStyle: "bold",
  },
  checkboxes: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
}));
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Setting(props) {
  const frstGraphClear = () => {
    console.log("first");
  };
  const secGraphClear = () => {
    console.log("first");
  };
  const history = useHistory();

  const classes = useStyles();
  // const history = useHistory();
  // const {
  //   location: { pathname },
  // } = history;
  const [localStroke, setLocalStroke] = useState();
  const [defaultDate, setDefaultDate] = useState("Last 90 Days");
  const [defaultDateValue, setDefaultDateValue] = useState();
  const {
    // setIsLogin,
    isLogin,
    enableAi,
    setProjectRangePickerValue: setRangePickerValue,
    // setMetricData,
    // setAllMetricData,
    // setTeamVal,
    // setTrendData,
    // setPopupPrecision,
    // setSelectedMetricTrend,
    // setIsSummary,
    // setEnableAi,
    // setFutureDates,
    settingData,
    setSettingData,
    setRole,
    iteration,
    iterationValueGlobal,
    setIterationValueGlobal,
    setIteration,
    setGraphLoading,
    setGraphLoading2,
    riskadvisor,
    // setRiskAdvisor,
    // isSignUp,
    // setIsSignUp,
    // isVerification,
    // setIsVerification,
    setOpen,
    open,
    setToolIntegrationUrl,
  } = useContext(GlobalData);
  const [iterationValue, setIterationValue] = useState("Weekly");
  const [iterationValueLocal, setIterationValueLocal] =
    useState(iterationValue);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const [open, setOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const futureRage = [
    // { name: "Next 7 Days", value: 7 },
    // { name: "Next 15 Days", value: 15 },
    { name: "Next 30 Days", value: 30 },
    { name: "Next 60 Days", value: 60 },
    { name: "Next 90 Days", value: 90 },
  ];
  const [value, setValue] = React.useState("General");
  const { settingTab } = useParams();
  const handleChange = (event, newValue) => {
    history.push(`/settings/${newValue}`);
  };
  useEffect(() => {
    // console.log(settingTab,"settingTab")
    setValue(settingTab);
  }, [settingTab]);

  // const signUpHandler = () => {
  //   // console.log("signUpHandler....")
  //   history.push("/signUp");
  //   setIsSignUp(false);
  //   setIsVerification(false);
  // };

  // const handleClose = () => {
  //   setIterationValue(iterationValueLocal);
  //   setLocalStroke(settingData?.stroke);

  //   setDefaultDate(settingData.rangeData);
  //   setOpenSetting(false);
  // };
  // const handleStrokeChange = (event) => {
  //   setLocalStroke(event.target.value);
  //   // setOpen(false);
  // };
  const handleDateRange = (event) => {
    setDefaultDate(event?.name);
    setDefaultDateValue(event?.value);

    graphClear();
    // setGlobalDefaultDate(event.target.value);
  };
  const handleIterationChange = (event) => {
    setIterationValue(event);
  };
  const graphClear = () => {
    if (frstGraphClear) frstGraphClear();
    if (secGraphClear) secGraphClear();
  };

  const handleApply = () => {
    setGraphLoading(true);
    setGraphLoading2(true);
    const body = {
      thickness: localStroke,
      range: defaultDate,
      rangeValue: defaultDateValue,
      Iteration: iterationValue,
    };

    axios
      .post("/api/auth/settings", body)
      .then((result) => {
        if (result.status === 200) {
          var obj = {
            isCustom: false,
            range: defaultDate,
          };

          setIterationValueLocal(iterationValue);
          setIterationValueGlobal(iterationValue);
          setRangePickerValue(obj);
          setSettingData({
            stroke: localStroke,
            rangeData: defaultDate,
            range: defaultDateValue,
          });
          setOpen(false);
          graphClear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userName = userDetails.name;
  const getSettingData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setRole(user?.isAdministrator);
    const source = axios.CancelToken.source();
    axios
      .get("/api/auth/me", {
        cancelToken: source.token,
      })
      .then((result) => {
        let setting = result.data.user?.settings;
        setRole(result.data.user?.isAdministrator);
        if (setting) {
          setLocalStroke(setting?.stroke);
          if (setting?.Iteration) {
            setIterationValueGlobal(setting?.Iteration);
            setIterationValueLocal(setting?.Iteration);
            setIterationValue(setting?.Iteration);
          }

          setting?.rangeValue
            ? setDefaultDateValue(setting.rangeValue)
            : setDefaultDateValue(-89);
          if (setting?.range) {
            setRangePickerValue({
              isCustom: false,
              range: setting?.range,
            });
            setDefaultDate(setting?.range);

            setSettingData({
              stroke: setting?.thickness,
              range: setting?.rangeValue,
              rangeData: setting?.range,
            });
          } else {
            setRangePickerValue({
              isCustom: false,
              range: "Last 90 Days",
            });
            setDefaultDate("Last 90 Days");
            setSettingData({
              stroke: 3,
              range: -89,
              rangeData: "Last 90 Days",
            });
          }
        } else {
          setDefaultDate("Last 90 Days");
          // setIterationDefault();
          setLocalStroke(3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();

      // setting ? "" : source.cancel();
    };
  };
  useEffect(() => {
    Range = [
      // { name: "Last 7 Days", value: -6 },
      // { name: "Last 15 Days", value: -14 },
      { name: "Last 30 Days", value: -29 },
      { name: "Last 60 Days", value: -59 },
      { name: "Last 90 Days", value: -89 },
      { name: "Last 180 Days", value: -179 },
    ];

    setLocalStroke(settingData.stroke);

    // eslint-disable-next-line no-unused-expressions
    isLogin ? (riskadvisor ? null : getSettingData()) : null;
  }, [iterationValueGlobal]);

  useEffect(() => {
    setLocalStroke(settingData.stroke);
  }, [settingData]);
  useEffect(() => {
    setLocalStroke(settingData.stroke);

    if (!enableAi) {
      return Range;
    } else {
      futureRage.map((data) => {
        Range.unshift({ name: data.name, value: data.value });
      });
    }
    return Range;
  }, [enableAi]);

  useEffect(() => {
    setToolIntegrationUrl("/settings/Tool Integration");
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <NotificationsIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const [val, setVal] = useState("two");

  const handleTab = (e, newVal) => {
    setVal(newVal);
  };
  //console.log(window.location.pathname);
  return (
    <>
      <BreadCrumb list={[]} active={"Settings"} />
      <div>
        <Card className="customCard">
          <Box>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  {/* <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="General"
                    value="General"
                    key="General"
                  /> */}
                  {/* <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Users"
                    value="Users"
                    key="Users"
                  />
                  <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Teams"
                    value="Teams"
                    key="Teams"
                  /> */}
                  {/* <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Tool Integration"
                    value="Tool Integration"
                    key="Tool Integration"
                  /> */}
                  <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Metrics"
                    value="Metrics"
                    key="Metrics"
                  />
                  {/* <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Risk"
                    value="Risk"
                    key="Risk"
                  /> */}
                  {/* <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Sprint"
                    value="Sprint"
                    key="Sprint"
                  /> */}
                  <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Alert Frame Work"
                    value="Alert Frame Work"
                    key="alert frame work"
                  />
                  <Tab
                    style={{
                      font: "normal  600 16px/28px Lexend Deca",
                      color: "#5EBDCC",
                    }}
                    label="Reports"
                    value="Reports configuration"
                    key="Reports configuration"
                  />
                </TabList>
              </Box>
              <TabPanel value={value} index={"General"}>
                <GeneralSetting />
                {/* {" "}
                <div className="row">
                  <div className="col-3 mt-4">
                    <span
                      className={classes.text}
                      style={{ fontWeight: "bold", marginTop: "15px" }}
                    >
                      You work days
                    </span>
                    <div>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                      >
                        <Select
                          value={defaultDate}
                          className={classes.videoadjust}
                        >
                          {Range.map((val, i) => {
                            return (
                              <MenuItem
                                key={i + val}
                                value={val.name}
                                onClick={() => handleDateRange(val)}
                              >
                                {val.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-4 mt-4">
                    <span
                      className={classes.text}
                      style={{ fontWeight: "bold", marginTop: "15px" }}
                    >
                      Your daily meeting
                    </span>
                    <div className={classes.videoadjust}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                      >
                        <Select value={iterationValue}>
                          {iterationDaysMap.map((val, i) => {
                            return (
                              <MenuItem
                                key={i + val}
                                value={val}
                                onClick={() => handleIterationChange(val)}
                              >
                                {val}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <span className={[classes.text]} style={{ fontWeight: "bold" }}>
                  Your metric calculation
                </span>
                <div className={classes.checkboxes}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Daily"
                    />
                    <FormControlLabel control={<Checkbox />} label="Weekly" />
                    <FormControlLabel control={<Checkbox />} label="Biweekly" />
                    <FormControlLabel control={<Checkbox />} label="Monthly" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Quarterly"
                    />
                  </FormGroup>
                </div>
                <FormControl>
                  <span
                    className={[classes.text]}
                    style={{ fontWeight: "bold" }}
                  >
                    Forecasting feature
                  </span>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl> */}
              </TabPanel>
              <TabPanel value={value} index={"Users"}>
                <User />
              </TabPanel>
              <TabPanel value={value} index={"Teams"}>
                <Teams />
              </TabPanel>
              <TabPanel value={value} index={"Tool Integration"}>
                <ConfigureForm isSetting={true} />
              </TabPanel>
              <TabPanel value={value} index={"Metrics"}>
                <Metric />
              </TabPanel>
              <TabPanel value={value} index={"Risk"}>
                <Risk />
              </TabPanel>
              {/* <TabPanel value={value} index={"Sprint"}>
                <Sprint />
              </TabPanel> */}
              <TabPanel value={value} index={"Alert Frame Work"}>
                <AlertFromRisks />
              </TabPanel>
              <TabPanel value={value} index={"Reports configuration"}>
                <ReportList />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </div>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value == index && <h4>{children}</h4>}</div>;
}
