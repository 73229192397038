import React, { useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import configImg from "../../../assets/configImg.png";
import axios from "../../../utils/axiosHelper";

const AzureModal = ({
  data,
  GrantAccess,
  res,
  classes,
  index,
  skipHandler,
  configTools
}) => {
  // let dataIndex = data.findIndex(
  //   (item) => item["Integration Name"] === "Azure"
  // );
  // let dataAzure = data[dataIndex];

  const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  const validationSchema = yup.object({
    url: yup
      .string()
      // .matches(urlRegex, "URL is not valid")
      .required("URL is required"),
  });

  // const azurSetupHandler = () =>{
  //   axios
  //   .post("/api/tool", payLoad)
  //   .then((result) => {
  //     if (result.data.success) {
  //       setLoading(false);
  //       history.push({
  //         pathname: `/auth/onBoarding`,
  //         // hash: "Tools Integration",
  //         // state: {
  //         //   details: "details",
  //         //   title: "Tools Integration",
  //         //   id: 6,
  //         //   isLoad: true,
  //         // },
  //       });
  //       localStorage.removeItem("AzureUrl");
  //     }
  //   })
  //   .catch((err) => {
  //     setErrorMessage(err.response?.data?.error);
  //     setLoading(false);
  //     setError(true);
  //     setTimeout(function () {
  //       setError(false);
  //     }, 3000);
  //     if(err?.response?.data?.error=== "LIMIT_REACHED"){
  //       setIsPlanUpgradeMessage(!isPlanUpgradeMessage)
  //     }
  //   });
  // }

  const formik = useFormik({
    initialValues: {
      url: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const result = data.find(({ name }) => name === "Azure");
      localStorage.setItem("AzureUrl", values.url);
      window.open(result.url, "_blank");
      formik.resetForm();
    },
  });
  console.log(formik.errors, "formik.errors");

  return (
    <>
      <div className={classes.middelContent}>
        <div className={classes.middelContentRight}>
          <div className={`${classes.middleMainTxt} mb-3`}>
            Itechgenic app is requesting access to your Azure account.
          </div>

          <div className={`${classes.middleMTxtLMs}`}>
            This will allow the app to show you insights from multiple metrics
            such as Cycle time, lead time, work commitment, bugs and many more!
          </div>

          {!configTools[res] ? (
            <>
              <form className="mt-3">
                <TextField
                  id="url"
                  name="url"
                  className="border-bottom"
                  size="small"
                  label="URL"
                  style={{ minWidth: 400 }}
                  InputProps={{ classes: { input: classes.input } }}
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                />
              </form>

              <div className={classes.btns}>
                <button
                  className={classes.grntBtn}
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Grant Access
                </button>
                {/* {value} {mergeResult.length} */}
                <button
                  className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                  onClick={() => {
                    skipHandler(index);
                  }}
                >
                  Skip
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="mt-5">Your Account has been added Successfully</p>
              <button
                className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                onClick={() => {
                  skipHandler(index);
                }}
              >
                Next
              </button>
            </>
          )}
        </div>
        <div className={classes.middelContentLeft}>
          <img src={configImg} className={classes.configImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default AzureModal;
