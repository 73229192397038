import React, { useEffect, useState } from 'react'
import instance from '../../utils/axiosHelper';
import { Autocomplete, TextField } from '@mui/material';

function EpicDropDown({ epicDropDownHandler, width = 500, style = {}, limitTags = 2 }) {
    const [selectedEpic, setSelctedEpic] = useState([]);
    const [EipicList, setEplicList] = useState([]);
    const handleSelect = (value) => {
        if (value?.length > 0) {
            let url = " ";
            value.forEach((e, index) => {
                if (index === 0) {
                    url += `?epic=${e.key}`;
                } else {
                    url += `&epic=${e.key}`;
                }
            });
            epicDropDownHandler(url.trim(), value);
        } else {
            epicDropDownHandler("", value);
        }
        setSelctedEpic(value)
    }
    const getEpicList = () => {
        instance
            .get(`/api/v2/projects/epic/list`)
            .then((res) => {
                if (res.data) {
                    setEplicList(res.data);
                }
            })
            .catch((err) => { });
    };
    useEffect(() => {
        getEpicList();
    }, []);
    return (
        <div style={{ width: 500, marginBottom: "20px", ...style }}>
            <Autocomplete
                multiple
                limitTags={limitTags}
                style={{
                    width: width,
                    // border: "1px solid",
                    paddingRight: `10px`,
                    borderRadius: 10,
                }}
                id="tags-readOnly"
                value={selectedEpic}
                onChange={(e, value) => handleSelect(value)}
                options={EipicList}
                getOptionLabel={(option) => option?.epic?.name}
                renderInput={(params) => (
                    <TextField {...params} placeholder="Select Epic" />
                )}
            />
        </div>
    )
}

export default EpicDropDown