import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";

import Typography from "@mui/material/Typography";
import { Formik, useFormik } from "formik";
import { addDays, format } from "date-fns";
import * as yup from "yup";
import instance from "../../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Form from "./form";
import { config } from "../../../../../Config/permission";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { datePayload } from "../../../../../utils/moduleTabApi";

function ScenarioPlanner() {
  const { projectId, epicId } = useParams();
  const { state, date } = useLocation();
  const [resourceList, setResourcelist] = useState([]);
  const [cloneResourceList, setCloneResourcelist] = useState([]);
  const [sprintList, setSprintList] = useState([]);
  const [epicList, setEpicList] = useState([]);
  const [unResolvedData, setUnResolvedData] = useState([]);
  const [resourceMsg, setResourceMsg] = useState("");
  const [ExecutionStatusForResource, setExecutionStatusForResource] =
    useState("");
  const [resData, setResData] = useState({});
  useEffect(() => {
    formik.setFieldValue("totalStoryPoints", state?.scope);
    formik.setFieldValue("avgVelocity", state?.velocity);
    formik.setFieldValue(
      "devAttention",
      state?.DevAttention[0]?.progressPercentage.toFixed()
    );
    formik.setFieldValue("startDate", new Date(state?.startDate));
    if (state?.targetDate) {
      formik.setFieldValue("endDate", new Date(state?.targetDate));
    }
  }, [state]);
  const [parentState, setParentState] = useState(false);
  const [devAttentionColumns, setDevAttentionColumns] = useState([]);
  const [techSkillRequestBody, setTechSkillRequestBody] = useState({});

  const initialValues = {
    startDate: new Date(),
    endDate: addDays(new Date(), 40),
    resources: [],
    resourceAllocated: "",
    totalStoryPoints: "",
    daysInSprint: "14",
    avgVelocity: "",
    // sprint: null,
    epic: [],
    devAttention: "0",
    // totalTimeRequired: "",
  };
  const onSubmit = (values) => {
    let payload;
    if (config.type === "Task") {
      payload = {
        ...values,
        avgVelocity: +values?.avgVelocity,
        daysInSprint: +values?.daysInSprint,
        totalTimeRequired: +values?.totalStoryPoints,
        devAttention: +values?.devAttention,
      };
    } else {
      payload = {
        ...values,
        avgVelocity: +values?.avgVelocity,
        daysInSprint: +values?.daysInSprint,
        totalStoryPoints: +values?.totalStoryPoints,
        devAttention: +values?.devAttention,
      };
    }

    instance
      .post(`/api/v2/sc/scenario/planner`, payload)
      .then((res) => {
        const response = res.data;
        const endDate = new Date(response?.endDate);
        const expectedDeliveryDate = new Date(response?.expectedDiliveryDate);
        const timeDifference = endDate - expectedDeliveryDate;
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        );
        if (expectedDeliveryDate > endDate) {
          setExecutionStatusForResource("BAD");
        } else {
          setExecutionStatusForResource("GOOD");
        }
        const delay =
          expectedDeliveryDate > endDate ? (
            <Typography className={"text-danger"}>
              {" "}
              Status: Delayed ({Math.abs(daysDifference)} Days).
            </Typography>
          ) : (
            <Typography className={"text-success"}> Status: On time</Typography>
          );
        setResData({
          ...response,
          delay: delay,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const regex = /^([1-9]|[1-9][0-9]|100)(\.\d+)?$/;

  const validationSchema = yup.object({
    resources: yup.array().min(1, "Resource Allocated is required"),
    epic: yup.array().min(1, "Epic sis required"),
    totalStoryPoints: yup
      .number()
      .typeError("Please enter valid number")
      .required(`Total ${config.type}  is required`),
    daysInSprint: yup
      .number()
      .typeError("Please enter valid number")
      .required("Days In Sprint is required"),
    avgVelocity: yup
      .number()
      .typeError("Please enter valid number")
      .required("Avg Velocity is required"),
    // sprint: yup.mixed().required("Sprint  is required"),
    devAttention: yup
      .string()
      .matches(regex, "Number should be 1 to 100")
      .required("Dev Attention is required"),
  });
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema,
  });

  const getTopFiveResourece = (childData) => {
    setParentState(childData);
  };

  useEffect(() => {
    const selectedResources = resourceList?.filter((res) => {
      return state?.uniqueResources.includes(res?.Name);
    });
    formik.setFieldValue("resources", selectedResources);
  }, [state, resourceList]);

  useEffect(() => {
    if (
      state?.status.includes("Slipping") ||
      state?.status.includes("delayed")
    ) {
      const data = {
        startDate: new Date(state?.startDate ? state?.startDate : "-"),
        endDate: new Date(state?.targetDate ? state?.targetDate : "-"),
        resources: [],
        resourceAllocated: state?.contributors,
        totalStoryPoints: state?.scope,
        daysInSprint: "14",
        avgVelocity: state?.velocity,
        epic: [{ _id: state?.key }],
        devAttention: "100",
      };
      onSubmit(data);
    }
  }, [state]);

  const getEpicUrl = (value) => {
    let epic = "";
    value.forEach((e, index) => {
      if (index === 0) {
        epic += `?epic=${e._id}`;
      } else {
        epic += `&epic=${e._id}`;
      }
    });
    return epic;
  };

  const getUserList = (sprintId, value) => {
    const epic = getEpicUrl(value);
    const dateUrl = datePayload(date);
    instance
      .get(
        `/api/v2/sc/scenario/planner/get/resource/${projectId}${epic}&${dateUrl}`
      )
      .then((res) => {
        const resources = res?.data
          .map((e) => {
            return {
              Name: e?.Name,
              TeamName: e?.TeamName,
              ResourceId: e?.ResourceId,
              avgVelocity: e?.AvgVelocity,
              AccountId: e?.AccountId,
              utilizationStatus: e?.utilizationStatus,
              averageHealthScore:
                e?.Quality.averageHealthScore < "0.20" ? "AVERAGE" : "GOOD",
              AttentionPercent: e?.AttentionPercent.$numberDecimal
                ? e?.AttentionPercent.$numberDecimal
                : e?.AttentionPercent,
            };
          })
          .filter((e) => state?.uniqueResources.includes(e?.Name));
        // Recommnded resource flow
        // const resourcesRecomand = res?.data
        //   .map((e) => {
        //     return {
        //       Name: e?.Name,
        //       TeamName: e?.TeamName,
        //       ResourceId: e?.ResourceId,
        //       avgVelocity: e?.AvgVelocity,
        //       AccountId: e?.AccountId,
        //       utilizationStatus: e?.utilizationStatus,
        //       averageHealthScore:
        //         e?.Quality.averageHealthScore < "0.20" ? "AVERAGE" : "GOOD",
        //       AttentionPercent: e?.AttentionPercent.$numberDecimal
        //         ? e?.AttentionPercent.$numberDecimal
        //         : e?.AttentionPercent,
        //     };
        //   })
        //   .filter((e) => e?.Name);
        // if (parentState) {
        //   setResourcelist(resourcesRecomand);
        // } else {
        //   setResourcelist(resources);
        // }
        setResourcelist(resources);
        setCloneResourcelist(resources);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getSprintList = () => {
  //   instance
  //     .get(`/api/v2/sc/scenario/planner/get/sprint/${projectId}`)
  //     .then((res) => {
  //       const data = res.data;
  //       setSprintList(data);
  //       if (data?.length > 0) {
  //         formik.setFieldValue("sprint", data[0]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const getEpicList = () => {
    instance
      .get(`/api/v2/sc/scenario/planner/get/project/${projectId}/epics`)
      .then((res) => {
        const data = res?.data?.filter((e) => e?._id === epicId);
        setEpicList(data);
        if (config.type === "Task") {
          formik.setFieldValue("epic", data.slice(0, 0));
        } else {
          if (data?.length > 0) {
            formik.setFieldValue("epic", data.slice(0, 3));
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const eicResource = (epic) => {
    instance
      .get(
        `/api/v2/sc/scenario/planner/project/${projectId}/sprint/${formik.values?.sprint?._id}/get/resource${epic}`
      )
      .then((res) => {
        const resources = res.data.map((e) => {
          return {
            Name: e.Name,
            ResourceId: e.ResourceId,
            avgVelocity: e.avgVelocity,
            AccountId: e.AccountId,
            // AttentionPercent: e?.AttentionPercent
          };
        });
        const commonObjects = resourceList.filter((obj1) =>
          resources.some((obj2) => obj2.ResourceId === obj1.ResourceId)
        );
        console.log(commonObjects, "commonObjects");
        formik.setFieldValue("resources", commonObjects);
        if (!resData) {
          formik.handleSubmit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStoryPoint = (value) => {
    if (!value) return;
    const epic = getEpicUrl(value);
    instance
      .get(
        `/api/v2/sc/scenario/planner/project/${projectId}/sprint/${formik.values?.sprint?._id}/get/storypoints${epic}`
      )
      .then((res) => {
        const data = res.data;
        if (data?.length > 0) {
          if (config.type === "Task") {
            formik.setFieldValue("totalTimeRequired");
          } else {
            formik.setFieldValue("totalStoryPoints", [
              data[0].totalStoryPoints,
            ]);
          }
          eicResource(epic);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const calculateAvgVelocity = (data = []) => {
    const total = data.reduce((acc, item) => {
      return (acc += item.AvgVelocity || item.avgVelocity);
    }, 0);
    return total?.toFixed(2);
  };
  const calCulateDevAttention = (arr = []) => {
    const sum = arr.reduce(
      (acc, curr) =>
        acc +
        (parseFloat(curr?.attentionPercentLastWeek)
          ? parseFloat(curr?.attentionPercentLastWeek)
          : 0),
      0
    );
    // Calculate the average
    const average = sum / arr?.length;
    return average?.toFixed();
  };
  useEffect(() => {
    const data = calculateAvgVelocity(formik?.values?.resources);
    formik.setFieldValue(
      "resourceAllocated",
      formik?.values?.resources?.length
    );
    formik.setFieldValue("avgVelocity", parseFloat(data));
    // const devAttention = calCulateDevAttention(formik?.values?.resources)
    // formik.setFieldValue('devAttention', devAttention)
  }, [formik.values.resources]);

  // useEffect(() => {
  //   getSprintList();
  // }, []);

  useEffect(() => {
    getEpicList();
  }, []);

  useEffect(() => {
    if (formik.values?.epic.length > 0) {
      getStoryPoint(formik.values?.epic);
    }
  }, [formik.values.epic]);

  useEffect(() => {
    getUserList(formik.values?.sprint?._id, formik.values?.epic);
  }, [formik.values?.sprint, formik.values.epic, date, parentState]);

  // Dev Attention api call
  useEffect(() => {
    const startDate = format(new Date(state?.startDate), "yyy-MM-dd");
    const endDate = format(new Date(state?.targetDate), "yyy-MM-dd");
    instance
      .get(
        `/api/v2/sc/scenario/devattention?epic=${state?.key}&startDate=${startDate}&targetDate=${endDate}&projectId=${projectId}`
      )
      .then((response) => {
        const mainColumn = response?.data.map((item, index) => {
          // Addition of Attention Percent
          const sumOfDevAttention = item?.currentDevAttention?.reduce(
            (acc, curr) => {
              return acc + curr?.progressPercentage;
            },
            0
          );
          const avgAttentionPer =
            sumOfDevAttention / item?.currentDevAttention?.length;
          // make string of Competing Priorities
          const newString = item?.detailedDevAttention
            .map(
              (str) =>
                `${str?.summery} <b>(${Math.floor(
                  Math.random() * 30
                )?.toFixed()}%)</b>`
            )
            .join(",");
          // return Object
          // str?.progressPercentage;
          return {
            resourceName: item?.Name,
            teamName: item?.TeamName,
            attentionPercentLastWeek: +avgAttentionPer?.toFixed() || 0,
            competingPriorities:
              newString || "No Competing Development Priorities",
          };
        });
        // Only required resource
        const newList = mainColumn.filter((obj1) =>
          resourceList.some((obj2) => obj1?.resourceName === obj2?.Name)
        );
        setDevAttentionColumns(newList);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [state, resourceList]);

  const resourceAvailabilityHandler = (value) => {
    if (formik.values.resources.length < value.length) {
      const latestUser = value.at(-1);
      const epic = getEpicUrl(formik.values.epic);
      const url = `/api/v2/sc/scenario/planner/project/${projectId}/get/resource/available${epic}&accountId=${latestUser.AccountId}`;
      instance
        .get(url)
        .then((res) => {
          if (res.data.status === false) {
            const key = res?.data?.keys?.join(", ");
            setResourceMsg(
              `Your other  ${key}  will get impacted if you add  ${latestUser.Name}. Please ensure that your other stakeholders are aware . Do you still want to add ${latestUser.Name} ?`
            );
          } else {
            setResourceMsg("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setResourceMsg("");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Done":
      case "On Time":
        return "text-success";
      case "Open":
        return "text-success";
      case "To do":
      case "To Do":
      case "On hold":
      case "In Progress":
        return "text-warning";
      default:
        if (status.includes("delayed")) {
          return "text-danger";
        } else if (status.includes("Slipping")) {
          return "text-danger";
        }
    }
  };

  const unResolvedIssues = () => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/issuetypesp/${projectId}?${dateUrl}&epic=${state?.key}`)
      .then((res) => {
        setUnResolvedData(res?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    unResolvedIssues();
  }, [state, date]);

  // useEffect(() => {
  //   instance
  //     .post(`/api/v2/sc/scenario/resourcebyRating`, techSkillRequestBody)
  //     .then((response) => {
  //       let newArray = [...cloneResourceList];
  //       let filterResource = response?.data;
  //       console.log(newArray, "call", filterResource);
  //       for (let i = 0; i < newArray.length; i++) {
  //         console.log("call: 1", newArray[i]);
  //         for (let j = 0; j < filterResource.length; j++) {
  //           console.log("call: 2", filterResource[0]);
  //           if (newArray[i].Name === filterResource[j].Name) {
  //             console.log("call: 1", filterResource[j]);
  //             newArray[i] = filterResource[j];
  //           } else {
  //             newArray.push(filterResource[j]);
  //             console.log("call: 2", filterResource[j]);
  //           }
  //         }
  //       }
  //       console.log("call: 2", newArray);
  //       setResourcelist(newArray);
  //     })
  //     .catch((error) => {
  //       console.log(error, "error");
  //     });
  // }, [techSkillRequestBody]);

  useEffect(() => {
    instance
      .post(`/api/v2/sc/scenario/resourcebyRating`, techSkillRequestBody)
      .then((response) => {
        let newArray = [...cloneResourceList];
        let filterResource = response?.data;
        let resultArray = [];

        // Iterate over newArray and check against filterResource
        for (let i = 0; i < newArray.length; i++) {
          let found = false;

          for (let j = 0; j < filterResource.length; j++) {
            if (newArray[i].Name === filterResource[j].Name) {
              resultArray.push(filterResource[j]); // Add the matched item to resultArray
              found = true;
              break; // No need to check further once we found a match
            }
          }

          // If no match was found, keep the original item
          if (!found) {
            resultArray.push(newArray[i]);
          }
        }

        // Optionally, add any remaining filterResource items that weren't matched
        for (let j = 0; j < filterResource.length; j++) {
          if (!newArray.some((item) => item.Name === filterResource[j].Name)) {
            resultArray.push(filterResource[j]);
          }
        }
        setResourcelist(resultArray);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [techSkillRequestBody]);

  return (
    <>
      <Card className="customCard p-3 border text-left h-100 mb-3">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Epic Information</Typography>
          </Grid>
          <Grid item xs={6}>
            {resData?.delay ? (
              resData?.delay
            ) : (
              <Typography className={getStatusColor(state?.status)}>
                Status: {state?.status}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}></Grid>
          {/* <Grid item xs={6}>
            <Typography>
              {`Expected ${config.type}  in Each Sprint`}:{" "}
              {resData?.expectedTaskIneachSprint}
            </Typography>
          </Grid> */}
          {/* <Grid item xs={6}>
            <Typography>
              Expected Number of Sprints Required:{" "}
              {resData?.expectedNumberOfSprintRequired}
            </Typography>
          </Grid> */}
          <Grid item xs={6}>
            <Typography>
              Expected Delivery Date:{" "}
              {resData?.expectedDiliveryDate
                ? format(new Date(resData?.expectedDiliveryDate), "dd-MMM-yyyy")
                : format(new Date(state?.expectedDate), "dd-MMM-yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Target Date:{" "}
              {state?.targetDate ? (
                format(new Date(state?.targetDate), "dd-MMM-yyyy")
              ) : (
                <>
                  {resData?.endDate ? (
                    format(new Date(resData?.endDate), "dd-MMM-yyyy")
                  ) : (
                    <span style={{ color: "red" }}>Select Date</span>
                  )}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Total FTE:{" "}
              {state?.fteUniqueResources
                ? state?.fteUniqueResources
                : resData?.fteUniqueResourcesinMonth}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Suggestion</Typography>
            <Typography>
              <>
                {resData?.suggestion}
                <span>
                  {ExecutionStatusForResource === "BAD" ? (
                    <>
                      {" "}
                      . Please Use Our
                      <strong> What If Adviser</strong> to Mitigate the Problem.{" "}
                    </>
                  ) : null}
                </span>
              </>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={"text-danger"}>{resourceMsg}</Typography>
          </Grid>
        </Grid>
      </Card>

      <Form
        formik={formik}
        calCulateDevAttention={calCulateDevAttention}
        resourceList={resourceList}
        sprintList={sprintList}
        epicList={epicList}
        resourceAvailabilityHandler={resourceAvailabilityHandler}
        resData={resData}
        ExecutionStatusForResource={ExecutionStatusForResource}
        unResolvedData={unResolvedData}
        isShowTopFiveResource={getTopFiveResourece}
        projectId={projectId}
        date={date}
        devAttentionColumns={devAttentionColumns}
        TechSkillRequestFunc={setTechSkillRequestBody}
        parentState={state}
      />
    </>
  );
}

export default ScenarioPlanner;
