import axios from "axios";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    document.body.classList.add("loading-indicator");
    config.headers.Authorization = `Bearer ${
      localStorage.getItem("token") || ""
    }`;
    config.baseURL = process.env.REACT_APP_BACKEND_URL;
    return config;
  },
  (error) => {
    console.log(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
