import React from "react";
import { UserInformation } from "./userInformation";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabContainer: {
    "border-bottom": "1px solid #e0e0e0 !important",
    "border-top": "1px solid #e0e0e0 !important",
  },
  btnstyle: {
    "& button": {
      "border-right": "1px solid #e0e0e0 !important",
      padding: "10px",
    },
    "& button:last-child": {
      "border-right": "0px !important",
      padding: "10px",
    },

    "& .MuiTab-wrapper": {
      "& >div": {
        width: "250px ",
        height: "auto ",
        
      },
    },
  },
}));
export const CardOfUser = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.tabContainer}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="none"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.btnstyle}
      >
        <Tab label={<UserInformation />} {...a11yProps(0)} />
        <Tab label={<UserInformation />} {...a11yProps(1)} />
        <Tab label={<UserInformation />} {...a11yProps(2)} />
        <Tab label={<UserInformation />} {...a11yProps(3)} />
        <Tab label={<UserInformation />} {...a11yProps(4)} />
        <Tab label={<UserInformation />} {...a11yProps(5)} />
        <Tab label={<UserInformation />} {...a11yProps(2)} />
        <Tab label={<UserInformation />} {...a11yProps(3)} />
        <Tab label={<UserInformation />} {...a11yProps(4)} />
        <Tab label={<UserInformation />} {...a11yProps(5)} />
      </Tabs>
    </div>
  );
};
