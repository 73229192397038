import AutoComp from "../../../components/jira/autoComp";
import { Button, DialogActions, Grid, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectField from "../../../components/select/selectField";
import { GlobalData } from "../../../context/globalData";
import axios from "../../../utils/axiosHelper";
import SelectFormFiled from "../../../components/select/selectFormFiled";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { useHistory } from "react-router-dom";
import { iterationList, rangeDuration } from "../../../utils/iteration";

const useStyles = makeStyles((theme) => ({
  //form css
  cancelBtnStyle: {
    background: "transparent",
    border: "1px solid #707070",
    cursor: "pointer",
    font: "normal normal normal 16px/20px Lexend Deca",
    borderRadius: "22px",
    width: "120px",
    padding: "8px 15px",
    margin: "15px 15px 15px 0",
  },
  applybtnStyle: {
    background: "#5EBDCC",
    color: "#ffff",
    cursor: "pointer",
    font: "normal normal normal 16px/20px Lexend Deca",
    borderRadius: "22px",
    width: "120px",
    padding: "8px 8px",
    margin: "15px 5px",
    border: "2px solid #5EBDCC",
    "&:hover": {
      background: "#26a6bb",
      border: "2px solid #26a6bb",
    },
  },
  riskText: {
    font: "normal normal normal 17px/18px Lexend Deca",
    color: "#B1B1B1",
    //   width: "325px",
    marginBottom: "20px",
    //   marginLeft:"30px",
    lineHeight: "1.5rem",
  },
}));

function AlertFormWorkForm() {
  const { state } = useLocation();

  const [data, setData] = useState({});

  const [t] = useTranslation("common");
  const classes = useStyles();
  const history = useHistory();
  const { errorMessage, setErrorMessage, error2, setError2, error, setError } =
    useContext(GlobalData);
  const [projectList, setProjectList] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);

  const [projectLevel, setProjectLevel] = useState();
  const [projectName, setProjectName] = useState();
  const [iteration, setIteration] = useState();
  const [daysDuration, setDaysDuration] = useState();
  const [notifications, setNotifications] = useState();
  const [alertType, setAlertType] = useState();
  const [email, setEmail] = useState(data?.email || "");
  let [projectLevelList] = useState([
    { lable: "Project Level", value: 1 },
    { lable: "Resources Level", value: 2 },
    { lable: "Team Level", value: 3 },
    { lable: "Module Level", value: 4 },
  ]);

  useEffect(() => {
    // this is for set value
    // if (data?.iteration) {
    //   let index = itrationList.findIndex((e) => e.lable == data.iteration);
    //   setIteration(itrationList[index]);
    // }
    // if (data?.Level) {
    //   let index = projectLevelList.findIndex((e) => e.lable == data.Level);
    //   setProjectLevel(projectLevelList[index]);
    // }
    // if (data?.day) {
    //   let index = rangeDuration.findIndex((e) => e.value == data.day);
    // }
    if (data) {
      setDaysDuration(data.days);
      setProjectLevel(data.groupTypeId)
      setIteration(data.iteration)
      setEmail(data.email)
      setAlertType(data.alertType)
      setNotifications(data.notficationType)
      setProjectName(data.projectId)
    }
  }, [data]);

  const getProjectList = () => {
    axios
      .get("/api/alerts/projects/get")
      .then((res) => {
        const projectData = res.data.data.map((e) => {
          return {
            lable: e.name,
            value: e.Id,
          };
        });
        setProjectList(projectData);
        if (data?.projectName) {
          let projectTemp = projectData.filter(
            (e) => e.name == data.projectName
          );
          setProjectName(projectTemp[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAlertList = () => {
    axios
      .get("/api/alerts/get/alert-type")
      .then((res) => {
        setAlertList(res.data.data);
        if (data?.alertType) {
          let type = res.data.data.filter((e) => e.value == data.alertType);
          setAlertType(type[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNotificationList = () => {
    axios
      .get("/api/alerts/get/notification-type")
      .then((res) => {
        setNotificationList(res.data.data);
        let notificationTemp = res.data.data.filter(
          (e) => e.value == data.notficationType
        );
        // setNotifications(notificationTemp[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let ClientID = localStorage.getItem("ClientId");
  const riskSubmitHandler = () => {
    let url;
    if (validateForm()) {
      if (data._id) {
        const body = {
          clientId: +ClientID,
          id: data?._id,
          groupTypeId: projectLevel,
          projectId: projectName,
          days: daysDuration,
          notficationType: notifications,
          alertType: alertType,
          iteration: iteration,
          email,
          isActive: data?.isActive,
        };
        url = `/api/alerts/update`;
        axios
          .post(url, body)
          .then((result) => {
            if (result.status === 200) {
              // setmetricDetails(metricDetails);
              // back();
              // getList();
              history.goBack();
            }
          })
          .catch((error) => {
            setErrorMessage(t(error.response?.data?.error));
            setError2(true);
            setTimeout(function () {
              setError2(false);
            }, 3000);
          });
      } else {
        const body = {
          clientId: +ClientID,
          groupTypeId: projectLevel,
          projectId: projectName,
          days: daysDuration,
          notficationType: notifications,
          alertType: alertType,
          iteration: iteration,
          email,
        };
        url = "/api/alerts/create";
        axios
          .post(url, body)
          .then((result) => {
            if (result.status === 200) {
              // setmetricDetails(metricDetails);
              history.goBack();
            }
          })
          .catch((error) => {
            setErrorMessage(t(error.response?.data?.error));
            setError2(true);
            setTimeout(function () {
              setError2(false);
            }, 3000);
          });
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!projectLevel) {
      formIsValid = false;
      errors["projectLevel"] = "Required";
    }
    if (!daysDuration) {
      formIsValid = false;
      errors["daysDuration"] = "Required";
    }
    if (!iteration) {
      formIsValid = false;
      errors["iteration"] = "Required";
    }
    if (!notifications) {
      formIsValid = false;
      errors["notifications"] = "Required";
    }
    if (!alertType) {
      formIsValid = false;
      errors["alertType"] = "Required";
    }
    if (!email) {
      formIsValid = false;
      errors["email"] = "Required";
    }

    setError(errors);
    return formIsValid;
  };

  useEffect(() => {
    getProjectList();
    getAlertList();
    getNotificationList();
  }, []);

  useEffect(() => {
    setData(state?.row || []);
  }, [state]);

  return (
    <>
      <div className={classes.riskText}>
        {/* <span style={{ color: "#ff0000" }}>*</span>
        <label style={{ marginTop: "20px" }}>Indicates Required Field</label> */}
      </div>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={[]}
        active={"New Alert Frame work"}
      />
      <form autoComplete="off" className={"p-2"} onSubmit={riskSubmitHandler}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectFormFiled
              withLable={true}
              render={projectLevelList}
              value={projectLevel}
              setValue={setProjectLevel}
              label="Project Level"
            />
            <div className="errorMsg">{error.projectLevel}</div>
          </Grid>
          {console.log(projectList, "projectList")}
          {projectLevel > 1 && (
            <Grid item xs={4}>
              <SelectFormFiled
                withLable={true}
                render={projectList}
                label={"Project Names"}
                setValue={setProjectName}
                value={projectName}
              />
              <div className="errorMsg">{error.projectId}</div>
            </Grid>
          )}
          <Grid item xs={4}>
            <SelectFormFiled
              withLable={true}
              label={"Iteration"}
              render={iterationList}
              value={iteration}
              setValue={setIteration}
            />
            <div className="errorMsg">{error.iteration}</div>
          </Grid>
          <Grid item xs={4}>
            <SelectFormFiled
              withLable={true}
              label={"Days Duration"}
              render={rangeDuration}
              value={daysDuration}
              setValue={setDaysDuration}
            />
            <div className="errorMsg">{error.daysDuration}</div>
          </Grid>
          <Grid item xs={4}>
            <SelectFormFiled
              withLable={true}
              label={"Alert Type"}
              render={alertList}
              value={alertType}
              setValue={setAlertType}
            />
            <div className="errorMsg">{error.alertType}</div>
          </Grid>
          <Grid item xs={4}>
            <SelectFormFiled
              withLable={true}
              label={"Notifications"}
              render={notificationList}
              value={notifications}
              setValue={setNotifications}
            />
            <div className="errorMsg">{error.notifications}</div>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="email"
              name="email"
              className="border-bottom w-100"
              size="small"
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="errorMsg">{error.email}</div>
          </Grid>
          <Grid item xs={12}>
            <div
              className="position-relative from-grid"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                className={classes?.cancelBtnStyle}
                onClick={() => history.goBack()}
              >
                <span>Cancel</span>
              </Button>
              <Button
                className={classes?.applybtnStyle}
                onClick={riskSubmitHandler}
              >
                <span>Apply</span>
              </Button>
            </div>
          </Grid>
        </Grid>

        {/* <div className={classes?.inputWraper}>
          <div className={classes?.label + " " + classes?.min_width}>
            Project Level
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div>
            <div className={classes?.tableFieldGrid}>
              <SelectField
                withLable={true}
                render={projectLevelList}
                value={projectLevel}
                setValue={setProjectLevel}
                custom={true}
              />
            </div>
            <div className={classes?.errorMsg} style={{ marginLeft: "0px" }}>
              {error.projectLevel}
            </div>
          </div>
        </div> */}
        {/* {projectLevel > 1 && (
          <div className={classes?.inputWraper}>
            <div className={classes?.label + " " + classes?.min_width}>
              Project List
            </div>
            <div>
              <div className={classes?.tableFieldGrid}>
                <AutoComp
                  data={projectList}
                  labelName={"Project Names"}
                  setValue={setProjectName}
                  value={projectName}
                />
              </div>
              <div className="errorMsg">{error.projectId}</div>
            </div>
          </div>
        )} */}
        {/* <div className={classes?.inputWraper}>
          <div className={classes?.label + " " + classes?.min_width}>
            Iteration
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div>
            <div className={classes?.DropdownMargin}>
              <SelectField
                withLable={true}
                custom={true}
                render={itrationList}
                value={iteration}
                setValue={setIteration}
              />
              <div className="errorMsg">{error.iteration}</div>
            </div>
          </div>
        </div> */}
        {/* <div className={classes?.inputWraper}>
          <div className={classes?.label + " " + classes?.min_width}>
            Days
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div>
            <div className={classes?.DropdownMargin}>
              <SelectField
                withLable={true}
                custom={true}
                render={rangeDuration}
                value={daysDuration}
                setValue={setDaysDuration}
              />
              <div className="errorMsg">{error.daysDuration}</div>
            </div>
          </div>
        </div> */}
        {/* <div className={classes?.inputWraper}>
          <div className={classes?.label + " " + classes?.min_width}>
            Alert Type
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div>
            <div className={classes?.DropdownMargin}>
              <SelectField
                withLable={true}
                custom={true}
                render={alertList}
                value={alertType}
                setValue={setAlertType}
              />
              <div className="errorMsg">{error.alertType}</div>
            </div>
          </div>
        </div> */}
        {/* <div className={classes?.inputWraper}>
          <div className={classes?.label + " " + classes?.min_width}>
            Notification Type
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div>
            <div className={classes?.DropdownMargin}>
              <SelectField
                withLable={true}
                custom={true}
                render={notificationList}
                value={notifications}
                setValue={setNotifications}
              />
              <div className="errorMsg">{error.notifications}</div>
            </div>
          </div>
        </div>
        <div className={classes?.inputWraper}>
          <div className={classes?.label + " " + classes?.min_width}>
            Email
            <span style={{ color: "#ff0000" }}>*</span>
          </div>
          <div>
            <div className={classes?.DropdownMargin}>
              <TextField
                name="email"
                variant="outlined"
                size="small"
                type={email}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div className="errorMsg">{error.email}</div>
            </div>
          </div>
        </div> */}
        {/* <DialogActions>
          <Button onClick={() => setIsForm(false)}>
            <span className={classes?.btnlabel}>Cancel</span>
          </Button>
          <Button onClick={riskSubmitHandler}>
            {" "}
            <span className={classes?.btnlabel}>Create</span>
          </Button>
        </DialogActions> */}
      </form>
      {error2 && (
        <div className={classes?.alert}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
}

export default AlertFormWorkForm;
