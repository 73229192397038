import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

export default function CheckSelect({ names, setValue, value, defaultValue }) {
  const classes = useStyles();

  const isAllSelected = names?.length > 0;

  const handleChange = (event) => {
    const { value } = event.target;
    if (value[value?.length - 1] === "all") {
      setValue(value?.length === names.length ? [] : names);
      return;
    }
    setValue(typeof value === "string" ? value?.split(",") : value);
  };

  useEffect(() => {
    if (names.length > 0 && value.length === 0 && defaultValue) {
      setValue([defaultValue]);
    }
  }, [names, setValue, value?.length, defaultValue]);

  return (
    <FormControl style={{ width: "200px" }}>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        style={{
          height: "45px",
          borderRadius: "40px",
          border: "1px solid gray",
          padding: "2px 6px",
        }}
      >
        <MenuItem value="all">
          <Checkbox
            checked={isAllSelected}
            indeterminate={value?.length > 0 && value?.length < names?.length}
          />
          <ListItemText primary="All" />
        </MenuItem>
        {names?.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={value?.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
