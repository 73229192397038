import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { red } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { SortingONTitle } from "../../../utils/sortingOnTable";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  saveicon: {
    width: 30,
    height: 30,
    backgroundColor: red,
  },
  "MuiTab-root": {
    padding: 0,
  },
  tablecont: {
    marginTop: "40px",
    "& td": {
      // borderRight: "1px solid #80808047",
      padding: "20px",
    },
    "& td:last-child": {
      borderRight: "0px solid #80808047",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiTableCell-head": {
      boxShadow: "none",
      color: "black",
      fontWeight: "bold",
      background: "#F2F2F2 0% 0% no-repeat padding-box",
      textAlign: "center",
    },
  },
  fl: {
    display: "flex",
    gap: "10px",
  },
});

export const JiraDynamicTable = (props) => {
  const { searchJiraIssue, keysOfObject, name } = props;
  const [page, setPage] = useState(0);
  const [dataOfTable, setDataOfTable] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [indexToggle, setIndexToggle] = useState();

  let PaginationHandler = (initialValue, finalValue) => {
    let data = [...searchJiraIssue];
    let dataOfTables = data?.slice(
      Math.abs(initialValue),
      Math.abs(finalValue)
    );
    setDataOfTable(dataOfTables);
  };
  useEffect(() => {
    let initialValue = page * 10 - 10;
    let finalValue = page * 10;
    PaginationHandler(initialValue, finalValue);
  }, [page]);
  const classes = useStyles();
  useEffect(() => {
    PaginationHandler(0, 10);
    window.scrollTo(0, 500);
  }, [searchJiraIssue]);

  const sortData = (header, toggle, index) => {
    let data = SortingONTitle(header, toggle, index, dataOfTable);
    setIndexToggle(data?.indexOfHeader);
    setDataOfTable(data?.sortedDataOfTable);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.tablecont}
        elevation={0}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {keysOfObject?.map((header, index) => {
                return (
                  <>
                    {header.title !== "url" && (
                      <TableCell
                        align="left"
                        key={index}
                        onClick={() => {
                          setToggle(!toggle);
                          sortData(header, toggle, index);
                        }}
                      >
                        <div className={classes.fl}>
                          <div>{header.title}</div>
                          <div>
                            {toggle && indexToggle === index ? (
                              <ArrowUpwardIcon style={{ fontSize: 15 }} />
                            ) : (
                              <ArrowDownwardIcon style={{ fontSize: 15 }} />
                            )}
                          </div>
                        </div>
                      </TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {name === "jira" &&
              dataOfTable?.map((item, index) => (
                <TableRow key={index}>
                  {keysOfObject?.map(
                    (content, index) =>
                      content.title !== "url" && (
                        <TableCell align="left" style={{ width: "12%" }}>
                          <>
                            <label>
                              {item[content.keyItem] === 1 ? (
                                <CheckCircleIcon style={{ color: "green" }} />
                              ) : item[content.keyItem] === 0 ? (
                                <CancelIcon style={{ color: "red" }} />
                              ) : content.title === "key" ? (
                                <a
                                  target="_blank"
                                  href={item.url}
                                  rel="noreferrer"
                                >
                                  {item[content.keyItem]}
                                </a>
                              ) : (
                                <label>{item[content.keyItem]}</label>
                              )}
                            </label>
                          </>
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}

            {name === "gitHub" &&
              dataOfTable?.map((item, index) => (
                <TableRow key={index}>
                  <>
                    <TableCell align="left" style={{ width: "12%" }}>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <label>{`${item?.sha.substring(0, 6)}`}</label>
                      </a>
                    </TableCell>
                    <TableCell align="left" style={{ width: "12%" }}>
                      <label>{`${item?.author}`}</label>
                    </TableCell>
                    <TableCell align="left" style={{ width: "12%" }}>
                      <label>
                        {format(new Date(item?.date), "dd-MMM-yyyy")}
                      </label>
                    </TableCell>
                    <TableCell align="left" style={{ width: "12%" }}>
                      <label>{`${item?.newWorkPercentage.toFixed(2)} %`}</label>
                    </TableCell>
                    <TableCell align="left" style={{ width: "12%" }}>
                      <label>{`${item?.reWorkPercentage.toFixed(2)} %`}</label>
                    </TableCell>
                    <TableCell align="left" style={{ width: "12%" }}>
                      <label
                        style={{
                          color:
                            item?.legacyrefactorPercentage.toFixed(2) < 5
                              ? "green"
                              : item?.legacyrefactorPercentage.toFixed(2) > 6 &&
                                item?.legacyrefactorPercentage.toFixed(2) < 10
                              ? "orange"
                              : "red",
                        }}
                      >{`${item?.legacyrefactorPercentage.toFixed(
                        2
                      )} %`}</label>
                    </TableCell>
                  </>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div>
        <Pagination
          count={Math.ceil(searchJiraIssue.length / 10)}
          onChange={(item, index) => {
            setPage(index);
          }}
        />
      </div> */}
    </>
  );
};
