export const textValue = {
  ProjectName: "Project",
  MetricGroup: "Metric Group",
};

export const inverseTextValue = {
  Project: "ProjectName",
  "Metric Group": "MetricGroup",
};
export const DaysMap = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};
export const StatusValueMap = {
  Active: true,
  Inactive: false,
};
export const MonthMap = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};
export const iterationMap = {
  Weekly: 7,
  Daily: 1,
  Biweekly: 14,
  Monthly: 30,
  Quarterly: 90,
};

export const iterationDaysMap = [
  // "Daily",
  "Weekly",
  "Biweekly",
  "Monthly",
  // "Quarterly",
];
export const Metric = ["Metric Details", "Metric Summary"];
