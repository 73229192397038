import React, { useEffect, useRef } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

function OverviewTrend({ data, YAxisLabel }) {
  console.log(data, "data");
  const flattenData = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    // Get the keys dynamically from the first entry
    const keys = Object.keys(data[0]?.Team[0] || []);

    return data?.flatMap((entry) =>
      entry?.Team?.map((item) => ({
        startDate: entry?.SprintName,
        ...keys.reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {}),
        combined: `${entry?.SprintName}-${item?.TeamName}`,
      }))
    );
  };

  const flattenedData = flattenData(data);
  const chartRef = useRef(null);
  useEffect(() => {
    if (!flattenedData || flattenedData.length === 0) {
      console.error("No data available for chart");
      return;
    }
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    // Add data
    chart.data = flattenedData;
    chart.colors.step = 2;

    // Create category axis for combined label
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "combined";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    // Group labels by startDate
    categoryAxis.renderer.labels.template.adapter.add(
      "textOutput",
      function (text) {
        if (text) {
          let parts = text.split("-");
          return `${parts[1]}\n(${parts[0]})`;
        }
        return text;
      }
    );

    // Add a grid line for each startDate group
    let previousDate;
    categoryAxis.events.on("dataitemsvalidated", function () {
      categoryAxis.dataItems.each(function (dataItem) {
        let currentDate = dataItem.category.split("-")[0];
        if (previousDate && previousDate !== currentDate) {
          let range = categoryAxis.axisRanges.create();
          range.category = dataItem.category;
          range.label.disabled = true;
          range.grid.stroke = am4core.color("#000");
          range.grid.strokeWidth = 1;
          range.grid.strokeOpacity = 1;
        }
        previousDate = currentDate;
      });
    });

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = YAxisLabel;

    // Dynamic creation of series based on fields and names
    function createSeries(fields, names) {
      fields.forEach((field, index) => {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "combined";
        series.name = names[index];
        series.stacked = true;
        series.columns.template.tooltipText = `[bold]{name}: {valueY}`;
        // series.columns.template.fillOpacity = 0.8;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        // columnTemplate.strokeOpacity = 1;
      });
    }

    chart.scrollbarX = new am4core.Scrollbar();

    // Extract fields and names dynamically
    const keys = Object.keys(data[0]?.Team[0]).filter(
      (key) => key !== "TeamName"
    );
    const names = keys.map((key) => key.charAt(0).toUpperCase() + key.slice(1));

    // Create series dynamically
    createSeries(keys, names);

    // Add legend
    chart.legend = new am4charts.Legend();

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);
  return (
    <div
      id="chartdiv"
      style={{ width: "100%", height: "500px", overflow: "auto" }}
    />
  );
}

export default OverviewTrend;

// import React, { useEffect } from "react";
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// const OverviewTrend = ({ TrendData, yLabel }) => {
//   console.log(TrendData, "TrendData");
//   useEffect(() => {
//     // Check if TrendData is available
//     if (!TrendData || TrendData.length === 0) {
//       return; // No data to render
//     }

//     // Use amCharts theme
//     am4core.useTheme(am4themes_animated);

//     // Create chart instance
//     let chart = am4core.create("chartdiv", am4charts.XYChart);

//     chart.colors.step = 4;

//     // Set chart data
//     chart.data = TrendData;

//     // Create category axis
//     let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
//     categoryAxis.dataFields.category = "SprintName";
//     categoryAxis.renderer.grid.template.location = 0;

//     // Create value axis
//     let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
//     valueAxis.renderer.outside = true; //yaxes  lebel inside and outside
//     valueAxis.renderer.labels.template.disabled = false;
//     valueAxis.min = 0;
//     valueAxis.title.text = yLabel;

//     // Create series dynamically
//     Object.keys(TrendData[0]).forEach((key, index) => {
//       if (key !== "SprintName") {
//         console.log(key);
//         // Create series
//         let series = chart.series.push(new am4charts.ColumnSeries());
//         series.name = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize series name
//         series.dataFields.valueY = key;
//         series.dataFields.categoryX = "SprintName";
//         series.sequencedInterpolation = true;
//         series.stacked = true;

//         // Configure columns
//         series.columns.template.width = am4core.percent(60); // its is width of column
//         series.columns.template.tooltipText = `[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}`; //It is tooltip of column

//         // Add label bullet
//         let labelBullet = series.bullets.push(new am4charts.LabelBullet());
//         labelBullet.label.text = `{valueY}`; //values of column
//         labelBullet.locationY = 0.5; //Potion of column title
//         labelBullet.label.hideOversized = true; // if true hide column 0 th value of column and if false it shows 0th values of column
//       }
//     });

//     // Add chart legend
//     chart.legend = new am4charts.Legend(); // Add Legend (Indicator) of x axis

//     // Clean up when component unmounts
//     return () => {
//       chart.dispose();
//     };
//   }, [TrendData, yLabel]);

//   return <div id="chartdiv" style={{ width: "100%", height: 500 }} />;
// };

// export default OverviewTrend;
