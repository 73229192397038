import { useState, useEffect } from "react";

export const useMetricHook = () => {
  const [metricGraphData, setMetricGraphData] = useState([]);
  const [metricGraph, setMetricGraph] = useState();
  const [gridData, setGridData] = useState([]);
  const flush = () => {
    setMetricGraphData([]);
    setMetricGraph();
  };

  useEffect(() => {
    if (!metricGraph) return;
    if (!metricGraph.trends) return;
    if (!gridData) return;
    const data = metricGraph.trends[0];
    console.log("data",data);
    let counter = 0;
    const nameMap = {};
    let maxLength = 0;
    let timeArry = [];

    const reducedArry = data.reduce((obj, item) => {
      console.log("obj n item", obj,item);
      if (obj[item.Name]) {
        const innerObj = {
          [item.Time]: item.Value,

          ProjectId: item.ProjectId,
        };
        obj[item.Name].valMap.push(innerObj);
      } else {
        obj[item.Name] = {};
        obj[item.Name].valMap = [
          { [item.Time]: item.Value, ProjectId: item.ProjectId },
        ];
        // obj[item.Name].valMap = [{Value:item.Value,ProjectId:item.ProjectId}];
        counter = 0;
      }
      counter++;
      return obj;
    }, {});
    Object.keys(reducedArry).map((val) => {
      console.log("val",val);
      const length = reducedArry[val].valMap.length;

      if (maxLength < length) {
        maxLength = length;
        timeArry = reducedArry[val].valMap;
    
      }
console.log("timeArry",timeArry);
    });
    const timeMap = timeArry.map((v) => Object.keys(v)[0]);

    const newArry = Array(maxLength)
      .fill()
      .map((_, i) => {
        const obj = {};
        let index = 0;
        Object.keys(reducedArry)
          .sort((a, b) => {
            return gridData.indexOf(a) - gridData.indexOf(b);
          })
          .filter((val) => gridData.includes(val))
          .map((val, i) => {
          
            index = i + 1;
            obj["Name" + index] = val;
            obj["Value" + index] = null;
            obj["ProjectId" + index] = null;
            nameMap[val] = index;
          });
        obj["Time"] = timeMap[i];
        return obj;
      });

    Object.keys(reducedArry).map((value) => {
      reducedArry[value].valMap.map((val, i) => {
        const time = Object.keys(val)[0];

        if (newArry[timeMap.indexOf(time)]) {
          newArry[timeMap.indexOf(time)]["Value" + nameMap[value]] =
            Object.values(val)[0];
        }
      });
    });
    newArry
      .sort((a, b) => {
        return new Date(b.Time) - new Date(a.Time);
      })
      .map((val, i) => {
        if (!metricGraph.target) return val;
        val.Name = "Target";
        val.Value = metricGraph.target;
        return val;
      });

    Object.keys(reducedArry).map((value) => {

      reducedArry[value].valMap.map((val, i) => {
        
        const time = Object.keys(val)[0];

        if (newArry[timeMap.indexOf(time)]) {
          newArry[timeMap.indexOf(time)]["ProjectId" + nameMap[value]] =
            val.ProjectId;
        }
      });
    });
    newArry
      .sort((a, b) => {
        return new Date(b.Time) - new Date(a.Time);
      })
      .map((val, i) => {
        if (!metricGraph.target) return val;
        val.Name = "Target";
        val.Value = metricGraph.target;
        return val;
      });

    setMetricGraphData(newArry);
  }, [metricGraph, gridData]);

  return {
    metricGraphData,
    setMetricGraph,
    setGridData,
    flush,
  };
};
