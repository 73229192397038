import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "../../../utils/axiosHelper";
import { format } from "date-fns";
import InvestmentProfileTemplate from "../../investmentProfile/InvestmentProfileTemplate";
import { addSpace } from "../../../utils/spaceSeprator";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Grid } from "@material-ui/core";
import CardDetail from "../../../components/Header/cardDetail";
import teamMemberImg from "../../../assets/users.png";
import check from "../../../assets/check.png";
import { config } from "../../../Config/permission";
import InvestmentFilter from "../../../components/investmentProfileFilter/InvestmentFilter";
import { GlobalData } from "../../../context/globalData";
import { Box } from "@mui/material";
import { datePayload } from "../../../utils/moduleTabApi";
import SelectInvestmentProfile from "../../../components/select/selectInvestmentProfile";
import CheckSelect from "../../../components/select/CheckSelect";

const InvestmentProfile = ({ project, sprintId, sprintId2, boardId }) => {
  const [issueType, setIssueType] = useState();
  const [TeamOrResource, setTeamOrResource] = useState("Team");
  const [issueList, setIssueList] = useState();
  const [pieGraphData, setpieGraphData] = useState();
  const [issueData, setIssueData] = useState();
  const [issueColumn, setIssueColumn] = useState();
  const [sprintCompareData, setSprintCompareData] = useState([]);
  const [costAmt, setCostAmt] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState("All");
  const [HeaderData, setHeaderData] = useState({});
  const { projectRangePickerValue } = useContext(GlobalData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [issueCostHandler, setIssueCostHandler] = React.useState(null);
  const [selectedViewBy, setSelectedViewBy] = useState();
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [date, setDate] = useState(projectRangePickerValue);
  const [TrendUnit, setTrendUnit] = useState("%");
  const [issueCostAmt, setIssueCostAmt] = useState("");
  const [TrendLabel, setTrendLabel] = useState("Time (%)");
  const [AllTeamList, setAllTeamList] = useState([]);
  const [AssigneeList, setAssigneeList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [Cumulative, setCumulative] = useState("");
  const [issueLevelCost, setIssueLevelCost] = useState();
  const [totalCostInDoller, setTotalCostInDoller] = useState("");

  const [selectedTeamOrResourceUrl, setSelectedTeamOrResourceUrl] =
    useState("");
  const viewType = useMemo(() => {
    const type = selectedViewBy?.includes("Cost") ? "Cost" : selectedViewBy;
    return type;
  }, [selectedViewBy]);

  console.log(issueCostAmt, "issueCostAmt");

  useEffect(() => {
    setSelectedTeam([]);
  }, [TeamOrResource]);

  useEffect(() => {
    if (TeamOrResource === "Team") {
      if (selectedTeam?.length > 0) {
        let url = " ";
        selectedTeam.forEach((e, index) => {
          if (index === 0) {
            url += `&teamName=${e}`;
          } else {
            url += `&teamName=${e}`;
          }
        });
        setSelectedTeamOrResourceUrl(url.trim(), selectedTeam);
      } else {
        setSelectedTeamOrResourceUrl("", selectedTeam);
      }
    } else {
      if (selectedTeam?.length > 0) {
        let url = " ";
        selectedTeam.forEach((e, index) => {
          if (index === 0) {
            url += `&resourceName=${e}`;
          } else {
            url += `&resourceName=${e}`;
          }
        });
        setSelectedTeamOrResourceUrl(url.trim(), selectedTeam);
      } else {
        setSelectedTeamOrResourceUrl("", selectedTeam);
      }
    }
  }, [selectedTeam]);

  useEffect(() => {
    let url = `/api/v2/teams/list/kpis?no_of_days=60&startDay=2024-04-1&endDay=2024-05-31&iteration=7&project_id=${project}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return item?.TeamName;
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/v2/projects/resourceAllocation/${project}?iteration=30&no_of_days=180&startDay=2023-12-1&endDay=2024-05-31`
      )
      .then((res) => {
        if (res?.data) {
          const data = Object.keys(res?.data).map((e) => {
            return { title: e, value: res?.data[e].toFixed() };
          });
          setAssigneeList(
            data.map((e) => e?.title).filter((e) => e !== "null")
          );
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  const SprintDetailHandler = () => {
    if (!project || !sprintId) return;
    const dateUrl = datePayload(date);
    let url = `/api/v2/investmentProfile/sprintLevelDetails?projectId=${project}&sprintId=${sprintId2}&boardId=${boardId}&${dateUrl}`;
    if (viewType) {
      url += `&type=${viewType}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    if (selectedTeamOrResourceUrl) {
      url += selectedTeamOrResourceUrl;
    }
    axios
      .get(url)
      .then((result) => {
        setHeaderData({
          TaskCount: result?.data?.TaskCount,
          ResourceCount: result?.data?.ResourceCount,
          StartDate: format(new Date(result?.data?.StartDate), "dd-MM-yyyy"),
          EndDate: format(new Date(result?.data?.EndDate), "dd-MM-yyyy"),
        });
        let issue = result?.data?.IssueType?.map((item) => item?.IssueType);
        setIssueList(issue);
        let pieGraphData = result?.data?.res?.map((item) => {
          return {
            title: item?.title,
            value: item?.value,
          };
        });
        setpieGraphData(pieGraphData);
        setIssueType(result?.data?.IssueType?.[0]?.IssueType);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const issueDetailsHandler = () => {
    if (!project || !issueType || !sprintId2) return;
    let url;
    if (issueType === "All") {
      url = `/api/v2/investmentProfile/sprintLevelIssue/${project}?sprint_id=${sprintId2}&board_id=${boardId}`;
    } else {
      url = `/api/v2/investmentProfile/sprintLevelIssue/${project}?sprint_id=${sprintId2}&board_id=${boardId}&issuetype=${issueType}`;
    }

    if (selectedTeamOrResourceUrl) {
      url += selectedTeamOrResourceUrl;
    }
    // if (selectedAssignee === "All") {
    //   url = `/api/v2/investmentProfile/sprintLevelIssue/${project}?sprint_id=${sprintId2}&board_id=${boardId}&issuetype=${issueType}`;
    // } else {
    //   url = `/api/v2/investmentProfile/sprintLevelIssue/${project}?sprint_id=${sprintId2}&board_id=${boardId}&issuetype=${issueType}&resourceName=${selectedAssignee}`;
    // }
    if (issueLevelCost) {
      url += `&cost=${issueLevelCost}`;
    }
    axios
      .get(url)
      .then((result) => {
        setIssueData(result?.data?.result2);
        setCumulative(result?.data?.totalFTE);
        const totalCost = result?.data?.result2.reduce((acc, curr) => {
          return acc + curr?.Cost;
        }, 0);
        setTotalCostInDoller(totalCost);
        const data = Object.keys(
          result?.data?.result2?.length > 0 ? result?.data?.result2?.[0] : {}
        )?.filter((e) => {
          if (e !== "tableData" && e !== "TaskLink") {
            return e;
          }
        });
        const columns = data?.map((e) => {
          return {
            field: e,
            title: addSpace(e),
            render: (data) => {
              if (e === "TaskId") {
                return (
                  <a target="_blank" href={data["TaskLink"]} rel="noreferrer">
                    {data?.TaskId}
                  </a>
                );
              } else if (e === "CreatedTime") {
                return format(new Date(data[e]), "dd-MMM-yyy");
              } else {
                return data[e];
              }
            },
          };
        });
        setIssueColumn(columns);
        // window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const sprintCompare = () => {
    let url = `/api/v2/investmentProfile/sprinttrend/${project}/${sprintId}?`;
    if (viewType) {
      url += `&type=${viewType}`;
    }
    if (viewType === "Cost") {
      url += `&cost=${costAmt}`;
    }
    if (epicData.url) {
      url += epicData.url.replace("?", "&");
    }
    if (selectedTeamOrResourceUrl) {
      url += selectedTeamOrResourceUrl;
    }
    axios
      .get(url)
      .then((res) => {
        const output = [];
        const issueTypes = Array.from(
          new Set(
            res?.data?.flatMap((sprints) =>
              sprints?.map((issue) => issue?.IssueType)
            )
          )
        );
        const data = [];
        res?.data?.forEach((e) => {
          const allData = [];
          issueTypes.forEach((issue) => {
            const obj = e?.find((item) => item?.IssueType === issue);
            allData.push(obj);
          });
          data.push(allData);
        });
        // Old code
        // issueTypes?.forEach((issue, index) => {
        //   const result = {
        //     category: issue,
        //   };
        //   data?.forEach((e, i) => {
        //     result["name"] = data[index][0].SprintName;
        //     result["value" + (i + 1)] = e[index]?.Task;
        //   });
        //   output.push(result);
        // });
        // new code
        issueTypes.forEach((issueType, index) => {
          const result = {
            category: issueType,
          };
          const sprintData = res.data.map((sprints) =>
            sprints.find((issue) => issue.IssueType === issueType)
          );
          result.name = sprintData[index]?.SprintName || "";
          sprintData.forEach((sprint, i) => {
            result["value" + (i + 1)] = sprint ? sprint.Task2.toFixed() : null;
          });

          output.push(result);
        });
        setSprintCompareData(output);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    SprintDetailHandler();
    sprintCompare();
  }, [
    project,
    selectedViewBy,
    viewType,
    epicData,
    date,
    selectedTeamOrResourceUrl,
  ]);

  useEffect(() => {
    issueDetailsHandler();
  }, [issueType, selectedTeamOrResourceUrl, issueLevelCost]);

  // Header Cost
  const handleConst = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCostClose = () => {
    setAnchorEl(null);
  };

  const ApplyHandler = () => {
    setSelectedViewBy(`Cost${Math.random()}`);
    handleCostClose();
  };

  // Issue level Cost
  const handleIssueConst = (event) => {
    setIssueCostHandler(event.currentTarget);
  };

  const handleIssueClose = () => {
    setIssueCostHandler(null);
  };

  const ApplyIssueHandler = () => {
    setIssueLevelCost(issueCostAmt);
    handleIssueClose();
  };

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    switch (viewType) {
      case "Percent":
        setTrendUnit("%");
        setTrendLabel("Time (%)");
        break;
      case "FTE":
        setTrendUnit("FTE");
        setTrendLabel("FTE");
        break;
      case "Cost":
        setTrendUnit("$");
        setTrendLabel("Cost ($)");
        break;
      default:
        break;
    }
  }, [viewType]);

  const selectTeamOrResourceTypeHandler = (event) => {
    setTeamOrResource(event?.target?.value);
  };

  return (
    <>
      <Box mb={2}>
        <InvestmentFilter
          setDate={setDate}
          date={date}
          issueType={issueType}
          setIssueType={setIssueType}
          anchorEl={anchorEl}
          handleClose={handleCostClose}
          handleClick={handleConst}
          ApplyHandler={ApplyHandler}
          costAmt={costAmt}
          setCostAmt={setCostAmt}
          epicDropDownHandler={epicDropDownHandler}
          selectedViewBy={viewType}
          setSelectedViewBy={setSelectedViewBy}
          HeaderData={HeaderData}
        />
      </Box>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={3}>
          <Card className={`customCard`}>
            <CardDetail
              img={check}
              value={`${HeaderData?.TaskCount || ""}`}
              name={`No. of ${config.type} completed`}
            />
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`customCard`}>
            <CardDetail
              img={teamMemberImg}
              value={`${HeaderData?.ResourceCount || ""}`}
              name={"Total Resource"}
            />
          </Card>
        </Grid>
        <Grid item xs={3}>
          <div className="mb-3">Please select Team Or Contributor:</div>
          <SelectInvestmentProfile
            handleChange={selectTeamOrResourceTypeHandler}
            list={["Team", "Contributor"]}
            value={TeamOrResource}
            iteration={true}
          />
        </Grid>
        {TeamOrResource === "Team" ? (
          <Grid item xs={3}>
            <div className="mb-3">Please select Team:</div>
            <CheckSelect
              names={AllTeamList}
              setValue={(value) => {
                setSelectedTeam(value);
              }}
              value={selectedTeam}
            />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <div className="mb-3">Please select Contributor:</div>
            <CheckSelect
              names={AssigneeList}
              setValue={(value) => {
                setSelectedTeam(value);
              }}
              value={selectedTeam}
            />
          </Grid>
        )}
      </Grid>
      <InvestmentProfileTemplate
        setIssueType={setIssueType}
        issueList={issueList}
        pieGraphData={pieGraphData}
        issueData={issueData}
        issueColumn={issueColumn}
        columnTitle={"Issue Level Details For Current Sprint"}
        issueType={issueType}
        date={false}
        isSprint={true}
        sprintCompareData={sprintCompareData}
        // project={project}
        selectedAssignee={selectedAssignee}
        setSelectedAssignee={setSelectedAssignee}
        TrendUnit={TrendUnit}
        trendLabel={TrendLabel}
        isCumulative={Cumulative}
        showCost={handleIssueConst}
        issueCostHandler={issueCostHandler}
        handleIssueCostClose={handleIssueClose}
        setIssueCostAmt={setIssueCostAmt}
        issueCostAmt={issueCostAmt}
        ApplyIssueHandler={ApplyIssueHandler}
        totalCostInDoller={totalCostInDoller}
      />
    </>
  );
};

export default InvestmentProfile;
