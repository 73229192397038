import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import NavTabs from "../../components/tabs/tabs";
import Template from "./template";
import { useRiskColumnHook } from "../../utils/riskcolumnhook";
import { API_RISK_GRID_GEN } from "../../utils/apiGen";
import { idMap } from "../../utils/idsMap";
import axios from "../../utils/axiosHelper";
import { SprintMap } from "../../utils/levelMap";
import { iterationMap } from "../../utils/textMap";
import { GlobalData } from "../../context/globalData";
import Button from "@material-ui/core/Button";
import UpgradePlanRequest from "../../utils/UpgradePlanRequest";

const Risks = ["All Risks", "High Risk", "Medium Risk"];
const complete = ["All Sprints", "Completed Sprints", "Ongoing Sprints"];
const Metric = ["Metric Details", "Metric Summary"];

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    marginTop: "25px",
    width: "100%",
    display:"none",
  },
  feature: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
    marginTop: "20px",
  },
  btn: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function RiskPage(props) {
  let Tabs = ["Projects", "Teams", "Resources", "Modules"];
  const { state } = useLocation();
  const tabHorizontalTab = "true"
  const { projectId } = useParams();
  const { projectLevel } = props;
  //   {
  //     "matric": [
  //         "Pull Requests",
  //         "Resolved Vulnerabilities Lead Time",
  //         "Resolved Vulnerabilities Cycle Time",
  //         "Story Point Cycle Time",
  //         "Schedule Varient"
  //     ],
  //     "projectName": "eCommerce",
  //     "days": "Last 180 Days"
  // }

  if (projectLevel) Tabs = Tabs.filter((val) => val !== "Projects");

  const classes = useStyles();
  const [render, setRender] = useState(0);
  const tab = Tabs[render];
  const group_id = idMap[tab.toUpperCase()];
  const [riskValue, setRiskValue] = useState("All Risks");
  const [completeValue, setCompleteValue] = useState("All Sprints");

  const { setColumnVal, column, data } = useRiskColumnHook(projectLevel);

  const [isLoading, setLoading] = useState(false);
  const [openData, setOpenData] = useState(null);
  const [metricValue, setMetricValue] = useState("Metric Details");
  const {
    setIsSummary,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    iteration,
    iterationValueGlobal,
    setIterationValueGlobal,
    isPlanUpgradeRequest,
    setIsPlanUpgradeRequest,
  } = useContext(GlobalData);

  let pricePlan = JSON.parse(localStorage.getItem("pricePlan"));
  let isProject = pricePlan?.pricingPlan?.featureList?.risk?.projectLevel;
  let isTeam = pricePlan?.pricingPlan?.featureList?.risk?.teamLevel;
  let isResource = pricePlan?.pricingPlan?.featureList?.risk?.resourceLevel;
  let isModule = pricePlan?.pricingPlan?.featureList?.risk?.moduleLevel;
  let isEnabledRisk = pricePlan?.pricingPlan?.featureList?.risk?.isEnabled;

  useEffect(() => {
    if (state?.days) {
      setRangePickerValue({ isCustom: false, range: state.days });
    }
  }, [state?.days]);

  useEffect(() => {
    let unmounted = false;
    setColumnVal([]);
    setLoading(true);
    const source = axios.CancelToken.source();
    setIsSummary(metricValue === "Metric Summary");
    axios
      .get(
        API_RISK_GRID_GEN(
          rangePickerValue,
          riskValue,
          group_id,
          projectId,
          metricValue,
          iterationMap[iterationValueGlobal]
        ),
        {
          cancelToken: source.token,
        }
      )
      .then((result) => {
        setLoading(false);

        if (state?.projectName && render === 0) {
          const newData = result?.data.filter((item) => {
            return item?.Project === state?.projectName;
          });
          let metricFilter = [];
          let metricName = state?.matric;
          newData.forEach((item) => {
            metricName.forEach((entry) => {
              if (entry === item["Metric"]) {
                metricFilter.push(item);
              }
            });
          });
          setColumnVal(metricFilter);
        } else {
          setColumnVal(result.data);
        }
        setOpenData(result.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (unmounted) {
          setLoading(true);
        }
      });

    return () => {
      unmounted = true;
      source.cancel();
    };
  }, [
    setColumnVal,
    rangePickerValue,
    riskValue,
    render,
    metricValue,
    iterationValueGlobal,
  ]);

  useEffect(() => {
    if (!openData) return;
    if (render !== 4) return;
    if (SprintMap[completeValue]) {
      setColumnVal(
        openData.filter((val) => {
          return val.State === SprintMap[completeValue];
        })
      );
    } else {
      setColumnVal(openData);
    }
  }, [openData, setColumnVal, completeValue, render]);

  const planRequestHandler = () => {
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
  };

  let renderPage = null;
  if (render === 0) {
    //project
    renderPage = isProject ? (
      <Template
        key={0}
        subTab="risk-project"
        Iteration={iteration}
        setIteration={setIterationValueGlobal}
        iterationValue={iterationValueGlobal}
        Columns={column}
        tableData={data}
        Risks={Risks}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        isLoading={isLoading}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  }

  if (render === 1) {
    //teams
    renderPage = isTeam ? (
      <Template
        key={1}
        subTab="risk-teams"
        Iteration={iteration}
        setIteration={setIterationValueGlobal}
        iterationValue={iterationValueGlobal}
        Columns={column}
        tableData={data}
        Risks={Risks}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        isLoading={isLoading}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  }

  if (render === 2) {
    //resources
    renderPage = isResource ? (
      <Template
        subTab="risk-resource"
        Iteration={iteration}
        setIteration={setIterationValueGlobal}
        iterationValue={iterationValueGlobal}
        key={2}
        Columns={column}
        tableData={data}
        Risks={Risks}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        isLoading={isLoading}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  }

  if (render === 3) {
    //Modules
    renderPage = isModule ? (
      <Template
        subTab="risk-modules"
        key={3}
        Columns={column}
        tableData={data}
        Risks={Risks}
        Iteration={iteration}
        setIteration={setIterationValueGlobal}
        iterationValue={iterationValueGlobal}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        isLoading={isLoading}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
        rangePickerValue={rangePickerValue}
        setRangePickerValue={setRangePickerValue}
      />
    ) : (
      <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
    );
  }

  if (render === 4) {
    //sprint
    renderPage = (
      <Template
        key={4}
        Columns={column}
        tableData={data}
        Risks={Risks}
        Iteration={iteration}
        setIteration={setIterationValueGlobal}
        iterationValue={iterationValueGlobal}
        riskValue={riskValue}
        setRiskValue={setRiskValue}
        complete={complete}
        completeValue={completeValue}
        setCompleteValue={setCompleteValue}
        isLoading={isLoading}
        Metric={Metric}
        metricValue={metricValue}
        setMetricValue={setMetricValue}
      />
    );
  }

  return (
    <div style={{ marginTop: "35px" }} id="mainRiskAdvisor">
      {isEnabledRisk ? (
        <>
          <div className={classes.tabWrapper}>
            <NavTabs
              value={render}
              setValue={setRender}
              render={Tabs}
              fontsize="20px"
              tabHorizontalTab={tabHorizontalTab}
            />
          </div>
          {renderPage}
        </>
      ) : (
        <>
        <div className={`${classes.feature}`}>
          <h4>You dont have access. Please upgrade your plan.</h4>
        </div>
        <div className={`${classes.featureBtn}`}>
          <Button
            variant="contained"
            className={classes.btn}
            type="button"
            onClick={() => {
              planRequestHandler();
            }}
          >
            Upgrade
          </Button>
        </div>
        {isPlanUpgradeRequest && <UpgradePlanRequest />}
      </>
      )}
    </div>
  );
}
