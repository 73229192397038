import React from "react";
import ProjectLevelRiskRisk from "./projectLevelList/ProjectLevelRiskRisk";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { makeStyles } from "@material-ui/core/styles";

import SprintLevelRiskRisk from "./sprintLevelRisk/sprintLevelRisk";
function Risk() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const useStyles = makeStyles((theme) => ({
    btnStyle: {
      float: "right",
      background: "#5EBDCC",
      cursor: "pointer",
      font: "normal normal normal 16px/20px Lexend Deca",
      borderRadius: "22px",
      width: "180px",
      padding: "8px 8px",
      margin: "15px 5px",
      color: "#fff",
      border: "none",
      position: "absolute",
      top: "0px",
      right: 10,
      zIndex: 1,
    },
    btngroup: {
      marginTop: "10px",
    },
    searchnButton: {
      display: "flex",
      margin: "25px 25px",
    },
    headerlabel: {
      margin: "20px 5px",
      font: "normal normal normal 20px/23px Lexend Deca",
    },
    tableStyle: {
      position: "relative",
      "& .MuiTextField-root": {
        position: "absolute",
        left: "10px",
        background: "#f8f8f8",
        borderRadius: 20,
        "& .MuiInput-root": {
          height: 45,
        },
      },
    },

    //form css
    cancelBtnStyle: {
      background: "transparent",
      border: "1px solid #707070",
      cursor: "pointer",
      font: "normal normal normal 16px/20px Lexend Deca",
      borderRadius: "22px",
      width: "120px",
      padding: "8px 15px",
      margin: "15px 15px 15px 0",
    },
    applybtnStyle: {
      background: "#5EBDCC",
      color: "#ffff",
      cursor: "pointer",
      font: "normal normal normal 16px/20px Lexend Deca",
      borderRadius: "22px",
      width: "120px",
      padding: "8px 8px",
      margin: "15px 5px",
      border: "2px solid #5EBDCC",
      "&:hover":{
        background:"#26a6bb",
      border: "2px solid #26a6bb",
      }
    },
    riskText: {
      font: "normal normal normal 17px/18px Lexend Deca",
      color: "#B1B1B1",
    //   width: "325px",
      marginBottom: "20px",
    //   marginLeft:"30px",
      lineHeight: "1.5rem",
    },

    // m-form:{
    //     display: "flex",
    //     flexWrap: "wrap".
    // },



  }));
  const classes = useStyles();
  console.log(classes, "classes");
  return (
    <div  className="nestingTabs">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" className="m-2">
            <Tab
            className="border"
              style={{
                font: "normal  600 16px/28px Lexend Deca",
                color: "#5EBDCC",
                borderRadius: "6px 6px 0px 0px",
                boxShadow: "inset lightgray 0px 0px 8px 0px",
              }}
              label="Project consolidated Risk"
              value="1"
            />
            <Tab
            className="border mx-4"
              style={{
                font: "normal  600 16px/28px Lexend Deca",
                color: "#5EBDCC",
                borderRadius: "6px 6px 0px 0px",
                boxShadow: "inset lightgray 0px 0px 8px 0px",
              }}
              label="Sprint consolidated Level Risk"
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value={value} index={"1"}>
          <ProjectLevelRiskRisk classes={classes} />
        </TabPanel>
        <TabPanel value={value} index={"2"}>
          <SprintLevelRiskRisk classes={classes} />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Risk;
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value == index && <h4>{children}</h4>}</div>;
}
