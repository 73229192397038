import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import projectIcon from "../../assets/project_icon.svg";
import teamIcon from "../../assets/team_logo.svg";
import resourceIcon from "../../assets/resource_icon.svg";
import moduleIcon from "../../assets/module_icon.svg";
import sprintIcon from "../../assets/sprint_icon.svg";
import riskIcon from "../../assets/risk_icon.svg";
import notificationIcon from "../../assets/notification_icon.svg";
import setupIcon from "../../assets/setup_icon.svg";
import settingIcon from "../../assets/setting_icons.svg";
import logoutIcon from "../../assets/logout_icon.svg";
import riskImage from "../../assets/Risk.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFolder, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  tabstyle: {
    fontWeight: "bold",
    color: "#092c49",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "#092c49",
      opacity: 1,
    },
  },

  paperStyle: {
    // borderBottom: "2px solid #cacaca",
  },
  tabsIndicator: {
    height: 0,
  },
  rootTabsIndicator: {
    backgroundColor: "#092c49",
    height: 3,
  },
  tabsRoot: {
    backgroundColor: "#092c49",
    color: "#fff",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    "&:hover": {
      color: "#fff",
      opacity: 1,
    },
    height: "50px",
    width: "196px",
    borderRadius: "4px",
  },
  tabRoot: {
    height: "50px",
    width: "196px",
  },
  rootLeveleTabsRoot: {
    color: "#092c49",
  },
  tabs: {
    borderRight: `0px solid ${theme.palette.divider}`,
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "50px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "white",
      backgroundColor: "#5EBDCC",
      height: "50px",
      width: "196px",
      borderRadius: "4px",
      fontSize: "14px",
    },
  },
}));

export default function NavTabs(props) {
  const {
    value,
    setValue,
    render,
    fontsize,
    isroot,
    clearGridResponse,
    resetGrid,
    clearFrstGraph,
    clearSecGraph,
    clearAdmin,
    groupId,
    tabHorizontalTab,
  } = props;

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (clearGridResponse) clearGridResponse(null);
    if (resetGrid) resetGrid([]);
    if (clearFrstGraph) clearFrstGraph();
    if (clearSecGraph) clearSecGraph();
    // if (clearAdmin) clearAdmin();
  };
  return (
    <Paper square elevation={0} className={classes.paperStyle}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        orientation={"vertical"}
        classes={{
          indicator: isroot ? classes.rootTabsIndicator : classes.tabsIndicator,
        }}
        className={classes.tabs}
      >
        {render &&
          render.map((val, i) => (
            <Tab
            iconposition="end"

              icon={
                <div
                  className=""
                  style={{ position: "relative", marginRight: "10px" }}
                >
                  {val === "PROJECTS" && (
                    // <img src={projectIcon} alt="" width={16} height={16} style={{fill:"white"}}></img>
                    <i
                      className="fa fa-folder-o"
                      width={16}
                      height={16}
                      style={{ color: "#707070" }}
                      aria-hidden="true"
                    ></i>
                  )}

                  {val === "MODULES" && (
                    <img
                      src={moduleIcon}
                      alt=""
                      width={16}
                      height={16}
                      style={{ fill: "white" }}
                    ></img>
                  )}
                  {val === "SPRINT" && (
                    <img
                      src={sprintIcon}
                      alt=""
                      width={16}
                      height={16}
                      style={{ fill: "white" }}
                    ></img>
                  )}
                  {val === "NOTIFICATIONS" && (
                    <img
                      src={notificationIcon}
                      alt=""
                      width={16}
                      height={16}
                      style={{ fill: "white" }}
                    ></img>
                  )}

                  {val === "SETTINGS" && (
                    <img
                      src={settingIcon}
                      alt=""
                      width={16}
                      height={16}
                      style={{ fill: "white" }}
                    ></img>
                  )}

                  {val === "LOGOUT" && (
                    <img
                      src={logoutIcon}
                      alt=""
                      width={16}
                      height={16}
                      style={{ fill: "white" }}
                    ></img>
                  )}
                </div>
              }
              label={
                <div className="" style={{ position: "relative" }}>
                  {val}
                </div>
              }
              key={i + val}
              classes={{
                root: classes.tabstyle,
                selected: isroot
                  ? classes.rootLeveleTabsRoot
                  : classes.tabsRoot,
              }}
              style={{ fontSize: fontsize || "20px" }}
            />
          ))}
      </Tabs>
    </Paper>
  );
}
