import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import NormalSelect from "../../../components/select/normalSelect";
import axios from "../../../utils/axiosHelper";
import css from "./index.module.css";
import { useEffect } from "react";
import DatePicker from "../../../components/datepicker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function GeneralSetting() {
  const dayDropDown = [
    { label: "Monday", value: 1 },
    { label: "Monday To Tuesday", value: 2 },
    { label: "Monday To Wednesday", value: 3 },
    { label: "Monday To Thursday", value: 4 },
    { label: "Monday To Friday", value: 5 },
    { label: "Monday To Saturday", value: 6 },
    { label: "Monday To Sunday", value: 7 },
  ];

  const hoursList = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
  ];
  const calculation = [
    { label: "Average", value: "average" },
    { label: "75th Percentile", value: "75" },
  ];

  const minList = [
    { label: 0, value: 0 },
    { label: 15, value: 15 },
    { label: 30, value: 30 },
    { label: 45, value: 45 },
  ];
  const TimeList = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  const countryTimezones = [
    {
      label: "Asia/Kabul",
      value: "Asia/Kabul",
    },
    {
      label: "Asia/Yerevan",
      value: "Asia/Yerevan",
    },
    {
      label: "Australia/Sydney",
      value: "Australia/Sydney",
    },
    {
      label: "Asia/Baku",
      value: "Asia/Baku",
    },
    {
      label: "Asia/Dhaka",
      value: "Asia/Dhaka",
    },
    {
      label: "Asia/Thimphu",
      value: "Asia/Thimphu",
    },
    {
      label: "Asia/Shanghai",
      value: "Asia/Shanghai",
    },
    {
      label: "Asia/Kolkata",
      value: "Asia/Kolkata",
    },
    {
      label: "Asia/Jakarta",
      value: "Asia/Jakarta",
    },
    {
      label: "Asia/Tokyo",
      value: "Asia/Tokyo",
    },
    {
      label: "Asia/Kuala_Lumpur",
      value: "Asia/Kuala_Lumpur",
    },
    {
      label: "Pacific/Auckland",
      value: "Pacific/Auckland",
    },
    {
      label: "Asia/Karachi",
      value: "Asia/Karachi",
    },
    {
      label: "Asia/Manila",
      value: "Asia/Manila",
    },
    {
      label: "Asia/Singapore",
      value: "Asia/Singapore",
    },
    {
      label: "Asia/Seoul",
      value: "Asia/Seoul",
    },
    {
      label: "Asia/Colombo",
      value: "Asia/Colombo",
    },
    {
      label: "Asia/Taipei",
      value: "Asia/Taipei",
    },
    {
      label: "Asia/Bangkok",
      value: "Asia/Bangkok",
    },
    {
      label: "Asia/Ho_Chi_Minh",
      value: "Asia/Ho_Chi_Minh",
    },
  ];

  const formik = useFormik({
    initialValues: {
      weekStart: "",
      enableAI: "yes",
      daily: false,
      weekly: false,
      monthly: false,
      biweekly: false,
      quarterly: false,
      hours: "",
      min: "",
      time: "",
      ingnoredDate: [""],
      timeZone: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (value) => {
    const payload = {
      isSync: true,
      isCalculate: true,
      enableAI: value.enableAI == "yes" ? true : false,
      isDailyCalculate: value.daily,
      isWeeklyCalculate: value.weekly,
      isBiWeeklyCalculate: value.biweekly,
      isMonthlycalculate: value.monthly,
      isQuarterlyCalculate: value.quarterly,
      futureDates: 60,
      ingnoredDate: value.ingnoredDate,
      timeZone: value.timeZone,
      settings: {
        weekStart: value.weekStart,
        biweekStart: 1,
        quaterStart: 4,
        meetingTime: `${value.hours}:${value.min}:${value.time}`,
      },
    };
    axios
      .post("/api/auth/setup/tool", payload)
      .then((res) => {
        getClient();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClient = () => {
    axios
      .get("/api/client")
      .then((result) => {
        const res = result.data;
        const time =
          res.settings?.meetingTime && res.settings?.meetingTime.split(":");
        localStorage.setItem("ClientId", result.data.ClientId);
        formik.setFieldValue("enableAI", res.enableAI ? "yes" : "no");
        formik.setFieldValue("weekStart", res.settings.weekStart);
        formik.setFieldValue("daily", res.isDailyCalculate);
        formik.setFieldValue("weekly", res.isWeeklyCalculate);
        formik.setFieldValue("biweekly", res.isBiweeklyCalculate);
        formik.setFieldValue("monthly", res.isMonthlyCalculate);
        formik.setFieldValue("quarterly", res.isQuarterlyCalculate);
        formik.setFieldValue("timeZone", res?.timeZone);
        formik.setFieldValue("ingnoredDate", res.ingnoredDate || [""]);

        if (time) {
          formik.setFieldValue("hours", time[0]);
          formik.setFieldValue("min", time[1]);
          formik.setFieldValue("time", time[2]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClient();
  }, []);
  useEffect(
    (e) => {
      if (formik.values?.ingnoredDate.length === 0) {
        formik.setFieldValue("ingnoredDate[0]", "");
      }
    },
    [formik.values?.ingnoredDate]
  );
  return (
    <Box p={5} className={"minHeight"}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <label className={css.label}>Your work days</label>
              <NormalSelect
                name="weekStart"
                option={dayDropDown}
                handleChange={(value) =>
                  formik.setFieldValue("weekStart", value)
                }
                value={formik.values.weekStart}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <label className={css.label}>Your daily meeting</label>
              <div className="row">
                <div className="col-md-4">
                  <NormalSelect
                    name="hours"
                    option={hoursList}
                    handleChange={(value) =>
                      formik.setFieldValue("hours", value)
                    }
                    value={formik.values.hours}
                  />
                </div>
                <div className="col-md-4">
                  <NormalSelect
                    name="min"
                    option={minList}
                    handleChange={(value) => formik.setFieldValue("min", value)}
                    value={formik.values.min}
                  />
                </div>
                <div className="col-md-4">
                  <NormalSelect
                    name="time"
                    option={TimeList}
                    handleChange={(value) =>
                      formik.setFieldValue("time", value)
                    }
                    value={formik.values.time}
                  />
                </div>
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <label className={css.label}>Your metric calculation</label>
              <div
                style={{ display: "flex", gap: "20px", flexDirection: "row" }}
              >
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.daily}
                    onChange={(e) =>
                      formik.setFieldValue("daily", e.target.checked)
                    }
                  />
                  <label className={css.checkLabel}>Daily</label>
                </div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.weekly}
                    onChange={(e) =>
                      formik.setFieldValue("weekly", e.target.checked)
                    }
                  />
                  <label className={css.checkLabel}>Weekly</label>
                </div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.biweekly}
                    onChange={(e) =>
                      formik.setFieldValue("biweekly", e.target.checked)
                    }
                  />
                  <label className={css.checkLabel}>Biweekly</label>
                </div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.monthly}
                    onChange={(e) =>
                      formik.setFieldValue("monthly", e.target.checked)
                    }
                  />
                  <label className={css.checkLabel}>Monthly</label>
                </div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.quarterly}
                    onChange={(e) =>
                      formik.setFieldValue("quarterly", e.target.checked)
                    }
                  />
                  <label className={css.checkLabel}>Quarterly</label>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="row">
                <div className="col-md-4">
                  <label className={css.label}>Select time zone</label>
                  <NormalSelect
                    name="timeZone"
                    option={countryTimezones}
                    handleChange={(value) =>
                      formik.setFieldValue("timeZone", value)
                    }
                    value={formik.values.timeZone}
                  />
                </div>
                <FieldArray
                  name="ingnoredDate"
                  render={(arrayHelpers) => (
                    <div className="col-md-4">
                      <label className={css.label}>Ignored Dates</label>
                      {formik.values?.ingnoredDate?.map((date, index) => (
                        <Box display={"flex"} gap="20px" mb={2} key={index}>
                          <div>
                            <DatePicker
                              style={{ width: "300px" }}
                              value={date}
                              setValue={(value) => {
                                formik.setFieldValue(
                                  `ingnoredDate[${index}]`,
                                  value
                                );
                              }}
                            ></DatePicker>
                          </div>
                          <IconButton
                            aria-label="delete"
                            onClick={() => arrayHelpers.remove(index, "")}
                          >
                            <RemoveIcon />
                          </IconButton>
                          {formik.values?.ingnoredDate.length - 1 == index && (
                            <IconButton
                              color="primary"
                              aria-label="add"
                              onClick={() => arrayHelpers.push(index, "")}
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                        </Box>
                      ))}
                    </div>
                  )}
                />
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <label className={css.label}>
                Calculate and present metrics by
              </label>
              <NormalSelect
                name="calculation"
                option={calculation}
                handleChange={(value) =>
                  formik.setFieldValue("calculation", value)
                }
                value={formik.values.calculation}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <label className={css.label}>Forecasting feature</label>
              <RadioGroup
                row
                name="enableAI"
                value={formik.values.enableAI}
                onChange={(e) =>
                  formik.setFieldValue("enableAI", e.target.value)
                }
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <button className={css.updateBtn} type="submit">
              Update
            </button>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
}

export default GeneralSetting;
