import { useEffect, useContext, forwardRef, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useLocation } from "react-router-dom";
import Navbar from "./navbar";
import axios from "../../utils/axiosHelper";
import { GlobalData } from "../../context/globalData";
import Graph from "../../components/metricGraph/graph/index";
import { useMetricHook } from "../../utils/metricHook";
import { useExcelDownloadHook } from "../../utils/downloadExcelHook";
import StackChart from "../../components/metricGraph/graph/StackChart";
const iterationMap = {
  Weekly: 7,
  Daily: 1,
  Biweekly: 14,
  Monthly: 30,
  Quarterly: 90,
};
const useStyles = makeStyles((theme) => ({
  cardLayout: {
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "white",
    paddingBottom: "10px",
  },
  card: {
    borderRadius: "0px",
    boxShadow: "none",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "16px",
    },
  },
  appBar: {
    position: "relative",
    backgroundColor: "#56aee4",
    color: "black",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "black",
  },
  innerCard: {
    padding: 0,
    margin: 0,
    paddingBottom: "0px",
    // background:"white",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px !important",
    },
  },
  wrapper: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
    marginLeft: "27px",
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Layout(props) {
  const [chartType, setChartType] = useState(1);
  const { children } = props;
  const classes = useStyles();
  const {
    setMetricData,
    openPop,
    setOpenPop,
    trendData,
    setTrendData,
    metricDataName,
    setMetricDataName,
    setAllMetricData,
    isLogin,
    selectedMetricTrend,
    popupPrecision,
    setEnableAi,
    setFutureDates,
    metricDetails,
    setmetricDetails,
    iteration,
    setIteration,
    iterationValueGlobal,
    projectNameData,
    RiskActive,
    metricData,
    localChart,
  } = useContext(GlobalData);
  const { flush } = useMetricHook();
  const { flush: flush2 } = useMetricHook();
  const handleClose = () => {
    setOpenPop(false);
    // setTrendData([]);
    // setMetricDataName([]);
  };
  useEffect(() => {
    if (!isLogin) return;
    if (RiskActive) return;

    if (!iterationValueGlobal) return;
    const source = axios.CancelToken.source();
    axios
      .get(`/api/metrics/${iterationMap[iterationValueGlobal]}`, {
        cancelToken: source.token,
      })

      .then((result) => {
        const data = result.data.map((val) => {
          const obj = {
            [val.DisplayName]: val.Id,
          };
          return obj;
        });
        setMetricData(data);
        setAllMetricData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel();

      // setting ? "" : source.cancel();
    };
  }, [iterationValueGlobal]);
  useEffect(() => {
    if (!isLogin) return;
    axios
      .get("/api/iterations/get")
      .then((result) => {
        result.data.map((data) => {
          iteration.push(data.Name);
        });

        setIteration(iteration.filter((str) => str.includes("eek"))); // Temporary removed
      })
      .catch((err) => {
        console.log("Error is here", err);
      });
  }, [isLogin]);
  useEffect(() => {
    if (!isLogin) return;

    axios
      .get("/api/client")
      .then((result) => {
        setEnableAi(result.data.enableAI);
        localStorage.setItem("ClientId", result.data.ClientId);
        result.data.enableAI
          ? setFutureDates(
              result?.data?.futureDates ? result?.data?.futureDates : 90
            )
          : setFutureDates(result.data.futureDates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setMetricData, isLogin, setmetricDetails]);
  const location = useLocation();
  const path = location.pathname;
  //Row data
  const handleChartType1 = (event) => {
    setChartType(event.target.value);
    // setOpen(false);
  };
  const { downloadCsv } = useExcelDownloadHook(
    metricDataName,
    projectNameData,
    selectedMetricTrend,
    popupPrecision
  );
  return (
    <div className="row">
      <div className="col-12 ">
        {/* {path !== "/login" && path !== "/signUp" && (
        <Navbar frstGraphClear={flush} secGraphClear={flush2} />
      )} */}
        <div
        // className={classes.cardLayout}
        // style={{ height: !isLogin && "calc(100vh - 95px)" }}
        >
          {/* <Card className={classes.card}> */}
          <div className={classes.innerCard}>{children}</div>
          {/* </Card> */}

          <Dialog
            fullScreen
            open={openPop}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="black"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  className={classes.title}
                  style={{ color: "black" }}
                >
                  Metric Trend
                </Typography>

                {/* <IconButton
                disabled={!metricDataName}
                onClick={() => downloadCsv()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#FFFFFF"
                >
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
                  </g>
                </svg>
              </IconButton> */}
              </Toolbar>
            </AppBar>

            <div className={classes.wrapper}>
              <Typography variant="h6" className={classes.title}>
                {selectedMetricTrend}
              </Typography>
            </div>
            <div className="row">
              <div className="col-3 p-4 m-4 d-flex justify-content-start align-items-center">
                <FormControl
                  variant="outlined"
                  // className={classes.formControl}
                  size="small"
                >
                  <Select value={chartType} onChange={handleChartType1}>
                    <MenuItem value={1}>Line Chart</MenuItem>

                    <MenuItem value={2}>Bar Chart</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {chartType == 1 && (
              <Graph
                body="popupGraph"
                graphData={trendData}
                height="70vh"
                precision={popupPrecision}
              />
            )}
            {chartType == 2 && (
              <StackChart
                body="popupGraph"
                graphData={trendData}
                height="70vh"
                precision={popupPrecision}
              />
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );
}
