import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { GlobalData } from "../../src/context/globalData";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "../utils/axiosHelper";
import { Alert, AlertTitle } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import UpgradePriceCheckList from "./UpgradePriceCheckList";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    top: "50%",
    left: "50%",
    transform: "translate(-50% ,-50%)",
    textAlign: "center",
  },
  feature: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bolder",
  },
  btn2: {
    backgroundColor: "#51a7ed",
    fontSize: "15px",
    padding: "0px 30px",
    color: "#fff",
    height: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#072136",
    },
  },
  featureBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnWraper: {
    display: "flex",
    justifyContent: "space-around",
  },
  upgardePlan: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "22px",
  },
  pricingLable: {
    alignSelf: "center",
    fontSize: "14px",
    fontWeight: "600",
  },
  upgraedSelect: {
    width: "150px",
    border: "1.5px solid lightgray",
    padding: "4px 8px",
    borderRadius: "4px",
  },
  alert: {
    marginTop: "25px",
  },
}));

const UpgradePlanRequest = ({ dataID, stateCode }) => {
  const classes = useStyles();
  const history = useHistory();

  const { isPlanUpgradeRequest, setIsPlanUpgradeRequest } =
    useContext(GlobalData);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [pricePlanList, setPricePlanList] = useState();
  const [isRequest, setIsRequest] = useState(false);
  const [checkList, setCheckList] = useState(false);
  
  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: {
      pricingPlan: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      upgradeHandler(values);
    },
  });

  const cancelHandler = () => {
    setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
    if (dataID === 7) {
      history.push({
        pathname: `/riskadvisor/${5}`,
        hash: "Metric configuration",
        state: {
          details: "details",
          title: "Metric configuration",
          id: 7,
          isLoad: true,
        },
      });
    } else if (stateCode) {
      history.push({
        pathname: `/riskadvisor/${5}`,
        hash: "Tools Integration",
        state: {
          details: "details",
          title: "Tools Integration",
          id: 6,
          isLoad: true,
        },
      });
    }
  };

  const upgradeHandler = (values) => {
    // setIsPlanUpgradeRequest(!isPlanUpgradeRequest);
    let payLoad = {
      pricingPlan: values.pricingPlan,
    };
    setLoading(true);
    axios
      .put("/api/pricingplans", payLoad)
      .then((result) => {
        if (result?.data?.success) {
          let message =
            "your request has sent. our support team is working on it.";
          setSuccessMessage(message);
          setIsRequest(true)
          setLoading(false);
          setSuccess(true);
          setTimeout(function () {
            setSuccess(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data.error);
        setLoading(false);
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 3000);
      });
  };

  const pricingPlanHandler = () => {
    axios
      .get("/api/pricingplans")
      .then((result) => {
        if (result.data.success) {
          setPricePlanList(result?.data?.data);
          formik.setFieldValue("pricingPlan", result?.data?.data[0]?._id);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    pricingPlanHandler();
  }, []);

  const planRequestChecklist=()=>{
    alert('a');
    setIsPlanUpgradeRequest(false);
    setCheckList(true);
  }

  return (
    <>
      <Modal
        open={isPlanUpgradeRequest}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div className={`${classes.feature}`}>
            <h4>Plan upgrade request.</h4>
          </div>
          <center>
            <form
              noValidate
              autoComplete="off"
              className={classes.root}
              onSubmit={formik.handleSubmit}
            >
              <div className={classes.upgardePlan}>
                <InputLabel
                  htmlFor="pricingPlan"
                  className={classes.pricingLable}
                >
                  Pricing Plan :
                </InputLabel>
                <Select
                  name="pricingPlan"
                  value={formik.values.pricingPlan}
                  onChange={formik.handleChange}
                  className={`${classes.upgraedSelect} ${classes.planSelect}`}
                  error={
                    formik.touched.pricingPlan &&
                    Boolean(formik.errors.pricingPlan)
                  }
                  helperText={
                    formik.touched.pricingPlan && formik.errors.pricingPlan
                  }
                >
                  {pricePlanList?.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.btnWraper}>
                <Button
                  variant="contained"
                  className={classes.btn2}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {isRequest ? "OK" : " Cancel"}
                </Button>
                {!isRequest && (
                  <Button
                    variant="contained"
                    className={classes.btn2}
                    type="button"
                    disabled={loading}
                    onClick={()=>{planRequestChecklist()}}
                  >
                    Upgrade
                  </Button>
                )}
              </div>
            </form>
          </center>

          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}

          {error && (
            <div className={classes.alert}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            </div>
          )}
          {success && (
            <div className={classes.alert}>
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {successMessage}
              </Alert>
            </div>
          )}
        </div>
      </Modal>

      {setCheckList && <UpgradePlanRequest />}
    </>
   
  );
};

export default UpgradePlanRequest;
