import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from "../../utils/axiosHelper";
import { useContext, useState, useEffect } from "react";
import ConsolidatedRisk from "./risk/projectLevelList/consolidatedRisk";
import ConfirmationDialog from "../../components/confirmationPopup";
import RiskWrapper from '../../components/overview/riskWrapper'


const useStyles = makeStyles((theme) => ({
  btnStyle: {
    float: "right",
    background: "#5EBDCC",
    cursor: "pointer",
    font: "normal normal normal 16px/20px Lexend Deca",
    borderRadius: "22px",
    width: "180px",
    padding: "8px 8px",
    margin: "15px 5px",
  },
  btngroup: {
    marginTop: "10px",
  },
  searchnButton: {
    display: "flex",
    margin:"25px 25px"
  },
  headerlabel: {
    margin: "20px 5px",
  },
  RiskIcons:{
    color: "#5EBDCC 0% 0% no-repeat padding-box",
    cursor:"pointer"
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontSize:16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



function createData(
  consolidatedScoreEqualto,
  consolidatedScoreLess,
  riskLevel
) {
  return { consolidatedScoreEqualto, consolidatedScoreLess, riskLevel };
}

const rows = [
  createData("80", "90", "High"),
  createData("40", "50", "Low"),
  createData("10", "20", "High"),
  createData("60", "70", "Medium"),
  createData("70", "60", "Low"),
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "2px solid black",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "50%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Sprint(props) {
  const [sprintLevel, setSprint] = useState();
  useEffect(() => {
    axios
      .get("/api/sprintconsolidatedrisk")
      .then((result) => {
        console.log("result", result.data);
        setSprint(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  } ,[]);
  const classes = useStyles();
  const [newRiskLevel, setRiskLevel] = useState(false);
  const [ editRisk ,setEditRisk]  = useState()
  const [ deleteRisk, setDeleteRisk] = useState()
  const [open, setOpen] = useState(false);
  const [SprintEdit, setSpirntData] = useState(false);

  const AddEditRisks = (data) => {
    console.log("function called");
    setRiskLevel(true);
    setEditRisk(data)
    axios
    .get("/api/sprintconsolidatedrisk")
    .then((result) => {
      console.log("result", result.data);
      setSprint(result.data);
    })
    .catch((err) => {
      console.log(err);
    });
    }
  
    // history.push("/ConsolidatedRisk");
  
  const EditRisks = (data) => {
    console.log("function called");
    setRiskLevel(true);
    setEditRisk(data)
    axios
    .get("/api/sprintconsolidatedrisk")
    .then((result) => {
      console.log("result", result.data);
      setSprint(result.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  const handleClose = () => {
    setOpen(false);
  };

  const deleteconRisk = () =>{
    console.log("delete",deleteRisk);
    axios 
    .delete(`/api/sprintconsolidatedrisk/delete/${deleteRisk.SprintConsolidateRiskId}`)
    .then((result) => {
      
      console.log("result", result);
      setOpen(false)
      axios
      .get("/api/sprintconsolidatedrisk")
      .then((result) => {
        console.log("result", result.data);
        setSprint(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
    })
    
  }

  return (
    <>
      {newRiskLevel == false ? (
        <>
          <div className={classes.headerlabel}>
            <span>Sprint Level Risk</span>
          </div>
         
          <button className={classes.btnStyle} onClick={AddEditRisks}>New Level Risk</button>
          <Toolbar className={classes.searchnButton}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    Consolidated risk score: Greater than/Equal to
                  </StyledTableCell>
                  <StyledTableCell>
                    Consolidated risk score: Less than
                  </StyledTableCell>
                  <StyledTableCell>Risk Level</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sprintLevel?.map((row) => (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {row.From}
                    </StyledTableCell>
                    <StyledTableCell>{row.To}</StyledTableCell>
                    <StyledTableCell><RiskWrapper>{row?.RiskCategory}</RiskWrapper></StyledTableCell>
                    <StyledTableCell>
                      <Grid item xs={4} sx={{ ml: 3 }}>
                        <EditIcon className={classes.RiskIcons} onClick={()=> EditRisks(row)} />{" "}
                        <DeleteOutlinedIcon className={classes.RiskIcons} xs={6} sx={{ ml: 3 }}   onClick={()=>{
                          setOpen(true) ;setDeleteRisk(row)
                        }}/>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
      {newRiskLevel == true ? <ConsolidatedRisk editConsolidated={editRisk} setRiskLevel={setRiskLevel} SprintEdit={SprintEdit} /> : null}
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        deleteHandler={deleteconRisk}
      />
    </>
  );
}
