import { Box, Button, Dialog } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { fix } from "../../../../utils/changeDecimal";
import { Alert, DialogTitle } from "@mui/material";
import MStepper from "../../../../components/mobileSteper";
import TrendWithCategoryAxis from "../../../../components/trendGraph/trendWithCategory";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import instance from "../../../../utils/axiosHelper";
import CommonPopup from "../../../../components/commonPopup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { datePayload } from "../../../../utils/moduleTabApi";
import RangeDatePicker from "../../../../components/RangeDatePicker";
import EpicDropDown from "../../../../components/epicDropDown";
import { GlobalData } from "../../../../context/globalData";

function ProcessModal({ classes, resourceHealth, handleClose, type }) {
  const { iterationValueGlobal, projectRangePickerValue: rangePickerValue } =
    useContext(GlobalData);
  const [value, setValue] = useState(0);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [date, setDate] = useState(rangePickerValue);
  const [showAlert, setShowAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showTrend, setShowTrend] = useState(false);
  const [trendData, setTrendData] = useState([]);
  const [showInsights, setShowInsights] = useState(false);
  const { state } = useLocation();
  const { projectId } = useParams();

  // const alertsData = [
  //   {
  //     title: "Action:",
  //     actions: [
  //       "We suggest you follow industry standards in order to make your process healthy. For Commit without Ticket Reference and Commit without Pull request, try to maintain it > 90%, keep the scope change to > 80%, Sprint completion rate > 90%, so that there is minimum spillover. For pull request age, don't keep it open for more than 3 days. Lower is better.",
  //     ],
  //   },
  //   {
  //     title: "Action: For Low Sprint Completion rate",
  //     actions: [
  //       "Use cross training and ensure knowledge transfer is consistent",
  //       "Avoid context switching and use a rolling average of the last 3-4 sprints to plan the next sprint. Remove any anomalous outliers to obtain a better average",
  //       "Use consistent definition of Done across your sprint",
  //     ],
  //   },
  //   {
  //     title: "Action: For Long Running PR",
  //     actions: [
  //       "Assign the responsibility or ownership of long pending PR to the engineer.",
  //       "Explain why long running PR results in code churn or extra work in long term.",
  //     ],
  //   },
  //   {
  //     title: "Action: For high % commits without ticket reference",
  //     actions: [
  //       "Insist on ticket reference in all commits to get better visibility.",
  //     ],
  //   },
  //   {
  //     title: "Action: For high % commit without pull request",
  //     actions: ["Disable direct commits into the master branch."],
  //   },
  // ];

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url;
    if (type?.id) {
      url = `/api/v2/ph/trend/process/resourceData/${projectId}?teamId=${type?.id}&${dateUrl}`;
    } else {
      url = `/api/v2/ph/trend/process/resourceData/${projectId}?resourceName=${state?.resourceName}&${dateUrl}`;
    }

    instance
      .get(url)
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.Date);
          return {
            ...e,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  return (
    <>
      <Box
        padding={3}
        display={"grid"}
        gridGap={4}
        gridColumnGap={8}
        gridTemplateColumns="1fr 1fr"
      >
        <div
          className={classes.closeIconSingleRes}
          onClick={() => handleClose(null)}
        >
          <div>
            <HighlightOffIcon />
          </div>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Avg Pr Age</label>
          <p>{(resourceHealth?.avgPrAge).toFixed()} Day</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Completion Rate</label>
          <p>{(resourceHealth?.CompletionRate * 100).toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Commit Without Ticket Ref
          </label>
          <p>{fix(resourceHealth?.PercentCommitWithoutTicketRef) * 100} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Commit Without Pr
          </label>
          <p>{(resourceHealth?.PercentCommitWithoutPr * 100)?.toFixed()} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Merged Pr</label>
          <p>{fix(resourceHealth?.PercentMergedPr) * 100} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Merged Reviewed Pr (%)
          </label>
          <p>{fix(resourceHealth?.PercentMergedReviewdPr) * 100} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Health Score</label>
          <p>{fix(resourceHealth?.HealthScore)} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Health Status</label>
          <p>{resourceHealth?.HealthStatus}</p>
        </div>

        {/*<div>
          <label className={classes.resourceHealthLabel}>Commits</label>
          <p>{resourceHealth?.Commits}</p>
        </div> */}

        {/* <div>
          <label className={classes.resourceHealthLabel}>
            Merged Reviewed Pr
          </label>
          <p>{resourceHealth?.MergedReviewdPr}</p>
        </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>Pr Count</label>
          <p>{resourceHealth?.PrCount}</p>
        </div>

        <div>
          <label className={classes.resourceHealthLabel}>Task Assigned</label>
          <p>{resourceHealth?.TaskAsigned}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Scope Change</label>
          <p>{resourceHealth?.ScopeChange}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Task Completed</label>
          <p>{resourceHealth?.TaskCompleted}</p>
        </div>

        <div>
          <label
            className={classes.resourceHealthLabel}
            style={{ paddingRight: "20px" }}
          >
            Commit Without Ticket Ref (%)
          </label>
          <p>{fix(resourceHealth?.PercentCommitWithoutTicketRef)} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Commit Without Pr (%)
          </label>
          <p>{fix(resourceHealth?.PercentCommitWithoutPr)} %</p>
        </div> */}
        {/* <div>
          <label className={classes.resourceHealthLabel}>
            Percent Merged Pr
          </label>
          <p>{fix(resourceHealth?.PercentMergedPr)} %</p>
        </div> */}
      </Box>
      <Box paddingX={3} paddingBottom={2} display={"flex"} gridGap={10}>
        {resourceHealth?.HealthStatus !== "GOOD" && (
          <>
            <Button
              variant="outlined"
              style={{ textTransform: "capitalize" }}
              color="primary"
              onClick={() => setShowInsights(!showInsights)}
            >
              {showInsights ? "Hide" : "Show"} Insights
            </Button>
            <Button
              variant="outlined"
              style={{ textTransform: "capitalize" }}
              color="primary"
              onClick={() => setShowAlert(!showAlert)}
            >
              {showAlert ? "Hide" : "Show"} Recommendation
            </Button>
          </>
        )}
        <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => setShowTrend(!showTrend)}
        >
          {showTrend ? "Hide" : "Show"} Trend
        </Button>
      </Box>
      <CommonPopup open={showAlert} setOpen={setShowAlert}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>{resourceHealth?.alertsData[activeStep].title}</h6>
            <ul style={{ paddingLeft: 20 }}>
              {resourceHealth?.alertsData[activeStep].actions.map(
                (action, i) => (
                  <li key={i}>{action}</li>
                )
              )}
            </ul>
          </Alert>
          <MStepper
            steps={resourceHealth?.alertsData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>
      </CommonPopup>
      <CommonPopup open={showInsights} setOpen={setShowInsights} width={600}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>Insights:</h6>
            <ul style={{ paddingLeft: 20 }}>
              Based on our analysis, you need to improve on
              <b> {resourceHealth?.additionalText} </b>so that you can make your
              Process healthy. View Our Show Recommendation Tab to Know More...
            </ul>
          </Alert>
        </Box>
      </CommonPopup>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box display={"flex"} gap={2}>
          {/* {(value === 0 || value === 1 || value === 3) && (
            <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
          )} */}
          {(value === 0 || value === 3) && (
            <>
              <Box marginLeft={2}>
                <RangeDatePicker
                  setDate={setDate}
                  date={date}
                  isNextDate={false}
                />
              </Box>
            </>
          )}
        </Box>
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trendData} />
        </Box>
      </CommonPopup>
    </>
  );
}

export default ProcessModal;
