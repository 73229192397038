import { MODULE_API_URL_GEN } from "../../utils/moduleTabApi";
import { useEffect, useState } from "react";
import axios from "../../utils/axiosHelper";
import { iterationMap } from "../../utils/textMap";
import { useContext } from "react";
import { GlobalData } from "../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";
import RiskWrapper from "../../components/overview/riskWrapper";
import { riskMap } from "../../utils/levelMap";
import { Card } from "@material-ui/core";
import Template from "./pageLayout";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Metric } from "../../utils/textMap";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";

export default function ModulesPage(props) {
  const {
    iterationValueGlobal,
    setModuleKpiFlag,
    moduleKpiFlag,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    titleId: projectId,
    setTitleID,
    projectList,
    isDefinaionPopup,
    setIsDefinaionPopup,
  } = useContext(GlobalData);

  const [moduleDetailData, setmoduleDetailData] = useState();
  const [materialTableColums, setMaterialTableColums] = useState([]);
  const [moduleKpiData, setModuleKpiData] = useState([]);
  const [kpiModalMaterialTableColums, setKpiModalMaterialTableColums] =
    useState([]);
  const [selectedmoduleKpiName, setSelectedmoduleKpiName] = useState([]);
  const [matrixId, setMatrixId] = useState();
  const [kpiGraphData, setKpiGraphData] = useState();
  const [selectModuleName, setSelectModuleName] = useState();
  const [metricValue, setMetricValue] = useState("Metric Details");
  const [moduleId, setModuleId] = useState();
  const [kpiName, setKpiName] = useState();

  const history = useHistory();

  const tableOption = {
    sorting: true,
    search: false,
    toolbar: false,
  };

  const moduleApiHandler = (Module_URL) => {
    axios
      .get(Module_URL)
      .then((result) => {
        let data = [];
        if (metricValue == "Metric Details") {
          const data = result?.data?.map((e) => {
            return {
              ...e,
              tableMatric: {
                [e?.metrics[0]?.name]: e?.metrics[0]?.Value,
                [e?.metrics[1]?.name]: e?.metrics[1]?.Value,
              },
            };
          });
          setmoduleDetailData(data);
        } else {
          result.data?.forEach((e) => {
            let value = { name: e.ProjectName };
            e.metrics.forEach((item) => {
              value[item.name] = item.RiskLevel;
            });
            data.push(value);
          });
          setmoduleDetailData(data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const moduleModalHandler = ({
    ProjectId,
    ProjectName,
    risk,
    metrics,
    ...rest
  }) => {
    setModuleId(ProjectId);
    setSelectModuleName(ProjectName);
    setModuleKpiData(metrics);
    setModuleKpiFlag(!moduleKpiFlag);
  };

  const moduleGraphHandler = (item) => {
    setMatrixId(item?.Id);

    setModuleKpiFlag(!moduleKpiFlag);
    setSelectedmoduleKpiName(item?.name);
    kpiGraphHandler();
    history.push({
      pathname: `/modules/${moduleId}`,
      state: {
        rangePickerValue: rangePickerValue,
        projectId: moduleId,
        matrixId: item?.Id,
        selectModuleName: selectModuleName,
        selectedmoduleKpiName: item?.name,
      },
    });
  };

  const kpiGraphHandler = (ModuleGraph_URL) => {
    axios
      .get(ModuleGraph_URL)
      .then((result) => {
        const graphData = result?.data?.trends?.map((item) => {
          return {
            Value1: item.Value,
            Time: item.Time,
            Name1: selectModuleName,
          };
        });
        setKpiGraphData(graphData);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    if (!projectId) return;
    let Module_URL = MODULE_API_URL_GEN(
      rangePickerValue,
      projectId,
      iterationMap[iterationValueGlobal]
    );
    if (metricValue == "Metric Details") {
      let url = `/api/v2/module/getMetrics?${Module_URL}`;
      moduleApiHandler(url);
    } else {
      let url = `/api/project/summary-detail?${Module_URL}&group_id=4`;
      moduleApiHandler(url);
    }
  }, [rangePickerValue, projectId, iterationValueGlobal, metricValue]);

  // useEffect(() => {
  //   let Module_URL = MODULE_API_URL_GEN(
  //     rangePickerValue,
  //     projectId,
  //     iterationMap[iterationValueGlobal]
  //   );
  //   let url = `api/v2/module/getMetricDetails/${projectId}/kpi/${matrixId}?${Module_URL}`;
  //   kpiGraphHandler(url);
  // }, [matrixId]);

  const definationPopupHandler = (value) => {
    setKpiName(value?.name)
    setIsDefinaionPopup(true);
  }

  useEffect(() => {
    if (!moduleDetailData) return;
    if (metricValue == "Metric Details") {
      const colums = [
        {
          field: "ProjectName",
          title: "Module Name",
          headerStyle: {
            backgroundColor: "#efefef",
          },
        },
      ];
      moduleDetailData[0]?.tableMatric &&
        Object.keys(moduleDetailData[0]?.tableMatric)?.forEach((e) => {
          colums.push({
            field: e,
            title: e,
            headerStyle: {
              backgroundColor: "#efefef",
              textAlign: "center",
            },
            cellStyle: {
              textAlign: "center",
            },
            render: (rowData) => <b>{rowData?.tableMatric[e]}</b>,
          });
        });
      colums.push(
        {
          field: "kpi",
          title: "KPI's",
          render: (value) => (
            <InfoIcon
              style={{ color: "#5EBDCC", cursor: "pointer" }}
              onClick={() => moduleModalHandler(value)}
            />
          ),
          cellStyle: {
            textAlign: "center",
          },
          headerStyle: {
            textAlign: "center",
            backgroundColor: "#efefef",
          },
        },
        {
          field: "riskLevel",
          title: "Overall Module Risk",
          headerStyle: {
            backgroundColor: "#efefef",
          },
          render: (value) => (
            <RiskWrapper val={riskMap[value?.riskLevel]}>
              {riskMap[value?.riskLevel]}
            </RiskWrapper>
          ),
          cellStyle: {
            textAlign: "center",
          },
        }
      );
      setMaterialTableColums(colums);
    } else {
      const columns = [
        {
          field: "name",
          title: "Module Name",
          headerStyle: {
            backgroundColor: "#efefef",
          },
        },
      ];

      moduleDetailData[0] &&
        Object.keys(moduleDetailData[0])?.forEach((e) => {
          if (e !== "name" && e !== "tableData") {
            columns.push({
              field: e,
              title: e,
              headerStyle: {
                backgroundColor: "#efefef",
              },
              render: (rowData) => (
                <RiskWrapper val={riskMap[rowData[e]]}>
                  {riskMap[rowData[e]]}
                </RiskWrapper>
              ),
            });
          }
        });
      setMaterialTableColums(columns);
    }
  }, [moduleDetailData, metricValue]);

  useEffect(() => {
    setKpiModalMaterialTableColums([
      {
        field: "",
        title: "Sr.No.",
        render: (rowData) => <>{rowData?.tableData?.id + 1}</>,
        headerStyle: {
          textAlign: "center",
          backgroundColor: "#efefef",
        },
        cellStyle: {
          textAlign: "center",
          fontSize: "12px",
        },
        width: "10%",
      },
      {
        field: "name",
        title: "KPI Name",
        render: (value) => (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                definationPopupHandler(value)
              }
            >
              <InfoIcon
                style={{ color: "rgb(203 196 196)" }}
                fontSize="small"
              />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => moduleGraphHandler(value)}
            >
              {value?.name} <ChevronRightIcon />
            </div>
          </div>
        ),
        headerStyle: {
          textAlign: "center",
          backgroundColor: "#efefef",
        },
        cellStyle: {
          color: "#5EBDCC",
          fontSize: "12px",
        },
      },
      {
        field: "Value",
        title: "Value",
        cellStyle: {
          textAlign: "center",
          fontSize: "12px",
        },

        headerStyle: {
          textAlign: "center",
          backgroundColor: "#efefef",
        },
        render: (value) => <b>{value?.Value}</b>,
        width: "10%",
      },
      {
        field: "Target",
        title: "Target",
        cellStyle: {
          textAlign: "center",
          fontSize: "12px",
        },

        headerStyle: {
          textAlign: "center",
          backgroundColor: "#efefef",
        },
        render: (value) => <b>{value?.Target}</b>,
        width: "10%",
      },
      {
        field: "RiskLevel",
        title: "Risk",

        render: (value) => (
          <RiskWrapper val={riskMap[value?.RiskLevel]}>
            {riskMap[value?.RiskLevel]}
          </RiskWrapper>
        ),
        cellStyle: {
          textAlign: "center",
          fontSize: "12px",
        },
        headerStyle: {
          textAlign: "center",
          backgroundColor: "#efefef",
        },
        width: "10%",
      },
    ]);
  }, [moduleKpiData]);

  return (
    <>
      <BreadCrumb list={[]} active={"Modules"} />
      <Card className="resourceStyling paginationStyling customCard">
        <Template
          rangePickerValue={rangePickerValue}
          setRangePickerValue={setRangePickerValue}
          Metric={Metric}
          metricValue={metricValue}
          setMetricValue={setMetricValue}
          dropdownProjectList={projectList}
          setTitleID={setTitleID}
          projectId={projectId}
          moduleDetailData={moduleDetailData}
          materialTableColums={materialTableColums}
          moduleKpiData={moduleKpiData}
          kpiModalMaterialTableColums={kpiModalMaterialTableColums}
          selectedmoduleKpiName={selectedmoduleKpiName}
          kpiGraphData={kpiGraphData}
          isProject={true}
          tableOption={tableOption}
          matrixId={matrixId}
          selectModuleName={selectModuleName}
          kpiName={kpiName}
        />
      </Card>
    </>
  );
}
