import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { GlobalData } from "../../../context/globalData";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import axios from "../../../utils/axiosHelper";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import configImg from "../../../assets/configImg.png";

const SonarqubeModal = ({
  data,
  GrantAccess,
  res,
  classes,
  index,
  skipHandler,
  configTools,
  getTools,
}) => {
  // const classes = useStyles();
  const history = useHistory();
  const {
    sonarqubeFlag,
    setSonarqubeFlag,
    setmetricDetails,
    isPlanUpgradeMessage,
    setIsPlanUpgradeMessage,
    setToolsError,
    toolIntegrationUrl,
  } = useContext(GlobalData);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  const validationSchema = yup.object({
    url: yup.string().required("URL is required"),
    token: yup.string().required("Token is required"),
  });

  const formik = useFormik({
    initialValues: {
      url: "",
      token: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sonarqubeVerificationHandler(values);
      formik.resetForm();
    },
  });

  const sonarqubeVerificationHandler = (values) => {
    let payLoad = {
      type: "Sonarqube",
      sonarqubeCode: values.token,
      sonarqubeEP: values.url,
    };
    setLoading(true);
    axios
      .post("/api/tool", payLoad)
      .then((result) => {
        if (result.data.success) {
          // skipHandler(index);
          setLoading(false);
          setSonarqubeFlag(!sonarqubeFlag);
          getTools();
          history.push({
            pathname: toolIntegrationUrl,
            // hash: "Tools Integration",
            // state: {
            //   details: "details",
            //   title: "Tools Integration",
            //   id: 6,
            //   isLoad: true,
            // },
          });
        }
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.error);
        setLoading(false);
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 3000);
        setToolsError(err?.response?.data?.error);

        if (err?.response?.data?.error === "LIMIT_REACHED") {
          setSonarqubeFlag(!sonarqubeFlag);
          setIsPlanUpgradeMessage(!isPlanUpgradeMessage);
        }
      });

    axios
      .get(`/api/tool/`)
      .then((result) => {
        result?.data?.tools.length >= 0
          ? setmetricDetails(result?.data?.tools)
          : setmetricDetails([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={classes.middelContent}>
        <div className={classes.middelContentRight}>
          <div className={`${classes.middleMainTxt} mb-3`}>
            Itechgenic app is requesting access to your Sonarqube account .
          </div>

          <div className={`${classes.middleMTxtLMs}`}>
            These are the metrics Resolved, Vulnerabilities Resolved,
            Vulnerabilities lead time
          </div>

          {!configTools[res] ? (
            <>
              <form className="mt-3">
                <div>
                  <TextField
                    id="url"
                    name="url"
                    className="border-bottom"
                    label="URL"
                    size="small"
                    style={{ minWidth: 400 }}
                    // placeholder="https://dev.sonarqube.com/<your-org>"
                    InputProps={{ classes: { input: classes.input } }}
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                  />
                </div>

                <div className="mt-2">
                  <TextField
                    id="token"
                    name="token"
                    className="border-bottom"
                    label="Token"
                    size="small"
                    style={{ minWidth: 400 }}
                    // placeholder=" sdbhbsjjk1234nklllkk455"
                    InputProps={{ classes: { input: classes.input } }}
                    value={formik.values.token}
                    onChange={formik.handleChange}
                    error={formik.touched.token && Boolean(formik.errors.token)}
                    helperText={formik.touched.token && formik.errors.token}
                  />
                </div>
              </form>

              <div className={classes.btns}>
                <button
                  className={classes.grntBtn}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Grant Access
                </button>
                {/* {value} {mergeResult.length} */}
                <button
                  className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                  onClick={() => {
                    skipHandler(index);
                  }}
                >
                  Skip
                </button>
                {/* <butt className={classes.cnclBtn}>Skip</butt> */}
              </div>
            </>
          ) : (
            <>
              <p className="mt-5">Your Account has been added Successfully</p>
              <button
                className={`${classes.cnclBtn} ml-3 bg-light border-0`}
                onClick={() => {
                  skipHandler(index);
                }}
              >
                Next
              </button>
            </>
          )}
        </div>
        <div className={classes.middelContentLeft}>
          <img src={configImg} className={classes.configImg} alt="" />
        </div>
      </div>

      <div>
        {/* <h2 id="simple-modal-title">Sonarqube user</h2>
        <form
          noValidate
          autoComplete="off"
          className={classes.root}
          onSubmit={formik.handleSubmit}
        >
          <div className={classes.inputWraper}>
            <InputLabel htmlFor="url" className={classes.label}>
              URL
            </InputLabel>
            <TextField
              id="url"
              name="url"
                className="border-bottom"
              
              size="small"
              placeholder="https://dev.sonarqube.com/<your-org>"
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.url}
              onChange={formik.handleChange}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText={formik.touched.url && formik.errors.url}
            />
          </div>

          <div className={classes.inputWraper}>
            <InputLabel htmlFor="token" className={classes.label}>
              Token
            </InputLabel>
            <TextField
              id="token"
              name="token"
                className="border-bottom"
              
              size="small"
              placeholder=" sdbhbsjjk1234nklllkk455"
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.token}
              onChange={formik.handleChange}
              error={formik.touched.token && Boolean(formik.errors.token)}
              helperText={formik.touched.token && formik.errors.token}
            />
          </div>

          <div className={classes.btnWraper}>
            <Button
              variant="contained"
              className={classes.btn}
              type="button"
              onClick={() => setSonarqubeFlag(!sonarqubeFlag)}
            >
              Cancel
            </Button>
            <Button variant="contained" className={classes.btn} type="submit">
              Submit
            </Button>
          </div>
        </form> */}

        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}

        {error && (
          <div className={classes.alert}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default SonarqubeModal;
