// import React from "react";

import { Pagination } from "@material-ui/lab";
import "./Pagination.css";

const PaginationTable = ({
  totalPosts,
  postsPerPage = 10,
  setCurrentPage,
  currentPage,
}) => {
  return (
    <div className="pagination">
      <Pagination
        count={Math.ceil(totalPosts / postsPerPage)}
        page={currentPage}
        onChange={(e, value) => setCurrentPage(value)}
      />
    </div>
  );
};

export default PaginationTable;
