import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Card, TextField } from "@material-ui/core";
import SelectFormFiled from "../../../../components/select/selectFormFiled";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../../utils/axiosHelper";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import BreadCrumb from "../../../../components/breadCrumb/BreadCrumb";
import { GlobalData } from "../../../../context/globalData";
import {
  capacityAccuracy,
  matricRisk,
  riskLevel,
  riskMap,
} from "../../../../utils/levelMap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function MetricLevelForm(props) {
  const [metricList, setMetricList] = useState("");
  const validationSchema = yup.object({
    MetricsId: yup.number("please enter valid number").required("Required"),
    From: yup.string("").nullable(),
    To: yup.string("").nullable(),
    // RiskScore: yup.number("").required("Required"),
    RiskLevel: yup.number("").required("Required"),
  });
  const { allMetricData } = useContext(GlobalData);
  const { state } = useLocation();
  const history = useHistory();
  const [error, setError] = useState("");
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      MetricsId: "",
      From: null,
      To: null,
      RiskScore: 1,
      RiskLevel: "",
      ProjectId: +params?.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        RiskCategory:
          formik?.values?.MetricsId === 44707
            ? matricRisk[values.RiskLevel]
            : riskMap[values.RiskLevel],
      };
      if (state) {
        updateHandler(payload);
      } else {
        submitHandler(payload);
      }
    },
  });
  const backHandler = () => {
    history.push({ pathname: "/settings/Metrics", state: params?.id });
  };

  const submitHandler = (payload) => {
    axios
      .post("/api/risks/create", payload)
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };
  const updateHandler = (payload) => {
    axios
      .patch(`/api/risks/update/${state.row.RiskId}`, {
        ...payload,
        MetricGroupId: state.row.MetricGroupId,
      })
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };

  useEffect(() => {
    if (state) {
      formik.setFieldValue("MetricsId", +state.row.MetricsId);
      formik.setFieldValue("From", +state.row.From?.$numberDecimal);
      formik.setFieldValue("To", +state.row.To?.$numberDecimal);
      formik.setFieldValue("RiskScore", +state.row.RiskScore?.$numberDecimal);
      formik.setFieldValue("RiskLevel", +state.row.RiskLevel);
    }
  }, [state]);

  useEffect(() => {
    if (allMetricData) {
      const data = allMetricData.map((e) => {
        return {
          lable: e.DisplayName,
          value: e.Id,
        };
      });
      setMetricList(data);
    }
  }, [allMetricData]);

  console.log(formik?.values?.MetricsId);

  return (
    <div>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={[]}
        active={"Metric Level Risks"}
      />
      <Card className="customCard">
        <form autoComplete="off" className="p-2" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectFormFiled
                render={metricList || []}
                value={formik.values.MetricsId}
                setValue={(value) => {
                  formik.setFieldValue("MetricsId", +value);
                }}
                name={"MetricsId"}
                label="Metric"
                withLable={true}
              />
              <div className="errorMsg">
                {formik.errors.MetricsId &&
                  formik.touched.MetricsId &&
                  formik.errors.MetricsId}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  name="From"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Target Range: Greater Than/Equal To"
                  value={formik.values.From}
                  onChange={(e) => {
                    formik.setFieldValue("From", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.From &&
                    formik.touched.From &&
                    formik.errors.From}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  name="To"
                  type="number"
                  size="small"
                  className="border-bottom w-100"
                  label="Target Range: Less Than"
                  value={formik.values.To}
                  onChange={(e) => {
                    formik.setFieldValue("To", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.To && formik.touched.To && formik.errors.To}
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <div>
                <TextField
                  name="RiskScore"
                  size="small"
                  type="number"
                  className="border-bottom w-100"
                  label="Risk Score"
                  value={formik.values.RiskScore}
                  onChange={(e) => {
                    formik.setFieldValue("RiskScore", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.RiskScore &&
                    formik.touched.RiskScore &&
                    formik.errors.RiskScore}
                </div>
              </div>
            </Grid> */}

            <Grid item xs={4}>
              <SelectFormFiled
                render={
                  formik?.values?.MetricsId === 44707
                    ? capacityAccuracy
                    : riskLevel || []
                }
                value={formik.values.RiskLevel}
                setValue={(value) => {
                  formik.setFieldValue("RiskLevel", +value);
                }}
                name={"RiskLevel"}
                label="Status"
                withLable={true}
              />
              <div className="errorMsg">
                {formik.errors.RiskLevel &&
                  formik.touched.RiskLevel &&
                  formik.errors.RiskLevel}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                className="position-relative from-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button
                  className={"cancelBtnStyle"}
                  type="button"
                  onClick={backHandler}
                >
                  <span>Cancel</span>
                </button>
                <button className={"applybtnStyle"} type="submit">
                  <span>Apply</span>
                </button>
              </div>
            </Grid>
          </Grid>

          {error && (
            <div>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}

export default MetricLevelForm;
