import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../../../utils/axiosHelper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState, useEffect } from "react";
import ConfirmationDialog from "../../../../components/confirmationPopup";
import RiskWrapper from "../../../../components/overview/riskWrapper";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../utils/tableIcon";
import SprintConsolidatedRisk from "./sprintLevelRiskForm";
import CommonSettingTable from "../../../../components/table/commonSettingTable";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';


export default function SprintLevelRiskRisk({ classes }) {
  // const classes = useStyles();
  const [riskValue, setRiskValue] = useState();
  const [isForm, setIsForm] = useState(false);
  const [editRisk, setEditRisk] = useState();
  const [deleteRisk, setDeleteRisk] = useState();
  const [open, setOpen] = useState(false);

  const columns = [
    {
      field: "From",
      title: "Consolidated risk score: Greater than/Equal to",
      width: "150px",
      render : (data)=>{
        return data.From ? data.From : <AllInclusiveIcon />
       }
    },
    {
      field: "To",
      title: "Consolidated risk score: Less than",
      width: "150px",
      render : (data)=>{
        return data.To ? data.To : <AllInclusiveIcon />
       }
    },
    {
      field: "RiskCategory",
      title: "Risk Level",
      width: "150px",
      render: (row) => <RiskWrapper>{row?.RiskCategory}</RiskWrapper>,
    },
    {
      field: "Actions",
      title: "Actions",
      width: "150px",
      render: (row) => (
        <Grid item xs={4} sx={{ ml: 3 }}>
          <EditIcon
            className={classes.RiskIcons}
            onClick={() => {
              AddEditRisks();
              setEditRisk(row);
            }}
               style={{ cursor: "pointer", color: "#5EBDCC" }}
          />
          <DeleteOutlinedIcon
            className={classes.RiskIcons}
               style={{ cursor: "pointer", color: "#5EBDCC" }}
            xs={6}
            sx={{ ml: 3 }}
            onClick={() => {
              setOpen(true);
              setDeleteRisk(row);
            }}
          />
        </Grid>
      ),
    },
  ];

  // const tableOption = {
  //   sorting: false,
  //   search: true,
  //   toolbar: true,
  //   headerStyle: {
  //     backgroundColor: "#efefef",
  //   },
  //   pageSize: 10,
  //   paginationType: "stepped",
  // };

  const handleClose = () => {
    setOpen(false);
  };

  // get List
  const getList = () => {
    axios
      .get("/api/sprintconsolidatedrisk", {
        params: { From: 10, To: 20, RiskLevel: 3, RiskCategory: "High" },
      })
      .then((result) => {
        setRiskValue(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // add page handler
  const AddEditRisks = () => {
    setIsForm(true);
    setEditRisk();
  };
  // delete Handler
  const deleteconRisk = () => {
    console.log(deleteRisk, "deleteRisk");
    axios
      .delete(
        `/api/sprintconsolidatedrisk/delete/${deleteRisk.SprintConsolidateRiskId}`
      )
      .then((result) => {
        setOpen(false);
        getList();
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {isForm == false ? (
        <>
          <CommonSettingTable
            columns={columns}
            clickHandler={AddEditRisks}
            btnLabel={"New Sprint Level Risks"}
            data={riskValue}
            tableTitle={""}
          ></CommonSettingTable>
          {/* <div>
            <div className={classes.headerlabel}>
              <span>Sprint Level Risks</span>
            </div>
          </div>
          <div className={classes.tableStyle}>
            <button
              type="button"
              className={classes.btnStyle}
              onClick={AddEditRisks}
            >
              New Level Risk
            </button>
            <MaterialTable
              style={{ boxShadow: "none" }}
              title=""
              columns={columns}
              enableRowNumbers
              rowNumberMode="static"
              icons={tableIcons}
              data={riskValue}
              options={tableOption}
            />
          </div> */}
        </>
      ) : null}
      {isForm == true ? (
        <SprintConsolidatedRisk
          editConsolidated={editRisk}
          setIsForm={setIsForm}
          getList={getList}
          classes={classes}
        />
      ) : null}

      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        deleteHandler={deleteconRisk}
      />
    </>
  );
}
