import React, { useContext, useEffect, useState } from "react";
import instance from "../../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonTable from "../../../../../components/commonTable";
import RiskWrapper from "../../../../../components/overview/riskWrapper";
import { Alert, Tooltip } from "@mui/material";
import { datePayload } from "../../../../../utils/moduleTabApi";
import CommonPopup from "../../../../../components/commonPopup";
import { Box } from "@material-ui/core";
import TrendWithCategoryAxis from "../../../../../components/trendGraph/trendWithCategory";
import { format, parseISO } from "date-fns";
import "../../health/Styles.css";
import HealthColorWrapper from "../../../../../components/overview/healthColorWrapper";
import HealthPointerWrapper from "../../../../../components/overview/HealthPointerWrapper";
import { GlobalData } from "../../../../../context/globalData";
import InfoIcon from "@material-ui/icons/Info";
import { definations } from "../../../../../utils/defination";

function Quality({
  date,
  showTrend,
  setShowTrend,
  getProcessStatus,
  recommendation,
}) {
  const [quality, setQuality] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  const cols = [
    { title: "Name", field: "_id" },
    {
      title: "Health Score",
      field: "healthScore",
      render: (res) => (res.healthScore * 100).toFixed(),
    },

    {
      title: "Quality Status",
      field: "healthStatus",
      // render: (res) => <RiskWrapper>{res?.healthStatus}</RiskWrapper>,
      render: (res) => <HealthColorWrapper status={res?.healthStatus} />,
    },
    {
      title: (
        <>
          Code Churn (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Code Churn (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "avgChurn",
      render: (res) => (
        <Tooltip
          title={definations?.["Code Churn (%)"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {res?.avgChurn ? res?.avgChurn?.toFixed() : " -"}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Avg Legacy (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Avg Legacy (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "avgLegacy",
      render: (res) => (
        <>
          {" "}
          <Tooltip
            title={definations?.["Avg Legacy (%)"]?.benchmark?.map((item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            ))}
            arrow
            placement="top"
          >
            {res.avgLegacy ? res.avgLegacy?.toFixed() : " -"}
          </Tooltip>
        </>
      ),
    },
    // {
    //   title: "Average PR Age (Days)",
    //   field: "AveragePRAge",
    //   render: (res) => res.AveragePRAge?.toFixed(),
    // },
    {
      title: (
        <>
          First Time Pass (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("First Time Pass (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "firstTimePassPercent",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.FirstTimeState}
        //   Range={res?.FirstTimeRange}
        // >
        <>
          {" "}
          <Tooltip
            title={definations?.["First Time Pass (%)"]?.benchmark?.map(
              (item) => (
                <ul className="pt-2 mb-0">
                  {Array?.isArray(item) ? (
                    <ul>
                      {item?.map((i) => (
                        <li>{i || "N.A."}</li>
                      ))}
                    </ul>
                  ) : (
                    <li>{item || "N.A."}</li>
                  )}
                </ul>
              )
            )}
            arrow
            placement="top"
          >
            {res.firstTimePassPercent?.toFixed()}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },
    {
      title: (
        <>
          Avg Pr Size
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Avg Pr Size");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PrSizes",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.PrSizeState}
        //   Range={res?.PrSizeRange}
        // >
        <>
          {" "}
          <Tooltip
            title={definations?.["Avg Pr Size"]?.benchmark?.map((item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            ))}
            arrow
            placement="top"
          >
            {res.PrSizes?.toFixed()}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },
    {
      title: "Return Rate (%)",
      field: "returnRatePercent",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.ReturnRateState}
        //   Range={res?.ReturnRateRange}
        // >
        <>{res.returnRatePercent?.toFixed()}</>
        // </HealthPointerWrapper>
      ),
    },

    {
      title: (
        <>
          Merged PR (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged PR (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "mergedPRPercentage",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.MergedPRState}
        //   Range={res?.MergedPRRange}
        // >
        <>
          {" "}
          <Tooltip
            title={definations?.["Merged PR (%)"]?.benchmark?.map((item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            ))}
            arrow
            placement="top"
          >
            {res.mergedPRPercentage?.toFixed()}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },
    {
      title: (
        <>
          Merged Reviewed PR (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged Reviewed PR (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "mergedReviewedPRPercentage",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.MergedReviewedState}
        //   Range={res?.MergedReviewdRange}
        // >
        <>
          {" "}
          <Tooltip
            title={definations?.["Merged Reviewed PR (%)"]?.benchmark?.map(
              (item) => (
                <ul className="pt-2 mb-0">
                  {Array?.isArray(item) ? (
                    <ul>
                      {item?.map((i) => (
                        <li>{i || "N.A."}</li>
                      ))}
                    </ul>
                  ) : (
                    <li>{item || "N.A."}</li>
                  )}
                </ul>
              )
            )}
            arrow
            placement="top"
          >
            {res.mergedReviewedPRPercentage?.toFixed()
              ? res.mergedReviewedPRPercentage?.toFixed()
              : "-"}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },

    {
      title: (
        <>
          Deployment Frequency (Count)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Deployment Frequency (Count)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "deploymentFrequency",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.MergedReviewedState}
        //   Range={res?.MergedReviewdRange}
        // >
        <>
          {" "}
          <Tooltip
            title={definations?.[
              "Deployment Frequency (Count)"
            ]?.benchmark?.map((item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            ))}
            arrow
            placement="top"
          >
            {res.deploymentFrequency?.toFixed()
              ? res.deploymentFrequency?.toFixed()
              : "-"}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },
    {
      title: (
        <>
          Change Failure Rate (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Change Failure Rate (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "changeFailure",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.MergedReviewedState}
        //   Range={res?.MergedReviewdRange}
        // >
        <>
          {" "}
          <Tooltip
            title={definations?.["Change Failure Rate (%)"]?.benchmark?.map(
              (item) => (
                <ul className="pt-2 mb-0">
                  {Array?.isArray(item) ? (
                    <ul>
                      {item?.map((i) => (
                        <li>{i || "N.A."}</li>
                      ))}
                    </ul>
                  ) : (
                    <li>{item || "N.A."}</li>
                  )}
                </ul>
              )
            )}
            arrow
            placement="top"
          >
            {res.changeFailure?.toFixed() ? res.changeFailure?.toFixed() : "-"}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },
    {
      title: (
        <>
          Deployment Rollback (Hours)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Deployment Rollback (Hours)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "deploymentRollback",
      render: (res) => (
        // <HealthPointerWrapper
        //   status={res?.MergedReviewedState}
        //   Range={res?.MergedReviewdRange}
        // >
        <>
          <Tooltip
            title={definations?.["Deployment Rollback (Hours)"]?.benchmark?.map(
              (item) => (
                <ul className="pt-2 mb-0">
                  {Array?.isArray(item) ? (
                    <ul>
                      {item?.map((i) => (
                        <li>{i || "N.A."}</li>
                      ))}
                    </ul>
                  ) : (
                    <li>{item || "N.A."}</li>
                  )}
                </ul>
              )
            )}
            arrow
            placement="top"
          >
            {" "}
            {res.deploymentRollback?.toFixed()
              ? res.deploymentRollback?.toFixed()
              : "-"}
          </Tooltip>
        </>
        // </HealthPointerWrapper>
      ),
    },

    // { title: "Total PR Count", field: "totalPRCount" },
    // { title: "Total Merged PR", field: "totalMergedPR" },
    // { title: "PR Count", field: "PRCount" },

    // { title: "Merged PR", field: "MergedPR" },
    // { title: "Merged Reviewd PR", field: "MergedReviewdPR" },
    // { title: "Commit Without Ticket Ref (%)", field: "CommitWithoutTicketRef" },

    // { title: "Most Reviewed PR", field: "MostReviwed" },
    // { title: "Commit With PR (%)", field: "CommitWithPR" },
    // { title: "Completed Issue", field: "CompletedIssue" },
    // { title: "First Time (%)", field: "FirstTime" },
    // { title: "Return Rate (%)", field: "ReturnRate" },
    // { title: "Pr Depth", field: "PrDepth" },
    // { title: "Receptiveness (%)", field: "Receptiveness" },

    // { title: "Comments Address", field: "CommentsAddress" },

    // { title: "Pr Comment Data", field: "PrCommentData" },

    // { title: "Assigned Story Point", field: "AssignedStoryPoint" },

    // { title: "Completed Story Point", field: "CompletedStoryPoint" },
  ];
  const [columns] = useState(cols);
  // [
  //     { title: "Name", field: "_id" },//
  //     { title: "Avg Churn", field: "avgChurn", render: (res) => res.avgChurn?.toFixed(2) },//

  //     { title: "Avg Legacy Refactor", field: "avgLegacyRefactor", render: (res) => res.avgLegacyRefactor ? res.avgLegacyRefactor?.toFixed(2) : "-" },

  //     { title: "Completed Issue", field: "CompletedIssue" },//

  //     { title: "First Time", field: "FirstTime" },//
  //     { title: "First Time Rate", field: "firstTimeRate", render: (res) => (res.firstTimeRate) ? (res.firstTimeRate * 100)?.toFixed(2) : "-" },
  //     { title: "Merged Pull Request", field: "MergedPR" },//
  //     { title: "Merged Pull Request Percent", field: "mergedPrPercent", render: (res) => (res.mergedPrPercent) ? (res.mergedPrPercent * 100)?.toFixed(2) : "-" },
  //     { title: "Merged Reviewed Pull Request", field: "MergedReviewdPR" },//

  //     { title: "Merged Reviewed Pull Request Percent", field: "mergedReviewedPrPercent", render: (res) => (res.mergedReviewedPrPercent) ? (res.mergedReviewedPrPercent * 100)?.toFixed(2) : "-" },

  //     { title: "Pull Request Count", field: "prCount" },

  //     { title: "Comments With Pull Request", field: "prDepth" },
  //     { title: "Pull Request Size", field: "prSize" },

  //     // { title: "Return", field: "return" },

  //     { title: "Return Rate", field: "ReturnRate", render: (res) => (res.ReturnRate) ? (res.ReturnRate * 100)?.toFixed(2) : "-" },//
  //     { title: "Quality Health Score (%)", field: "qualityHealthScore", render: (res) => (res.qualityHealthScore) ? (res.qualityHealthScore * 100)?.toFixed(2) : "-" },
  //     { title: "Quality Status", field: "qaualityStatus", render: (res) => <RiskWrapper>{res.qaualityStatus}</RiskWrapper> },

  // ]

  useEffect(() => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/quality/get/resources/project/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data.filter((e) => e.name !== null);
        setQuality(data);
      })
      .catch((err) => {
        console.error(err);
      });
    getProcessStatus("Quality", date);

    instance
      .get(
        `/api/v2/quality/get/resources/project/trends/${projectId}?${dateUrl}`
      )
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.time);
          return {
            status: e.healthStatus.toUpperCase(),
            Date: e.time ? format(originalDate, "MMM yy") : "",
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  const [visibleColumns, setVisibleColumns] = useState(columns.slice(0, 5));
  const [showViewMore, setShowViewMore] = useState(true);

  const handleViewMore = () => {
    const currentLength = visibleColumns.length;
    const nextVisibleColumns = columns.slice(currentLength, currentLength + 5);
    setVisibleColumns([...visibleColumns, ...nextVisibleColumns]);

    if (currentLength + 5 >= columns.length) {
      setShowViewMore(false);
    }
  };

  return (
    <div>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trendData} />
        </Box>
      </CommonPopup>
      {/* <Alert sx={{ mb: 3 }} severity="info">
        <b>Recommendation : </b>
        We suggest you follow industry standards in order to make your Quality
        healthy . For Code Churn, try to keep it below 5% , Pr merged with
        Review &gt; 90% , First time pass through rate &gt; 90%, Pr size &lt; 98
        code changes , Legacy refactor &lt; 15% and Pr Comments to (2 to 4)
        comments per pull request
      </Alert> */}
      {recommendation?.status === "GOOD" ? null : (
        <Alert sx={{ mb: 3 }} severity="info">
          <b>Insights : </b>
          Based on our analysis, you need to improve on{" "}
          <b>
            {recommendation?.recommendation?.slice(0, -1).replaceAll(",", ", ")}
          </b>{" "}
          so that you can make your Quality healthy.
        </Alert>
      )}
      <div className="healthTable">
        <CommonTable columns={visibleColumns} data={quality} />
      </div>
      {showViewMore && (
        <div
          style={{
            color: "rgb(65, 132, 142)",
            textAlign: "end",
            cursor: "pointer",
          }}
          onClick={handleViewMore}
          className="pt-2 mb-0"
        >
          ...View More Column
        </div>
      )}
    </div>
  );
}

export default Quality;
