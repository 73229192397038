import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, TextField } from "@material-ui/core";
import SelectFormFiled from "../../../../components/select/selectFormFiled";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../../utils/axiosHelper";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import BreadCrumb from "../../../../components/breadCrumb/BreadCrumb";

function ProjectForm() {
  const validationSchema = yup.object({
    DisplaySeq: yup.number("please enter valid number").required("Required"),
    DisplayName: yup.string("please enter valid number").required("Required"),
  });
  const { state } = useLocation();
  const history = useHistory();
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      DisplaySeq: "",
      DisplayName: "",
      Precision: "",
      isActive: "",
      Weekly: "",
      Biweekly: "",
      Monthly: "",
      Quarterly: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        Precision: values.Precision,
        isActive: values.isActive === "active" ? true : false,
        DisplayName: values.DisplayName,
        DisplaySeq: values.DisplaySeq,
        Iterations: [
          { iterateId: 7, Target: ` ${values.Weekly}` },
          { iterateId: 14, Target: ` ${values.Biweekly}` },
          { iterateId: 30, Target: ` ${values.Monthly}` },
          { iterateId: 90, Target: ` ${values.Quarterly}` },
        ],
      };
      updateHandler(payload);
    },
  });
  const backHandler = () => {
    history.goBack();
  };
  const updateHandler = (payload) => {
    axios
      .put(`/api/metrics/update/${state.Id}`, payload)
      .then((res) => {
        backHandler();
      })
      .catch((error) => {
        setError(error.response?.data?.error);
      });
  };

  useEffect(() => {
    if (state) {
      formik.setFieldValue("DisplaySeq", +state.DisplaySeq);
      formik.setFieldValue("DisplayName", state.DisplayName);
      formik.setFieldValue(
        "isActive",
        state.isActive ? "active" : "inactive"
      );
      formik.setFieldValue("Precision", +state.Precision);

    }

    const iterationTarget = state?.iterationMap || [];
    iterationTarget.forEach((item) => {
      const [key, value] = item.split(":").map((str) => str.trim());
      formik.setFieldValue([key], parseInt(value));
    });
  }, [state]);


  return (
    <div>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={['Metrics']}
        active={state?.project}
      />
      <Card className="customCard">
        <form autoComplete="off" className="p-2" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <TextField
                  name="DisplaySeq"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Display Sequence"
                  value={formik.values.DisplaySeq}
                  onChange={(e) => {
                    formik.setFieldValue("DisplaySeq", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.DisplaySeq &&
                    formik.touched.DisplaySeq &&
                    formik.errors.DisplaySeq}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  name="DisplayName"
                  className="border-bottom w-100"
                  size="small"
                  label="Metric"
                  value={formik.values.DisplayName}
                  onChange={(e) => {
                    formik.setFieldValue("DisplayName", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.DisplayName &&
                    formik.touched.DisplayName &&
                    formik.errors.DisplayName}
                </div>
              </div>
            </Grid>


            <Grid item xs={4}>
              <SelectFormFiled
                render={[0, 1, 2, 3, 4]}
                value={formik.values.Precision}
                setValue={(value) => {
                  formik.setFieldValue("Precision", value);
                }}
                name={"Number of Decimals*"}
                label="Number of Decimals"
              />
            </Grid>

            <Grid item xs={4}>
              <SelectFormFiled
                render={[
                  { lable: "active", value: "active" },
                  { lable: "Inactive", value: "inactive" },
                ]}
                value={formik.values.isActive}
                setValue={(value) => {
                  formik.setFieldValue("isActive", value);
                }}
                name={"isActive"}
                label="Status"
                withLable={true}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Iteration & Target</label>
            </Grid>

            <Grid item xs={3}>
              <div>
                <TextField
                  name="Weekly"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Weekly"
                  value={formik.values.Weekly}
                  onChange={(e) => {
                    formik.setFieldValue("Weekly", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.Weekly &&
                    formik.touched.Weekly &&
                    formik.errors.Weekly}
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <TextField
                  name="Biweekly"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Biweekly"
                  value={formik.values.Biweekly}
                  onChange={(e) => {
                    formik.setFieldValue("Biweekly", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.Biweekly &&
                    formik.touched.Biweekly &&
                    formik.errors.Biweekly}
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <TextField
                  name="Monthly"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Monthly"
                  value={formik.values.Monthly}
                  onChange={(e) => {
                    formik.setFieldValue("Monthly", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.Monthly &&
                    formik.touched.Monthly &&
                    formik.errors.Monthly}
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <TextField
                  name="Quarterly"
                  className="border-bottom w-100"
                  size="small"
                  type="number"
                  label="Quarterly"
                  value={formik.values.Quarterly}
                  onChange={(e) => {
                    formik.setFieldValue("Quarterly", +e.target.value);
                  }}
                />
                <div className="errorMsg">
                  {formik.errors.Quarterly &&
                    formik.touched.Quarterly &&
                    formik.errors.Quarterly}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>level</div>
              <label>{state?.type}</label>
            </Grid>
            <Grid item xs={12}>
              <div
                className="position-relative from-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button className={"cancelBtnStyle"} type="button" onClick={backHandler}>
                  <span>Cancel</span>
                </button>
                <button className={"applybtnStyle"} type="submit">
                  <span>Apply</span>
                </button>
              </div>
            </Grid>
          </Grid>

          {error && (
            <div>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}

export default ProjectForm;
